import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NOTIFICATION_TYPE, NotificationTitleMessage } from "react-notifications-component";

interface notificationState {
    type: NOTIFICATION_TYPE,
    title: NotificationTitleMessage,
    message?: NotificationTitleMessage
    count: number
}

const initialState: notificationState = {
    type: 'default',
    title: null,
    message: null,
    count: 0
};

const notification = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        createNotification(state, action: PayloadAction<{
            type: NOTIFICATION_TYPE,
            title: NotificationTitleMessage,
            message?: NotificationTitleMessage
        }>) {
            state.type = action.payload.type;
            state.title = action.payload.title;
            state.count = state.count + 1;

            if (action.payload.message) {
                state.message = action.payload.message;
            } else {
                state.message = '';
            }
        }
    },
});

export const { createNotification } = notification.actions;
export default notification.reducer;