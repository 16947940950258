import { useAppSelector } from 'core/box/hooks';
import { useGetMyFavorites } from 'core/services/userInfo.service';
import React, { useMemo } from 'react';
import { DashboardItem } from 'core/components/organisms/DashboardItem';

export const Favorite = () => {
    const authMe = useAppSelector(state => state.auth.authMe);
    const { isLoading, data } = useGetMyFavorites();

    const sliceList = useMemo(() => data?.data?.data?.slice(0, 3), [ data ]);

    return (
        <DashboardItem
            type={"favorite"}
            list={authMe ? sliceList : []}
            isLoading={isLoading}
        />
    );
};