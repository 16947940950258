import React, { FC, useCallback } from 'react';
import C from "../RecommendList/RecommendList.module.scss";
import { useProject } from "core/services/project.service";
import { NavLink } from "react-router-dom";
import { rateConvert } from "core/box/utils/rateConvert";
import { avatarUrl } from "core/box/utils/avatarUrl";
import { Stars } from "../../Stars";

interface FavoriteItemProps {
    handleClickItem: (category: string, id: string) => void;
    index: number;
    target_id: number;
}

export const FavoriteItem: FC<FavoriteItemProps> = ({
    handleClickItem,
    index,
    target_id
}) => {
    const { data } = useProject(target_id);

    const avatarLink = useCallback((category: string, item: any) => {
        if (category === 'tokens') {
            return item?.data_extended?.coin?.coin?.avatar;
        }

        if (category === 'validators') {
            return item?.data_extended?.list?.identity;
        }

        return item?.logo;
    }, []);

    return (
        <>
            {data && (
                <NavLink
                    key={data.project_id}
                    to={`/project/${data?.category}/${data?.project_id}`}
                    className={`${C.Grid} table__body table__grid`}
                    onClick={() => handleClickItem(data?.category, data?.project_id)}
                >
                    <div className={`table__td`}>
                        <Stars projectId={data?.project_id} />
                        {1 + index}
                    </div>
                    <div className={`table__td`}>
                        <img className={C.Avatar} src={avatarUrl(avatarLink(data?.category, data) || '')} alt="" />
                        <span className={C.Name}>{data?.title}</span>
                    </div>
                    <div className={`table__td table__center`}>
                        {rateConvert(data?.rate)}
                    </div>
                </NavLink>
            )}
        </>
    );
};