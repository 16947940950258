import React, { useCallback, useEffect, useState } from 'react';
import C from './Dashboard.module.scss';
import { Switch } from "../../../../core/components/atoms/Switch";
import { useMediaQuery } from "@react-hook/media-query";
import { MediaQuery } from "core/box/types";
import { Recommend } from "../../../../core/components/organisms/Dashboards/Recommend";
import { News } from "../../../../core/components/organisms/Dashboards/News";
import { Favorite } from "../../../../core/components/organisms/Dashboards/Favorite";
import { useTranslation } from "react-i18next";

export const Dashboard = () => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(`only screen and (max-width: ${MediaQuery.XL})`);
    const isDashBoardStorage = localStorage.getItem('showDashboard');
    const [ isDashboardShow, setIsDashboardShow ] = useState(!!isDashBoardStorage);

    const handleChangeDashboard = useCallback(() => {
        setIsDashboardShow(isDashboardShow => !isDashboardShow);
    }, []);

    useEffect(() => {
        if (isMobile) {
            setIsDashboardShow(true);
        } else {
            isDashboardShow ? localStorage.setItem("showDashboard", "true") : localStorage.removeItem("showDashboard");
        }
    }, [ isDashboardShow, isMobile ]);

    return (
        <div className={C.Dashboard}>
            <div className={C.Head}>
                <div>
                    <h1 className={C.Title}>{t("catalog.title")}</h1>
                </div>

                {!isMobile && (
                    <Switch
                        labelText={t("catalog.switchLabel")}
                        checked={isDashboardShow}
                        onChange={handleChangeDashboard}
                    />
                )}
            </div>

            {isDashboardShow && (
                <div className={C.List}>
                    <Recommend />
                    <Favorite />
                    <News />
                </div>
            )}
        </div>
    );
};