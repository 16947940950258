import network from "api/network";
import { useQuery } from "react-query";

export const ProjectService = {
    async getProjectsRecommend() {
        return network.get('projects', {
            params: {
                page: 1,
                recommend_global: 1,
                per_page: 3,
                sortBy: 'rate,desc;likes_count,desc',
            }
        });
    },

    async getProject(id: number) {
        return network.get(`projects/${id}`);
    },

    async getProjectsNews() {
        return network.get('news', {
            params: {
                per_page: 3,
            }
        });
    }
};

export const useProjectsRecommend = () => {
    const {
        isLoading: isLoadingRecommend,
        isSuccess: isSuccessRecommend,
        isError: isErrorRecommend,
        data: dataRecommend
    } = useQuery(
        'CORE/RECOMMEND',
        () => {
            return ProjectService.getProjectsRecommend();
        },
        {
            select: ({ data }): any => data.data,
            onError: () => {
                console.log('error');
            }
        }
    );

    return { isLoadingRecommend, isSuccessRecommend, isErrorRecommend, dataRecommend };
};

export const useProjectsNews = () => {
    const { isLoading: isLoadingNews, isSuccess: isSuccessNews, isError: isErrorNews, data: dataNews } = useQuery(
        'CORE/NEWS',
        () => {
            return ProjectService.getProjectsNews();
        },
        {
            select: ({ data }): any => data.data,
            onError: () => {
                console.log('error');
            }
        }
    );

    return { isLoadingNews, isSuccessNews, isErrorNews, dataNews };
};

export const useProject = (id: number) => {
    const { isLoading, isSuccess, isError, data } = useQuery(
        'CORE/PROJECT' + id,
        () => {
            return ProjectService.getProject(id);
        },
        {
            select: ({ data }): any => {
                return data?.data;
            },
            onError: () => {
                console.log('error');
            },
            enabled: !!id,
        }
    );

    return { isLoading, isSuccess, isError, data };
};