import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiCategoryItem, _transformCategory } from 'core/box/types';

interface ICategoryState {
    list: ApiCategoryItem[];
}

const initialState: ICategoryState = {
    list: [],
};

const userInterface = createSlice({
    name: 'category',
    initialState,
    reducers: {
        setCategory(state, action: PayloadAction<ApiCategoryItem[]>) {
            state.list = action.payload.map(_transformCategory);
        },
        setCategoryCount(state, action: PayloadAction<{ name: string, total: number }>) {
            state.list = state.list.map((item) => {
                if (item.name === action.payload.name) {
                    item.total = action.payload.total;
                }

                return item;
            });
        },
    },
});

export const {
    setCategory,
    setCategoryCount
} = userInterface.actions;
export default userInterface.reducer;