import { useProjectsNews } from 'core/services/project.service';
import React from 'react';
import { DashboardItem } from 'core/components/organisms/DashboardItem';

export const News = () => {
    const { dataNews, isLoadingNews } = useProjectsNews();

    return (
        <DashboardItem
            type={"news"}
            list={dataNews}
            isLoading={isLoadingNews}
        />
    );
};