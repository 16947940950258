import React, { FC } from 'react';
import C from './PanelBanner.module.scss';
import classNames from "classnames";
import panelBg from 'assets/images/panel/panel-banner.png';
import { useTranslation } from "react-i18next";

interface PanelBannerProps {
    className?: string;
}

export const PanelBanner: FC<PanelBannerProps> = ({ className }) => {
    const { t } = useTranslation();

    return (
        <div className={classNames(C.panelBanner, className)}>
            <img className={C.panelBanner__bg} src={panelBg} alt="" />
            <div className={C.panelBanner__content}>
                <div dangerouslySetInnerHTML={{ __html: t('panel.banner.text') }} />

                <a className={C.panelBanner__link} href="https://t.me/ddapps_community"
                   target="_blank" rel="noreferrer">{t('panel.banner.buttonText')}</a>
            </div>
        </div>
    );
};