import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";

export const Module = () => {
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (params?.category) {
            navigate(`/catalog/${params?.category}`);
        } else {
            navigate('/catalog');
        }
    }, [ navigate, params?.category ]);

    return <></>;
};