import { useQuery } from "react-query";
import network from "../../../api/network";
import { IOwner } from "../components/GetProjectOwnerr";

export const CatalogService = {
    async getProjects(query: string, page: number) {
        if (query === "tokens") {
            // https://dev.projectmarketcap.com/backend/api/v1/projects?category_like=tokens&sortBy=rate,desc;reserve,desc
            return network.get('projects', {
                params: {
                    page,
                    category_like: query,
                    per_page: 10,
                    sortBy: 'rate,desc;reserve,desc',
                }
            });
        }

        if (query === "validators") {
            // https://dev.projectmarketcap.com/backend/api/v1/projects?category_like=validators&sortBy=rate,desc;stake,desc
            return network.get('projects', {
                params: {
                    page,
                    category_like: query,
                    per_page: 10,
                    sortBy: 'rate,desc;stake,desc',
                }
            });
        }

        if (query === "recommend") {
            // https://dev.projectmarketcap.com/backend/api/v1/projects?recommend_global=true&sortBy=rate,asc;likes_count,asc
            return network.get('projects', {
                params: {
                    page,
                    recommend_global: 1,
                    per_page: 10,
                    sortBy: 'rate,desc;likes_count,desc',
                }
            });
        }

        return network.get('projects', {
            params: {
                page,
                category_like: query,
                per_page: 10,
                sortBy: 'rate,desc;likes_count,desc',
            }
        });
    },
    async getProject(id: number, category: string) {
        return network.get(`projects/${id}`, {
            params: {
                category_like: category
            }
        });
    },
    async getOwner(query: IOwner) {
        return network.get(query?.target_type === "company" ? `companies/${query?.target_id}` : `user_profile/${query?.target_id}`);
    },
};

export const useProjects = (query: string, page: number) => {
    const { isLoading, isSuccess, isError, data } = useQuery(
        'CATALOG/CATEGORY' + query + page,
        () => {
            return CatalogService.getProjects(query, page);
        },
        {
            select: ({ data }): any => {
                return data;
            },
            onError: () => {
                console.log('error');
            },
            enabled: !!query && !!page,
        }
    );

    return { isLoading, isSuccess, isError, data };
};

export const useOwner = (query: IOwner) => {
    const { isLoading: isLoadingOwner, isSuccess: isSuccessOwner, data: dataOwner } = useQuery(
        'CATALOG/OWNER' + query?.target_type + query?.target_id,
        () => {
            return CatalogService.getOwner(query);
        },
        {
            select: ({ data }): any => data?.data,
            onError: () => {
                console.log('error');
            },
            enabled: !!query?.target_type && !!query?.target_id,
        }
    );

    return { isLoadingOwner, isSuccessOwner, dataOwner };
};