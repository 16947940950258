import React, { FC } from 'react';
import logo from '../../../../assets/images/logo-small.png';

interface LogoProps {
    className?: string;
}

export const Logo: FC<LogoProps> = ({ className }) => {
    return (
        <>
            <img className={className} width={28} height={28} src={logo} alt="logo" />
        </>
    );
};