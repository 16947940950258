import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from "./locales/en/translation.json";
import ru from "./locales/ru/translation.json";


i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en
            },
            ru: {
                translation: ru
            }
        },
        fallbackLng: "en",
        debug: false,
    });

export default i18n;