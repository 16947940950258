import network from "api/network";
import { useAppDispatch, useAppSelector } from "core/box/hooks";
import { setRates } from "core/store/rates";
import { useQuery } from "react-query";

export const RatesService = {
    async getRate() {
        return network.get('rates', {
            params: {
                per_page: 1500
            }
        });
    },
};

export const useRates = () => {
    const rates = useAppSelector(state => state.rates.list);

    const dispatch = useAppDispatch();
    const { isLoading: isLoadingRates, isSuccess: isSuccessRates, isError, data } = useQuery(
        'CORE/RATES',
        () => RatesService.getRate(),
        {
            onSuccess: ({ data }) => {
                dispatch(setRates(data?.data));
            },
            onError: () => {
                console.log('error');
            },
            enabled: !rates?.length
        }
    );

    return { isLoadingRates, isSuccessRates, isError, data };
};