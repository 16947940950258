import React, { useMemo } from 'react';
import C from "./DashboardItem.module.scss";
import { NavLink } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { useAppSelector } from 'core/box/hooks';
import { useProject } from 'core/services/project.service';
import { avatarUrl } from 'core/box/utils/avatarUrl';

export const DashboardItemFavorite = (props: any) => {
    const categories = useAppSelector(state => state.category.list);
    const { data, isLoading, isError } = useProject(props?.target_id);

    const { logo, title, link } = useMemo(() => {
        let logo = data?.logo;
        let title = data?.title;
        let link = `/project/${data?.category}/${data?.id}`;

        if (data?.category === "tokens" && data?.data_extended) {
            logo = data?.data_extended?.coin?.coin?.avatar;
            title = data?.data_extended?.coin?.coin?.symbol;
        }

        if (data?.category === "validators" && data.data_extended) {
            logo = data?.data_extended?.list?.identity;
            title = data?.data_extended?.validator?.moniker;
        }

        return {
            logo,
            title,
            link
        };
    }, [ data?.category, data?.data_extended, data?.id, data?.logo, data?.title ]);

    return (
        <>
            {!isLoading ? (
                <NavLink key={data?.id} to={link} className={C.Item}>
                    <img className={C.ItemLogo} src={avatarUrl(logo)} alt="" />
                    <div className={C.Name}>{title}</div>
                    <div className={C.Category}>
                        {categories?.filter((cat) => (cat?.name === data?.category))[0]?.title}
                    </div>
                </NavLink>
            ) : (
                <Skeleton height={44} />
            )}
        </>

    );
};