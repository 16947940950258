import React, { FC, useCallback } from 'react';
import C from "../RecommendList/RecommendList.module.scss";
import { useNavigate } from "react-router";
import { FavoriteItem } from "./FavoriteItem";
import { useTranslation } from "react-i18next";

interface FavoriteListProps {
    data: any[];
}

export const FavoriteList: FC<FavoriteListProps> = ({ data }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClickItem = useCallback((category: string, id: string) => {
        navigate(`/project/${category}/${id}`);
    }, [ navigate ]);

    return (
        <>
            {!data?.length && (
                <div className={C.noResult}>💁 {t("errors.noResultTable")}</div>
            )}

            {data?.length && (
                <div className="table">
                    <div className={`table__grid ${C.Grid}`}>
                        <div className={`table__th`}>{t("catalog.position")}</div>
                        <div className={`table__th`}>{t("catalog.nameProduct")}</div>
                        <div className={`table__th table__center`}>{t("catalog.rate")}</div>
                    </div>
                    {data?.map((item, index) => (
                        <FavoriteItem
                            key={item.project_id}
                            index={index}
                            target_id={item?.target_id}
                            handleClickItem={handleClickItem}
                        />
                    ))}
                </div>
            )}
        </>
    );
};