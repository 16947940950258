export type TypeTheme = "dark" | "light" | "dark-premium" | "light-premium"

export interface Route {
    path: string;
    Component: () => JSX.Element;
}

export type Routes = Route[];

export enum MediaQuery {
    XL = "1200px",
    SM = "768px"
}

/**
 * Типизация категории
 */
export interface ApiCategoryItem {
    id: number;
    name: string;
    title: string;
    description: string;
    total: number;
}

/**
 * Типизация ссылок
 */
export type TypeLinkLabel = 'Сайт' | 'Представитель' | 'Сообщество' | 'Обозреватель'

export interface ILinkItem {
    id: number,
    title: TypeLinkLabel,
    url: string
}

export type TypeLinks = ILinkItem[]

/**
 * Типизация проекта
 */
export interface ApiProjectItem {
    id: number;
    title: string;
    logo: string;
    description: string;
    recommend_global: number;
    recommend_global_category: string;
    recommend_category: boolean;
    links: TypeLinks;
    commission: number;
    income: number;
    per_day_from: number;
    per_day_to: number;
    likes_count: number;
    category: string;
}

/**
 * Трансформ запросов очищаем лишнее
 * @property _transformCategory - трансформация категорий
 * @property _transformSearchProject - трансформация при поиске
 * @property _transformGlobalProjectRecommend - трансформация глобальных рекомендованных проектов
 * @property _transformProjectInCategory - трансформация проектов в списке категорий
 */
export const _transformCategory = (item: ApiCategoryItem) => {
    return {
        id: item?.id,
        name: item?.name,
        title: item?.title,
        description: item?.description,
        total: 0,
    };
};

export const _transformSearchProject = (item: ApiProjectItem) => {
    return {
        id: item?.id,
        title: item?.title,
        categories: item?.category.split(','),
    };
};

export const _transformGlobalProjectRecommend = (item: ApiProjectItem) => {
    return {
        id: item?.id,
        title: item?.title,
        logo: item?.logo,
        recommend_global_category: item?.recommend_global_category,
    };
};

export const _transformCategoryProjectRecommend = (item: ApiProjectItem) => {
    return {
        id: item?.id,
        title: item?.title,
        logo: item?.logo,
        commission: item?.commission,
        recommend_category: item?.recommend_category,
    };
};

export const _transformProjectInCategory = (item: ApiProjectItem) => {
    return {
        id: item?.id,
        title: item?.title,
        logo: item?.logo,
        likes: item?.likes_count,
    };
};

