import React, { FC, useMemo } from 'react';
import C from './DashboardItem.module.scss';
import { NavLink } from "react-router-dom";
import RecommendIcon from "assets/images/dashboards/recommend.svg";
import classNames from "classnames";
import { Skeleton } from "@mui/material";
import { DashboardItemFavorite } from "./DashboardItemFavorite";
import { useAppSelector } from 'core/box/hooks';
import { avatarUrl } from 'core/box/utils/avatarUrl';
import { useTranslation } from "react-i18next";

export interface DashboardItemProps {
    type: 'news' | 'recommend' | 'favorite';
    list?: any[];
    isLoading: boolean;
}

const loadArray = Array(3).fill(1);

export const DashboardItem: FC<DashboardItemProps> = ({ list, type, isLoading }) => {
    const { t } = useTranslation();
    const categories = useAppSelector(state => state.category.list);
    const isAuth = useAppSelector(state => state.auth.authMe);

    const isNews = type === "news";

    const itemDashboard = useMemo(() => {
        switch (type) {
            case 'recommend':
                return {
                    link: '/catalog/recommend',
                    icon: <RecommendIcon />,
                    title: t("dashboardItem.recommendTitle")
                };
            case 'favorite':
                return {
                    link: '/catalog/favorite',
                    icon: <RecommendIcon />,
                    title: t("dashboardItem.favoriteTitle")
                };
            case 'news':
                return {
                    link: '',
                    icon: <RecommendIcon />,
                    title: t("dashboardItem.newsTitle")
                };
            default:
                return {
                    link: '/',
                    icon: '',
                    title: ''
                };
        }
    }, [ type, t ]);

    return (
        <div className={classNames(C.DashboardItem)}>
            <div className={C.Head}>
                <h3 className={C.Title}>{itemDashboard?.title}</h3>
                {!!list?.length && !!itemDashboard.link?.length &&
                <NavLink className={C.All} to={itemDashboard?.link}>{t("dashboardItem.seeAll")}</NavLink>}
            </div>

            {!!list?.length && !isLoading && list?.map((item, index) => {
                let logo = item?.logo;
                let title = item?.title;
                let link = `/project/${item?.category}/${item?.id}`;

                if (item?.category === "tokens" && item?.data_extended) {
                    logo = item?.data_extended?.coin?.coin?.avatar;
                    title = item?.data_extended?.coin?.coin?.symbol;
                }

                if (item?.category === "validators" && item?.data_extended) {
                    logo = item?.data_extended?.list?.identity;
                    title = item?.data_extended?.validator?.moniker;
                }

                if (isNews) {
                    logo = item?.image;
                    link = `/`;
                }

                return (
                    <div key={item?.id}>
                        {type === 'favorite' && isAuth ? (
                            <DashboardItemFavorite  {...item} />
                        ) : (
                            <NavLink to={link} className={C.Item}>
                                <img className={C.ItemLogo} src={avatarUrl(logo)} alt="" />
                                <div className={C.Name}>{title}</div>
                                <div className={C.Category}>
                                    {categories?.filter((cat) => (cat?.name === item?.category))[0]?.title}
                                </div>
                            </NavLink>
                        )}
                    </div>
                );
            })}

            {!list?.length && !isLoading && (
                <>
                    {type === "recommend" && (
                        <div className={C.NoList}>
                            <div className={C.Subtitle}>{t("dashboardItem.noRecommendations.subtitle")}</div>
                            <div className={C.Desc}>{t("dashboardItem.noRecommendations.desc")}</div>
                        </div>
                    )}

                    {type === "favorite" && (
                        <div className={C.NoList}>
                            <div className={C.Subtitle}>{t("dashboardItem.noFavorites.subtitle")}</div>
                            <div className={C.Desc}>
                                {isAuth ? t("dashboardItem.noFavorites.descAuth") : t("dashboardItem.noFavorites.descAnon")}
                            </div>
                        </div>
                    )}

                    {type === "news" && (
                        <div className={C.NoList}>
                            <div className={C.Subtitle}>{t("dashboardItem.noNews.subtitle")}</div>
                            <div className={C.Desc}>{t("dashboardItem.noNews.desc")}</div>
                        </div>
                    )}
                </>
            )}

            {isLoading && (
                <>
                    {loadArray.map((item, index) => <Skeleton key={index} height={44} />)}
                </>
            )}
        </div>
    );
};
