import React, { FC, useCallback, useState } from 'react';
import C from "./ValidatorsList.module.scss";
import copy from "copy-to-clipboard";
import { formatNumber, formatNumberFee } from "core/box/utils/formatNumber";
import { textEllipsisCenter } from "core/box/utils/textEllipsisCenter";
import { useNavigate } from "react-router";
import { rateConvert } from "core/box/utils/rateConvert";
import { avatarUrl } from "core/box/utils/avatarUrl";
import { Stars } from "../../Stars";
import { useTranslation } from "react-i18next";

interface ValidatorsListProps {
    data: any[];
    page: number;
}

const ValidatorsListComponent: FC<ValidatorsListProps> = ({ data, page }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [ text, setText ] = useState('');

    const handleCopyText = useCallback((e: React.MouseEvent<HTMLSpanElement, MouseEvent>, value: string) => {
        e.stopPropagation();
        copy(value);
        setText(value);
    }, []);

    const handleClickItem = useCallback((category: string, id: string) => {
        navigate(`/project/${category}/${id}`);
    }, [ navigate ]);


    return (
        <div className="table">
            <div className={`table__grid ${C.Grid}`}>
                <div className={`table__th`}>{t('catalog.position')}</div>
                <div className={`table__th`}>{t('catalog.name')}</div>
                <div className={`table__th table__center`}>{t('catalog.rate')}</div>
                <div className={`table__th table__center`}>{t('catalog.kpi')}</div>
                <div className={`table__th table__center`}>{t('catalog.address')}</div>
                <div className={`table__th table__center`}>{t('catalog.stack')}</div>
                <div className={`table__th table__center`}>{t('catalog.commission')}</div>
                <div className={`table__th table__center`}>{t('catalog.slots')}</div>
                <div className={`table__th table__center`}>{t('catalog.pass')}</div>
            </div>
            {data?.map((item, index) => {
                const validator = item?.data?.validator;
                const list = item?.data?.list;

                return (
                    <button
                        key={item.project_id}
                        className={`${C.Grid} table__body table__grid`}
                        onClick={() => handleClickItem(item?.category, item?.project_id)}
                    >
                        <div className={`table__td`}>
                            <Stars projectId={item?.project_id} />
                            {((page - 1) * 10 + 1) + index}
                        </div>

                        <div className={`table__td`}>
                            <img src={avatarUrl(list?.identity)} className={C.Avatar} alt="" />
                            <span className={C.Name}>{validator?.moniker}</span>
                        </div>

                        <div className={`table__td table__center ${C.Center}`}>
                            {rateConvert(item?.rate)}
                        </div>

                        <div className={`table__td table__center`}>
                            -
                        </div>

                        <div className={`table__td table__center`} title={item?.address}>
                            <span
                                className={C.AddressText}
                            >
                                {!!validator?.address?.length ? textEllipsisCenter(validator?.address) : '-'}
                            </span>
                            <span
                                className={C.AddressCopy}
                                onClick={(e) => handleCopyText(e, validator?.address)}
                            >
                                {validator?.address === text ? t('catalog.copyDone') : t('catalog.copy')}
                            </span>
                        </div>

                        <div className={`table__td table__center`}>
                            {formatNumber(validator?.stake, 18, 0)} DEL
                        </div>

                        <div className={`table__td table__center ${C.Center}`}>
                            {!!validator?.fee?.length ? `${formatNumberFee(validator?.fee)}%` : '-'}
                        </div>

                        <div className={`table__td table__center`}>
                            {validator?.slots}/1000
                        </div>

                        <div className={`table__td table__center ${C.Center}`}>
                            {validator?.skippedBlocks}
                        </div>
                    </button>
                );
            })}
        </div>
    );
};

export const ValidatorsList = React.memo(ValidatorsListComponent);
