import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from "./core/components/organisms/App";
import "./core/styles/styles.scss";
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import "normalize.css";
import "./core/box/i18n";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <App />
);
