import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IProfileProjectsState {
    projectsPage: number;
    teamPage: number;
    supportPage: number;
}

const initialState: IProfileProjectsState = {
    projectsPage: 1,
    teamPage: 1,
    supportPage: 1,
};

const catalog = createSlice({
    name: 'profileProjects',
    initialState,
    reducers: {
        setProjectPage: (state, action: PayloadAction<number>) => {
            state.projectsPage = action.payload;
        },
        setTeamPage: (state, action: PayloadAction<number>) => {
            state.projectsPage = action.payload;
        },
        setSupportPage: (state, action: PayloadAction<number>) => {
            state.supportPage = action.payload;
        },
    }
});

export const { setProjectPage, setTeamPage, setSupportPage } = catalog.actions;
export default catalog.reducer;