import React, { useCallback, useMemo } from 'react';
import C from './SupportForm.module.scss';
import CloseIcon from '../../../../assets/images/close.svg';
import { useNavigate } from "react-router";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { TextInput } from "../../../../core/components/atoms/TextInput";
import { useAppSelector } from "../../../../core/box/hooks";
import { SelectMui } from "../../../../core/components/molecules/SelectMui";
import classNames from "classnames";
import { FormValuesSupportProject, useSupportProject } from "../../services/profileProjects.service";
import CloudIcon from '../../../../assets/images/cloud.svg';
import { NavLink } from "react-router-dom";
import { RoutesLinkEnum } from "../../../../core/box/router";
import { useTranslation } from "react-i18next";

export type FormValues = {
    name: string,
    lastName: string,
    connect: string,
    nameProject: string,
    category: string,
    role: string
};

export const SupportFormProject = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const categories = useAppSelector(state => state.category.list);

    const optionsCategory = useMemo(() => categories?.map((item) => ({
        value: item?.title,
        label: item?.title
    })), [ categories ]);

    const { ...methods } = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: {
            name: '',
            lastName: '',
            connect: '',
            nameProject: '',
            category: '',
            role: '',
        }
    });

    const { mutateAsync, isLoading, isSuccess } = useSupportProject();

    const onSubmit: SubmitHandler<FormValues> = useCallback(async (data) => {
        const values: FormValuesSupportProject = {
            title: `Проект ${data?.nameProject}`,
            content: `
                Имя: ${data?.name}
                Фамилия: ${data?.lastName}
                Телефон/телеграм/номер вотсап: ${data?.connect}
                Название проекта: ${data?.nameProject}
                Категория проекта: ${data?.category}
                Ваша позиция/роль/должность в проекте: ${data?.role}
            `
        };

        await mutateAsync(values);
    }, [ mutateAsync ]);

    return (
        <div className={C.SupportForm}>
            {!isSuccess && (
                <>
                    <div className={C.Head}>
                        <h1 className={C.Title}>{t('profileProjects.supportFormProject.title')}</h1>
                        <button type={'button'} className={C.Close} onClick={() => navigate(-1)}>
                            <CloseIcon className={C.Close__Icon} />
                        </button>
                    </div>
                    <FormProvider {...methods}>
                        <form className={C.Form}>
                            <TextInput
                                name="name"
                                label={t('profileProjects.supportFormProject.nameLabel')}
                                placeholder={t('profileProjects.supportFormProject.namePlaceholder')}
                                rules={{
                                    required: true,
                                    maxLength: {
                                        value: 20,
                                        message: t('profileProjects.supportFormProject.maxLength20')
                                    },
                                }}
                            />

                            <TextInput
                                name="lastName"
                                label={t('profileProjects.supportFormProject.lastNameLabel')}
                                placeholder={t('profileProjects.supportFormProject.lastNamePlaceholder')}
                                rules={{
                                    required: true,
                                    maxLength: {
                                        value: 30,
                                        message: t('profileProjects.supportFormProject.maxLength30')
                                    },
                                }}
                            />

                            <TextInput
                                name="connect"
                                label={t('profileProjects.supportFormProject.connectLabel')}
                                placeholder={t('profileProjects.supportFormProject.connectPlaceholder')}
                                rules={{
                                    required: true,
                                    maxLength: {
                                        value: 100,
                                        message: t('profileProjects.supportFormProject.maxLength100')
                                    },
                                }}
                            />

                            <TextInput
                                name="nameProject"
                                label={t('profileProjects.supportFormProject.projectNameLabel')}
                                placeholder={t('profileProjects.supportFormProject.projectNamePlaceholder')}
                                rules={{
                                    required: true,
                                    maxLength: {
                                        value: 100,
                                        message: t('profileProjects.supportFormProject.maxLength100')
                                    },
                                }}
                            />

                            <SelectMui
                                name={'category'}
                                label={t('profileProjects.supportFormProject.categoryLabel')}
                                placeholder={t('profileProjects.supportFormProject.categoryPlaceholder')}
                                options={optionsCategory}
                            />

                            <TextInput
                                name="role"
                                label={t('profileProjects.supportFormProject.roleLabel')}
                                placeholder={t('profileProjects.supportFormProject.rolePlaceholder')}
                                rules={{
                                    required: true,
                                    maxLength: {
                                        value: 200,
                                        message: t('profileProjects.supportFormProject.maxLength200')
                                    },
                                }}
                            />

                            <button
                                type="button"
                                onClick={methods?.handleSubmit(onSubmit)}
                                className={classNames('btn', C.Button)}
                                disabled={!methods.formState.isValid || isLoading}
                            >
                                {t('profileProjects.supportFormProject.submitButton')}
                            </button>
                        </form>
                    </FormProvider>
                </>
            )}

            {isSuccess && (
                <div className={C.Success}>
                    <div className={C.Success__Wrap}>
                        <CloudIcon className={C.Success__Icon} />
                        <div className={C.Success__Title}>{t('profileProjects.supportFormProject.successTitle')}</div>
                        <div className={C.Success__Desc}>
                            {t('profileProjects.supportFormProject.successDesc')}
                        </div>

                        <NavLink
                            to={RoutesLinkEnum.ProfileProjects}
                            className={classNames('btn', C.Success__Button)}
                        >
                            {t('profileProjects.supportFormProject.nextButton')}
                        </NavLink>
                    </div>
                </div>
            )}
        </div>
    );
};