import network from "../../api/network";
import { useQuery } from "react-query";
import coinmarketrate from "api/coinmarketrate";
import { useAppDispatch } from "core/box/hooks";
import { setDel, setPro } from "core/store/course";

export const CourseService = {
    async getPro() {
        return network.get(`/projects/${process.env.REACT_APP_PRO_ID}`, {
            params: {
                category_like: 'tokens'
            }
        });
    },
    async getDel() {
        return coinmarketrate.get('/cryptocurrency/one/decimal');
    }
};

export const useProCourse = () => {
    const dispatch = useAppDispatch();

    const { isLoading: isLoadingCourse, data: dataCourse } = useQuery(
        'CORE/COURSE_PRO',
        () => CourseService.getPro(),
        {
            onSuccess: ({ data }) => {
                const price = data?.data?.data_extended?.coin?.current?.price;
                !!price ? dispatch(setPro(Number(price).toFixed(4))) : dispatch(setPro('-'));
            },
            onError: () => {
                console.log('error');
            }
        }
    );

    return { isLoadingCourse, dataCourse };
};

export const useDelCourse = () => {
    const dispatch = useAppDispatch();

    const { isLoading: isLoadingCourse, data: dataCourse } = useQuery(
        'CORE/COURSE_DEL',
        () => CourseService.getDel(),
        {
            onSuccess: ({ data }) => {
                const price = data?.data?.stat?.price;
                !!price ? dispatch(setDel(Number(price).toFixed(4))) : dispatch(setDel('-'));
            },
            onError: () => {
                console.log('error');
            }
        }
    );

    return { isLoadingCourse, dataCourse };
};