import axios from 'axios';

const network = () => {
    const defaultOptions = {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        },
        baseURL: process.env.REACT_APP_API_URL,
    };

    let instance = axios.create(defaultOptions);

    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
        const token = localStorage.getItem('token');
        config.headers.Authorization = token ? `Bearer ${token}` : '';
        return config;
    });

    return instance;
};

// if (process.env.NODE_ENV === 'development') {
//     network.interceptors.request.use(
//         function (config) {
//             // Do something before request is sent
//             console.log('out:', config);
//             return config;
//         },
//         function (error) {
//             // Do something with request error
//             console.log('out error:', error);
//
//             return Promise.reject(error);
//         },
//     );
//
//     network.interceptors.response.use(
//         function (response) {
//             console.log('in:', response);
//             return response;
//         },
//         function (error) {
//             console.log(error.response);
//         },
//     );
// }

export default network();
