import React from 'react';
import C from "../GeneralInfo.module.scss";
import { Icon } from "core/components/atoms/Icon";
import { avatarUrl } from "core/box/utils/avatarUrl";
import { LikesFavorites } from "../../LikesFavorites";
import { useTranslation } from "react-i18next";

export const GeneralInfoDefaultComponent = ({ className, ...props }: any) => {
    const { t } = useTranslation();

    return (
        <div className={`${!!className?.length ? className : ''}`}>
            <div className={C.Head}>
                <div className={C.Head__Left}>
                    {!!props?.logo?.length ? (
                        <img
                            className={`${C.Head__Logo}`}
                            src={avatarUrl(props?.logo)}
                            alt={props?.title}
                        />
                    ) : (
                        <Icon className={`${C.Head__Logo} ${C.Head__Logo_Icon}`} name={props?.category} />
                    )}

                    <div className={C.Head__Content}>
                        <h1 className={C.Head__Title}>{props?.title ? props?.title : t('project.generalInfo.projectDescription')}</h1>
                        {!!props?.description?.length && <div className={C.Head__Desc}>{props?.description}</div>}
                    </div>
                </div>
                <LikesFavorites {...props} />
            </div>

            <div className={C.Grid}>
                <div className={C.Col}>
                    <h3 className={C.Title}>{t('project.generalInfo.commonInfo')}</h3>
                    <p>{!!props?.info?.length ? props?.info : t('project.generalInfo.noData')}</p>
                </div>

                <div className={C.Col}>
                    <h3 className={C.Title}>{t('project.generalInfo.formatAndBorders')}</h3>
                    <p>{!!props?.additional_info?.length ? props?.additional_info : t('project.generalInfo.noData')}</p>
                </div>

                <div className={`${C.Col} ${C.Col__Full}`}>
                    <h3 className={C.Title}>{t('project.generalInfo.projectDescription')}</h3>
                    <p>{!!props?.projectinfo?.length ? props?.projectinfo : t('project.generalInfo.noData')}</p>
                </div>
            </div>
        </div>
    );
};

export const GeneralInfoDefault = React.memo(GeneralInfoDefaultComponent);
