import React from 'react';
import classNames from "classnames";
import C from './RootPage.module.scss';
import { Logo } from "../../../../core/components/molecules/Logo";
import LogoText from "../../../../assets/images/logoText.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const RootPage = () => {
    const { t } = useTranslation();

    return (
        <div className={classNames('container', C.RootPage)}>
            <div className={C.Wrap}>
                <div className={C.Logo}>
                    <Logo className={C.Logo__Icon} />
                    <LogoText className={C.Logo__Text} />
                </div>
                <h1 className={C.Title}>404</h1>
                <h3 className={C.Subtitle}>{t("404.title")}</h3>
                <p>{t("404.desc")}</p>

                <NavLink className={classNames('btn', C.Btn)} to={'/'}>{t("404.btnLabel")}</NavLink>
            </div>
        </div>
    );
};