import { useAppSelector } from 'core/box/hooks';
import { useEffect } from 'react';
import { Store } from "react-notifications-component";

export const NotificationCenter = () => {
    const { title, message, type, count } = useAppSelector(state => state.notification);

    useEffect(() => {
        if (type && title) {
            Store.addNotification({
                title,
                message,
                type,
                insert: "top",
                container: "top-right",
                animationIn: [ "animate__animated", "animate__fadeInRight" ],
                animationOut: [ "animate__animated", "animate__fadeOutRight" ],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        }
    }, [ message, type, title, count ]);

    return null;
};