import React, { useCallback, useMemo, useState } from 'react';
import { formatNumber, formatNumberFee } from "core/box/utils/formatNumber";
import { textEllipsisCenter } from "core/box/utils/textEllipsisCenter";
import C from "../GeneralInfo.module.scss";
import copy from "copy-to-clipboard";
import { avatarUrl } from "core/box/utils/avatarUrl";
import { LikesFavorites } from "../../LikesFavorites";
import { useTranslation } from "react-i18next";

const GeneralInfoValidatorsComponent = ({ className, ...props }: any) => {
    const { t } = useTranslation();
    const list = useMemo(() => props?.data_extended?.list, [ props?.data_extended?.list ]);
    const validator = useMemo(() => props?.data_extended?.validator, [ props?.data_extended?.validator ]);
    const topDelegate = useMemo(() => props?.data_extended?.coins?.stakes?.slice(0, 5), [ props?.data_extended?.coins?.stakes ]);
    const nftDelegateCount = useMemo(() => (props?.data_extended?.nfts?.count) || '-', [ props?.data_extended?.nfts?.count ]);

    const [ text, setText ] = useState('');

    const handleCopyText = useCallback((e: React.MouseEvent<HTMLSpanElement, MouseEvent>, value: string) => {
        e.stopPropagation();
        copy(value);
        setText(value);
    }, []);

    return (
        <div className={`${!!className?.length ? className : ''}`}>

            <div className={C.Head}>
                <div className={C.Head__Left}>
                    <img className={C.Head__Logo} src={avatarUrl(list?.identity)} alt={list?.moniker} />

                    <div className={C.Head__Content}>
                        <h1 className={C.Head__Title}>"{list?.moniker}"</h1>
                        {!!props?.description?.length && <div className={C.Head__Desc}>{props?.description}</div>}
                    </div>
                </div>
                <LikesFavorites {...props} />
            </div>

            <div className={C.Grid}>

                <div className={C.Col}>
                    <h3 className={C.Title}>{t("project.generalInfoValidators.informationCreation")}</h3>
                    {!!list?.fee?.length ?
                        <p><b>{t("project.generalInfoValidators.commission")}</b> {formatNumberFee(list?.fee)}%
                        </p> : 'Нет данных'}
                    <p title={validator?.address}>
                        <b>{t("project.generalInfoValidators.address")}</b> {!!validator?.address?.length ? `${textEllipsisCenter(validator?.address)} ` : '- '}
                        <span
                            className={C.AddressCopy}
                            onClick={(e) => handleCopyText(e, validator?.address)}
                        >
                                {validator?.address === text ? t("project.generalInfoValidators.copyAddress.copied") : t("project.generalInfoValidators.copyAddress.copy")}
                        </span>
                    </p>
                </div>

                <div className={C.Col}>
                    <h3 className={C.Title}>{t("project.generalInfoValidators.aboutValidator")}</h3>
                    <p>
                        <b>{t("project.generalInfoValidators.creationGoal")}</b> {!!props?.info ? props?.info : t("project.generalInfoValidators.noData")}
                    </p>
                    <p>
                        <b>{t("project.generalInfoValidators.usage")}</b> {!!props?.additional_info ? props?.additional_info : t("project.generalInfoValidators.noData")}
                    </p>
                </div>

                <div className={C.Col}>
                    <h3 className={C.Title}>{t("project.generalInfoValidators.top5Delegators")}</h3>
                    {topDelegate?.map((item: any) => (
                        <p key={item?.address}>
                            <a
                                className={C.Link}
                                href={`https://explorer.decimalchain.com/address/${item?.address}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {textEllipsisCenter(item?.address, 4, 4)}
                            </a> - {formatNumber(item?.coins[0]?.amount, 18, 0)} {item?.coins[0]?.coinSymbol.toUpperCase()}
                        </p>
                    ))}
                </div>

                <div className={C.Col}>
                    <h3 className={C.Title}>{t("project.generalInfoValidators.status")}</h3>
                    <p><b>{t("project.generalInfoValidators.delegators")}</b> {validator?.delegators}</p>
                    <p><b>{t("project.generalInfoValidators.occupiedSlots")}</b> {validator?.slots}</p>
                    <p><b>{t("project.generalInfoValidators.freeSlots")}</b> {1000 - validator?.slots}</p>
                    <p><b>{t("project.generalInfoValidators.delegatedNFT")}</b> {nftDelegateCount}</p>
                    <p>
                        <b>{t("project.generalInfoValidators.totalStake")}</b> {formatNumber(validator?.stake, 18, 0)} DEL
                    </p>
                    <p><b>{t("project.generalInfoValidators.skippedBlocks")}</b> {validator?.skippedBlocks}</p>
                </div>
            </div>
        </div>
    );
};

export const GeneralInfoValidators = React.memo(GeneralInfoValidatorsComponent);
