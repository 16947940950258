import axios from "axios";

const coinmarketrate = axios.create({
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
    },
    baseURL: "https://api-v2.coinmarketrate.com/api",
});

// if (process.env.NODE_ENV === 'development') {
//     coinmarketrate.interceptors.request.use(
//         function (config) {
//             // Do something before request is sent
//             console.log('out:', config);
//             return config;
//         },
//         function (error) {
//             // Do something with request error
//             console.log('out error:', error);
//
//             return Promise.reject(error);
//         },
//     );
//
//     coinmarketrate.interceptors.response.use(
//         function (response) {
//             console.log('in:', response);
//             return response;
//         },
//         function (error) {
//             console.log(error.response);
//         },
//     );
// }

export default coinmarketrate;
