import React, { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';
import { useController, UseControllerProps, useFormContext } from "react-hook-form";
import C from './TextInput.module.scss';
import classNames from "classnames";

interface TextInputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, UseControllerProps {
    label?: string;
    name: string;
    defaultValue?: string;
    endIcon?: React.ReactNode | React.ReactNode[];
    onClickEndIcon?: () => void;
    className?: string;
}

export const TextInput: FC<TextInputProps> = (props) => {
    const formContext = useFormContext();
    const { formState } = formContext;

    const {
        name,
        label,
        rules,
        defaultValue,
        endIcon,
        placeholder,
        type,
        onClickEndIcon,
        className
    } = props;

    const { field } = useController({ name, rules, defaultValue });
    const hasError = Boolean(formState?.errors[name]);

    return (
        <label className={classNames(C.textInput, className)}>
            {label && <span className={C.textInput__label}>{label}</span>}
            <div className={C.textInput__wrap}>
                <input
                    className={classNames(C.textInput__input, {
                        [C.textInput__input_Error]: hasError
                    })}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    placeholder={placeholder}
                    type={type}
                />

                {endIcon && <span className={C.textInput__endIcon} onClick={onClickEndIcon}>{endIcon}</span>}
            </div>

            {hasError && <span className={C.textInput__error}>{`${formState?.errors[name]?.message}`}</span>}
        </label>
    );
};