import React, { FC } from 'react';
import C from './Switch.module.scss';

export interface SwitchProps {
    labelText?: string;
    checked: boolean;
    onChange: () => void;
}

export const Switch: FC<SwitchProps> = ({ labelText, ...rest }) => {
    return (
        <label className={C.Switch}>
            {!!labelText?.length && <span className={C.Label}>{labelText}</span>}

            <input
                className={C.Input}
                type="checkbox"
                hidden
                {...rest}
            />

            <div className={C.Wrap}>
                <div className={C.Circle} />
            </div>

        </label>
    );
};