import React, { useCallback, useEffect, useMemo, useState } from 'react';
import C from "../../containers/VerifyForm/VerifyForm.module.scss";
import WestIcon from "@mui/icons-material/West";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import { PinInput } from "react-input-pin-code";
import { useNavigate } from "react-router";
import { useAppSelector } from "core/box/hooks";
import { useVerifyCodeToEmail, useVerifyEmail } from "../../services/verify.service";
import { IsVerify } from "../IsVerify";
import { RoutesLinkEnum } from "core/box/router";
import { useTranslation } from "react-i18next";

const isFirstLogin = localStorage.getItem('first_log_in');

export const VerifyFormEmail = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const email = useAppSelector(state => state.auth.authMe?.email);
    const isVerify = useAppSelector(state => state.auth.authMe?.email_verified_at);
    const [ timeOutButton, setTimeOutButton ] = useState(60);

    const { isLoading, isSuccess, isError, mutateAsync } = useVerifyEmail();

    useVerifyCodeToEmail(email, (timeOutButton === 60 && !isVerify && !isFirstLogin));

    useEffect(() => {
        if (!isVerify) {
            const timeOutSend = setInterval(() => {
                setTimeOutButton(timeOut => timeOut >= 1 ? timeOut - 1 : timeOut);
            }, 1000);

            if (timeOutButton === 0) {
                clearTimeout(timeOutSend);
            }

            return () => {
                localStorage.setItem('timeOutButton', timeOutButton.toString());
                clearTimeout(timeOutSend);
            };
        }
    }, [ email.length, isVerify, navigate, timeOutButton ]);

    const [ values, setValues ] = React.useState([ '', '', '', '', '', '' ]);

    const isValues = useMemo(() => (
        values?.filter((item) => item !== '')?.length === values?.length
    ), [ values ]);

    const submitCode = useCallback(async () => {
        if (email && isValues) {
            await mutateAsync({ email, code: values.join('').trim() });
        }
    }, [ email, isValues, mutateAsync, values ]);

    const refreshTimeOut = useCallback(() => {
        setTimeOutButton(60);
    }, []);

    useEffect(() => {
        if (email && isValues) {
            submitCode();
        }
    }, [ email, isValues, submitCode ]);

    return (
        <>
            {!isSuccess ? (
                <>
                    <div className={C.Head}>
                        <button className={C.Back} onClick={() => navigate(RoutesLinkEnum.Home)}>
                            <WestIcon /> {t('verify.goToHome')}
                        </button>

                        <div className={C.Email}>
                            <MarkEmailUnreadIcon className={C.Email__Icon} />
                        </div>
                    </div>

                    <h1 className={C.Title}>{t('verify.emailVerificationTitle')}</h1>

                    <p className={C.Text}>
                        {t('verify.emailSentText')}
                        <mark
                            className={C.EmailText}>{email}</mark>
                        .
                        {t('verify.enterCodeText')}
                    </p>

                    <div className={C.Input}>
                        <PinInput
                            inputClassName={` ${C.Code} ${isError ? C.Code__Error : ''}`}
                            values={values}
                            onChange={(value, index, values) => setValues(values)}
                            placeholder=""
                            autoFocus
                            type="number"
                            disabled={isLoading || isSuccess}
                        />
                    </div>

                    {timeOutButton !== 0 && (
                        <p>{t('verify.resendAfterText')} {timeOutButton} {t('verify.secondsText')}</p>
                    )}

                    <button onClick={refreshTimeOut} disabled={timeOutButton !== 0 || isLoading}
                            className="btn">
                        {t('verify.resendEmail')}
                    </button>

                    <p>{t('verify.emailNotReceivedText')}</p>
                </>
            ) : <IsVerify />}
        </>
    );
};
