import React from 'react';
import C from "./AsideDashboard.module.scss";
import { Recommend } from "core/components/organisms/Dashboards/Recommend";
import { Favorite } from "core/components/organisms/Dashboards/Favorite";
import { News } from "core/components/organisms/Dashboards/News";

export const AsideDashboard = () => {
    return (
        <div className={C.AsideDashboard}>
            <div className={C.Wrap}>
                <Recommend />
                <Favorite />
                <News />
            </div>
        </div>
    );
};