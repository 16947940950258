import { useAppDispatch, useAppSelector } from 'core/box/hooks';
import { useAuthMe } from 'core/services/auth.service';
import { openAuth } from 'core/store/auth';
import React, { FC, useEffect } from 'react';
import { useNavigate } from "react-router";

interface IsAuthLayoutProps {
    children: React.ReactNode | React.ReactNode[];
}

export const IsAuthLayout: FC<IsAuthLayoutProps> = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isAuth = useAppSelector(state => state.auth.authMe);

    useAuthMe();

    useEffect(() => {
        if (!isAuth) {
            navigate('/');
            dispatch(openAuth());
        }
    }, [ dispatch, isAuth, navigate ]);

    if (!isAuth) return null;

    return (
        <>{children}</>
    );
};