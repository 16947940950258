import { useAppDispatch, useAppSelector } from 'core/box/hooks';
import { setLogIn, setSignIn } from 'core/store/auth';
import React, { useCallback } from 'react';
import C from './Auth.module.scss';
import { useTranslation } from "react-i18next";

export const Tabs = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isLogInModal = useAppSelector(state => state.auth.isLogInModal);

    const handleLogIn = useCallback(() => {
        dispatch(setLogIn());
    }, [ dispatch ]);

    const handleSignIn = useCallback(() => {
        dispatch(setSignIn());
    }, [ dispatch ]);

    return (
        <div className={C.Tabs}>
            <button
                type={"button"}
                className={`${C.Tabs__Button} ${isLogInModal ? C.Tabs__Button_Active : ''}`}
                onClick={handleLogIn}
            >
                {t('tabsAuth.logIn')}
            </button>
            <button
                type={"button"}
                className={`${C.Tabs__Button} ${!isLogInModal ? C.Tabs__Button_Active : ''}`}
                onClick={handleSignIn}
            >
                {t('tabsAuth.signIn')}
            </button>
        </div>
    );
};