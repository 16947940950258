import React from 'react';
import { useTranslation } from "react-i18next";

export const TermsOfUse = () => {
    const { i18n } = useTranslation();
    return (
        <>
            {i18n.language === 'ru' && (
                <>
                    <h1>УСЛОВИЯ ИСПОЛЬЗОВАНИЯ ПЛАТФОРМЫ</h1>
                    <p>Этот веб-сайт принадлежит коммерческой компании Decimal Dapps Co., Ltd. (далее также именуемой
                        «Компания»). </p>
                    <p>Посредством этого Компания предоставляет своим пользователям услуги информационного характера в
                        электронном виде и по их индивидуальному запросу в рамках ведения деятельности, составляющей
                        компанию.</p>
                    <h3>Юридический адрес</h3>
                    <p>Decimal Dapps Co., Ltd Юридический адрес компании: 519 Patak Road, Karon Sub- District, Mueang
                        Phuket
                        District , Провинция Пхукет/ 6.22 </p>
                    <h3>Регистрационные данные</h3>
                    <p>Decimal Dapps Co., Ltd (Registered No: 0835566025199) была зарегистрирована 16 июня 2023 года в
                        реестре <b>DBD</b></p>
                    <h3>Адрес электронной почты</h3>
                    <p>Для всех юридических целей адрес электронной почты Компании: <a
                        href="mailto:info@ddapps.io">info@ddapps.io</a></p>

                    <h2>ОБЩИЕ УСЛОВИЯ ИСПОЛЬЗОВАНИЯ ВЕБ-САЙТА</h2>

                    <h3>Принятие условий использования</h3>
                    <p>Любое лицо, получающее доступ к этому веб-сайту и использующее его, заявляет, что знает, а также
                        принимает эти «Общие условия», полностью доступные любому пользователю через его «Официальное
                        уведомление». </p>
                    <p>Знание и принятие этих «Общих условий» является необходимой предпосылкой для доступа к услугам
                        информационного общества, предлагаемым Decimal Dapps Co., Ltd, и получения выгоды от их
                        предоставления.
                        Поэтому любое лицо, не согласное с изложенным в нем, не имеет права использовать этот
                        Веб-сайт.</p>

                    <h3>Право собственности на веб-сайт</h3>
                    <p>Эта веб-страница ( <b>https://ddapps.io</b>) является собственностью Decimal Dapps Co., Ltd.
                        Права на
                        интеллектуальную собственность и права на использование и воспроизведение указанного Веб-сайта,
                        а также
                        его содержание, внешний вид и дизайн являются исключительной собственностью этой Компании, если
                        прямо не
                        указано иное. Любое ненадлежащее использование данного веб-сайта или его содержимого может
                        преследоваться в соответствии с действующим законодательством.</p>

                    <p>Decimal Dapps Co., Ltd оставляет за собой право изменять, обновлять, расширять и удалять в любое
                        время
                        без предварительного уведомления содержимое этого веб-сайта, включая собственные условия
                        использования.
                        Аналогичным образом, он может ограничить или удалить доступ к этому веб-сайту по причинам,
                        которые он
                        считает уместными, и по своему собственному усмотрению.</p>

                    <h3>Объем веб-сайта</h3>
                    <p>Вся информация, предоставляемая пользователю через этот веб-сайт, составляет его «Контент» и
                        предназначена только для информационных целей.</p>

                    <p>Компания не намерена использовать этот веб-сайт в качестве средства или инструмента, прямо или
                        косвенно,
                        для ведения бизнеса или заключения договоров на оказание услуг. Ни при каких обстоятельствах
                        этот
                        веб-сайт не должен использоваться или рассматриваться как предложение о заключении договора,
                        приглашение
                        к предложению заключить договор или как предложение о заключении любого другого типа сделки,
                        если прямо
                        не указано иное.</p>

                    <p>Содержание этого Веб-сайта не влечет за собой каких-либо юридических, финансовых или любых других
                        консультаций со стороны Компании в отношении работы на рынке криптовалют, поскольку его цель
                        исключительно информативна. </p>

                    <p>Следовательно, содержащаяся в нем информация не должна приниматься за основу или рекомендацию для
                        осуществления инвестиций или инвестиционных решений, поскольку пользователь несет личную
                        ответственность
                        за получение адекватной информации и консультаций о рисках, применимых правилах и эксплуатации
                        криптоактива. инвестиционные рынки. </p>

                    <p>Точно так же пользователь должен оценить и, при необходимости, принять на себя риски, связанные с
                        заключением договоров на таких рынках.</p>

                    <p>Этот веб-сайт может содержать информацию, предоставленную организациями, отличными от Decimal
                        Dapps Co.,
                        Ltd не несет ответственности за точность такой информации и не несет никакой ответственности за
                        любые
                        возможные убытки, которые могут возникнуть в результате использования такой информации.</p>

                    <p>Этот веб-сайт содержит ссылки на другие приложения а также предоставленную ими информацию с целью
                        облегчения и предоставления пользователю более полной информации о проектах на базе блокчейн
                        сетей.</p>

                    <p>Пользователь несет ответственность за оценку содержания и полезности информации, опубликованной
                        на этих
                        других веб-сайтах, при этом Компания не берет на себя никакой ответственности за безопасность
                        или
                        конфиденциальность этих ссылок или содержимого таких веб-сайтов.</p>

                    <p>Третьи стороны, желающие установить ссылки на этот веб-сайт, должны получить на это
                        предварительное
                        письменное разрешение от Decimal Dapps Co., Ltd</p>

                    <h3>Использование веб-сайта</h3>

                    <p>Содержимое этого веб-сайта может быть загружено, если доступно, скопировано и распечатано только
                        для
                        личного использования. Компания не несет ответственности за любые несоответствия, которые могут
                        возникнуть между печатной версией и электронной версией информации, содержащейся на данном
                        веб-сайте.</p>

                    <p>Без ущерба для положений предыдущего параграфа, копирование, дублирование, перераспределение,
                        электронное
                        воспроизведение, печать, коммерциализация, маркетинг или любое другое использование содержимого
                        этого
                        веб-сайта, полностью или частично, будь то в форма письменных или графических документов,
                        запрещена,
                        даже при цитировании письменных или графических документов; </p>

                    <p>Содержание этого веб-сайта, полностью или частично, будь то в виде письменных или графических
                        документов,
                        не может быть воспроизведено, воспроизведено в электронном виде, распечатано, продано, продано,
                        продано
                        или иным образом использовано каким-либо образом, даже если источник цитируется без
                        предварительного
                        письменного согласия Компании.</p>

                    <h3>Обязанности</h3>

                    <p>Компания не дает никаких гарантий и не несет ответственности за любые убытки, потери, убытки,
                        претензии
                        или расходы любого характера, возникающие в результате или в связи с использованием,
                        невозможностью
                        использования или незаконным, небрежным или мошенническим использованием этого веб-сайта.</p>

                    <p>Чтобы предоставлять пользователям более качественные услуги, Компания может обновлять и улучшать
                        содержимое этого Веб-сайта. Однако, учитывая свободный и открытый характер работы рынков, к
                        которым он
                        относится, информация, содержащаяся на этом Веб-сайте, ни в коем случае не должна носить
                        официальный
                        характер.</p>

                    <p>Decimal Dapps Co., Ltd делает информацию, содержащуюся на его веб-сайте, доступной для
                        пользователя в
                        соответствии с современным уровнем развития техники, что не гарантирует полной безопасности и
                        конфиденциальности указанного веб-сайта, а также не позволяет избежать риска того, что тот же
                        или его
                        информационная служба может претерпевать временные перерывы, частичную недоступность или другие
                        изменения. </p>

                    <p>Пользователь также должен знать, что информация, полученная или отправленная через этот веб-сайт,
                        может
                        быть перехвачена.</p>

                    <h3>Применимое право и юрисдикция</h3>

                    <p>Настоящее официальное уведомление и веб-сайтDecimal Dapps Co., Ltd регулируются законодательством
                        Королевства Таиланд. </p>

                    <p>Разрешение любых разногласий, которые могут возникнуть между этой Компанией и пользователем
                        услуги,
                        подпадает под юрисдикцию судов и трибуналов Таиланда, при явном отказе сторон от любой другой
                        юрисдикции, на которую они могут иметь право.</p>

                    <h3>Аккаунт</h3>

                    <p>Вам необходимо иметь учетную запись Decimal Dapps (« Учетная запись »), чтобы использовать
                        некоторые
                        части Сервиса. Когда вы создаете Учетную запись, вы заявляете и гарантируете, что вы достигли
                        совершеннолетия в вашей соответствующей юрисдикции, вы способны заключать и выполнять юридически
                        обязывающие контракты в соответствии с применимым законодательством, и что вся предоставленная
                        вами
                        информация является точной, современной, правдивой и полной.</p>

                    <p>Вы несете единоличную ответственность за все действия под вашей Учетной записью. Мы не несем
                        никакой
                        ответственности, если ваш пароль и/или Учетная запись будут незаконно присвоены или использованы
                        третьей
                        стороной. </p>

                    <p>Таким образом, вы соглашаетесь хранить свой пароль в безопасности и постоянно обновлять
                        информацию своей
                        учетной записи.</p>

                    <p>Decimal Dapps определяет исключительно и абсолютно, что вы имеете право на создание только одной
                        учетной
                        записи для себя. Вы не имеете права одалживать, передавать или продавать свою Учетную запись или
                        информацию об Учетной записи другой стороне и не должны использовать Учетную запись другого
                        пользователя
                        без его письменного разрешения.</p>

                    <p>Decimal Dapps может время от времени связываться с вами по адресу электронной почты, указанному
                        при
                        регистрации вашей учетной записи а также по указанным в вашем профиле контактным данным. </p>

                    <p>Вы не сможете отказаться от таких сообщений, и вы берете на себя ответственность за актуальность
                        вашего
                        адреса электронной почты. Если вы пропустили какие-либо сообщения из-за неточного, устаревшего
                        или
                        неполного адреса электронной почты, Decimal Dapps не будет нести ответственности за любые убытки
                        или
                        ущерб, вызванные тем, что вы пропустили сообщение.</p>

                    <h3>Запрещенная деятельность</h3>

                    <p>Вы соглашаетесь, что не будете:</p>

                    <ol>
                        <li>Подменять или искажать смысл предоставляемого контента с целью получения личной выгоды в
                            результате
                            искажения информации от Decimal Dapps, а также манипулировать и совершать действия, которые
                            могут
                            причинить репутационный или иной ущерб для Decimal Dapps
                        </li>
                        <li>Делать чрезмерные запросы на информацию или предпринимать какие-либо действия, которые
                            мешают,
                            нарушают
                            или возлагают чрезмерную нагрузку на Сервис или любой сервер или сеть, подключенные к
                            Сервису, или
                            негативно влияют на качество или доступность любого Контента, скорость или функциональность
                            Сервиса;
                        </li>
                        <li>Внедрять вирус, троянскую программу, червя или другое вредоносное ПО в Сервис или Платформу
                            или
                            использовать любое устройство, программное обеспечение или процедуру для обхода любого
                            программного
                            или
                            аппаратного обеспечения, запрещающего объемные запросы информации;
                        </li>
                        <li>Нарушать или обходить любые меры безопасности, предназначенные для ограничения или
                            предотвращения
                            доступа к Платформе, Контенту или Сервису; или иными способами пытаться получить
                            несанкционированный
                        </li>
                    </ol>
                </>
            )}

            {i18n.language === 'en' && (
                <>
                    <h1>PLATFORM TERMS OF USE</h1>
                    <p>This website is owned by the commercial company Decimal Dapps Co., Ltd. (hereinafter referred to
                        as the "Company").</p>
                    <p>Through this, the Company provides informational services to its users in electronic form and
                        upon their individual request within the scope of its business activities.</p>

                    <h3>Legal Address</h3>
                    <p>Decimal Dapps Co., Ltd Legal address of the company: 519 Patak Road, Karon Sub-District, Mueang
                        Phuket District, Phuket Province/6.22</p>

                    <h3>Registration Data</h3>
                    <p>Decimal Dapps Co., Ltd (Registered No: 0835566025199) was registered on June 16, 2023, in
                        the <b>DBD</b> registry.</p>

                    <h3>Email Address</h3>
                    <p>For all legal purposes, the Company's email address: <a
                        href="mailto:info@ddapps.io">info@ddapps.io</a></p>

                    <h2>GENERAL TERMS OF WEBSITE USE</h2>

                    <h3>Acceptance of Terms of Use</h3>
                    <p>Any person accessing and using this website declares that they are aware of and accept these
                        "General Terms," fully accessible to any user through its "Official Notice."</p>
                    <p>Knowledge and acceptance of these "General Terms" are a necessary prerequisite for accessing the
                        information society services offered by Decimal Dapps Co., Ltd, and benefiting from their
                        provision. Therefore, anyone who disagrees with the content therein has no right to use this
                        website.</p>

                    <h3>Ownership of the Website</h3>
                    <p>This web page (<b>https://ddapps.io</b>) is the property of Decimal Dapps Co., Ltd. Intellectual
                        property rights and rights to use and reproduce the specified website, as well as its content,
                        appearance, and design, are the exclusive property of this Company unless otherwise stated. Any
                        improper use of this website or its content may be pursued in accordance with applicable law.
                    </p>

                    <p>Decimal Dapps Co., Ltd reserves the right to change, update, expand, and delete the content of
                        this website, including its own terms of use, at any time without prior notice. Similarly, it
                        may restrict or terminate access to this website for reasons it deems appropriate, at its own
                        discretion.</p>

                    <h3>Website Content</h3>
                    <p>All information provided to the user through this website constitutes its "Content" and is
                        intended for informational purposes only.</p>

                    <p>The Company does not intend to use this website as a means or tool, directly or indirectly, for
                        conducting business or entering into service contracts. Under no circumstances should this
                        website be used or considered as an offer to enter into a contract, an invitation to make an
                        offer to enter into a contract, or as an offer to enter into any other type of transaction,
                        unless expressly stated otherwise.</p>

                    <p>The content of this website does not imply any legal, financial, or any other advice from the
                        Company regarding cryptocurrency market operations, as its purpose is purely informative.</p>

                    <p>Therefore, the information contained in it should not be taken as a basis or recommendation for
                        making investments or investment decisions, as the user is personally responsible for obtaining
                        adequate information and advice on the risks, applicable rules, and operation of cryptocurrency
                        investment markets.</p>

                    <p>Similarly, the user must assess and, if necessary, assume the risks associated with entering into
                        contracts in such markets.</p>

                    <p>This website may contain information provided by organizations other than Decimal Dapps Co., Ltd,
                        and the Company is not responsible for the accuracy of such information and assumes no liability
                        for any possible losses that may arise from the use of such information.</p>

                    <p>This website contains links to other applications and the information provided by them to
                        facilitate and provide the user with more complete information about blockchain-based
                        projects.</p>

                    <p>The user is responsible for evaluating the content and usefulness of the information published on
                        these other websites, with the Company assuming no responsibility for the security or
                        confidentiality of these links or the content of such websites.</p>

                    <p>Third parties wishing to establish links to this website must obtain prior written permission
                        from Decimal Dapps Co., Ltd.</p>

                    <h3>Website Usage</h3>

                    <p>The content of this website may be downloaded, if available, copied, and printed only for
                        personal use. The Company is not responsible for any discrepancies that may arise between the
                        printed version and the electronic version of the information contained on this website.</p>

                    <p>Without prejudice to the provisions of the preceding paragraph, copying, duplicating,
                        redistributing, electronically reproducing, printing, commercializing, marketing, or any other
                        use of the content of this website, in whole or in part, whether in the form of written or
                        graphic documents, is prohibited, even when quoting written or graphic documents;</p>

                    <p>The content of this website, in whole or in part, whether in the form of written or graphic
                        documents, cannot be reproduced, electronically reproduced, printed, sold, resold, or otherwise
                        used in any way, even if the source is cited without the prior written consent of the
                        Company.</p>

                    <h3>Responsibilities</h3>

                    <p>The Company makes no warranties and shall not be liable for any losses, damages, claims, or
                        expenses of any kind arising out of or in connection with the use, inability to use, or
                        unlawful, negligent, or fraudulent use of this website.</p>

                    <p>To provide users with better services, the Company may update and improve the content of this
                        website. However, given the free and open nature of the markets to which it relates, the
                        information contained on this website should in no way be considered official.</p>

                    <p>Decimal Dapps Co., Ltd makes the information contained on its website available to the user in
                        accordance with the current state of technological development, which does not guarantee the
                        complete security and confidentiality of the specified website and does not prevent the risk
                        that the same or its information service may experience temporary interruptions, partial
                        unavailability, or other changes.</p>

                    <p>The user should also be aware that information transmitted or received through this website may
                        be intercepted.</p>

                    <h3>Applicable Law and Jurisdiction</h3>

                    <p>This official notice and the Decimal Dapps Co., Ltd website are governed by the laws of the
                        Kingdom of Thailand.</p>

                    <p>The resolution of any disputes that may arise between this Company and the user of the service
                        falls under the jurisdiction of the courts and tribunals of Thailand, with both parties
                        expressly waiving any other jurisdiction they may have the right to claim.</p>

                    <h3>Account</h3>

                    <p>You need to have a Decimal Dapps account ("Account") to use certain parts of the Service. When
                        you create an Account, you represent and warrant that you have reached the age of majority in
                        your respective jurisdiction, you are capable of entering into and performing legally binding
                        contracts in accordance with applicable law, and that all information provided by you is
                        accurate, up-to-date, truthful, and complete.</p>

                    <p>You are solely responsible for all actions under your Account. We are not responsible if your
                        password and/or Account are unlawfully misappropriated or used by a third party.</p>

                    <p>Thus, you agree to keep your password secure and regularly update your account information.</p>

                    <p>Decimal Dapps exclusively and absolutely determines that you are entitled to create only one
                        account for yourself. You are not allowed to lend, transfer, or sell your Account or Account
                        information to another party and must not use another user's Account without their written
                        permission.</p>

                    <p>Decimal Dapps may contact you from time to time at the email address provided when registering
                        your account and the contact information in your profile.</p>

                    <p>You will not be able to opt out of such messages, and you are responsible for the accuracy of
                        your email address. If you miss any messages due to an inaccurate, outdated, or incomplete email
                        address, Decimal Dapps will not be liable for any losses or damages caused by missing the
                        message.</p>

                    <h3>Prohibited Activities</h3>

                    <p>You agree not to:</p>

                    <ol>
                        <li>Substitute or distort the meaning of the content provided for personal gain by distorting
                            information from Decimal Dapps, as well as manipulate and take actions that may cause
                            reputational or other harm to Decimal Dapps.
                        </li>
                        <li>Make excessive requests for information or take any actions that interfere, violate, or
                            impose an excessive burden on the Service or any server or network connected to the Service
                            or negatively affect the quality or availability of any Content, speed, or functionality of
                            the Service.
                        </li>
                        <li>Introduce viruses, trojans, worms, or other malware into the Service or Platform or use any
                            device, software, or procedure to circumvent any software or hardware preventing volumetric
                            information requests.
                        </li>
                        <li>Violate or bypass any security measures designed to restrict or prevent access to the
                            Platform, Content, or Service; or otherwise attempt to obtain unauthorized access.
                        </li>
                    </ol>
                </>
            )}
        </>
    );
};