import network from "../../../api/network";
import { useMutation } from "react-query";
import { useAppDispatch, useAppSelector } from "../../../core/box/hooks";
import { createNotification } from "../../../core/store/notification";
import { useNavigate } from "react-router";

export interface IProfileItem {
    system_name: string;
    user_id: string;
    value: string;
}

export const EditProfileService = {
    async edit(data: IProfileItem[]) {
        return network.post('/userInformation/upsert', {
            "records": data
        });
    },
    async editNick(nick: string) {
        return network.patch('/settings/profile', {
            "nick": nick
        });
    },
};

export const useProfileEdit = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const authMe = useAppSelector(state => state.auth.authMe);
    const { isLoading, isSuccess, isError, data, error, mutateAsync } = useMutation(
        'PROFILE_EDIT',
        (data: IProfileItem[]) => EditProfileService.edit(data),
        {
            onSuccess: () => {
                dispatch(createNotification({ type: 'success', title: 'Профиль успешно обновлен' }));
                navigate(`/profile/${authMe?.id}`);
            },
            onError: (error: any) => {
                // console.log('error', error);
                // @ts-ignore
                return error?.response?.data;
            }
        }
    );

    return { isLoading, mutateAsync, isSuccess, isError, data, error };
};

export const useProfileEditNick = () => {
    const dispatch = useAppDispatch();
    const { isLoading, isSuccess, isError, data, error, mutateAsync: mutateAsyncNick } = useMutation(
        'PROFILE_EDIT_NICK',
        (nick: string) => EditProfileService.editNick(nick),
        {
            onSuccess: () => {
                dispatch(createNotification({ type: 'success', title: 'Никнейм успешно обновлен' }));
            },
            onError: (error: any) => {
                // console.log('error', error);
                // @ts-ignore
                return error?.response?.data;
            }
        }
    );

    return { isLoading, mutateAsyncNick, isSuccess, isError, data, error };
};