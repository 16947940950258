import React, { DetailedHTMLProps, FC, InputHTMLAttributes, useEffect, useState } from 'react';
import { UseControllerProps } from "react-hook-form";
import C from 'core/components/atoms/TextInput/TextInput.module.scss';
import classNames from "classnames";
import DeleteIcon from "../../../../assets/images/close.svg";
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";

interface ISkill {
    id: string;
    skill: string;
}

interface SkillBoxInputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, UseControllerProps {
    label?: string;
    name: string;
    defaultValue?: string;
    endIcon?: React.ReactNode | React.ReactNode[];
    onClickEndIcon?: () => void;
    className?: string;
    skills?: string;
    onChangeSkillBox: (value: any) => void;
}

export const SkillBox: FC<SkillBoxInputProps> = (props) => {
    const {
        className,
        skills,
        onChangeSkillBox
    } = props;

    const { t } = useTranslation();

    const [ items, setItems ] = useState<ISkill[]>(!!skills?.length ? JSON.parse(skills) : []);
    const [ userInput, setUserInput ] = useState('');

    useEffect(() => {
        onChangeSkillBox(items);
    }, [ items, onChangeSkillBox ]);

    const addSkill = (userInput: string) => {
        const isSkill = items?.find((item) => item.skill === userInput);

        if (isSkill) {
            alert(t('profileEditForm.isSkill'));
        }

        if (userInput && !isSkill) {
            const newItem = {
                id: Math.random().toString(36).substr(2, 9),
                skill: userInput
            };
            setItems([ ...items, newItem ]);
        }
    };

    const removeSkill = (id: string) => {
        setItems([ ...items.filter((todo) => todo.id !== id) ]);
    };

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        setUserInput(e.currentTarget.value);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (items?.length >= 6) {
            alert(t('profileEditForm.maxSkills6'));
        } else {
            addSkill(userInput);
        }

        setUserInput("");
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === "Enter") {
            handleSubmit(e);
        }
    };

    return (
        <>
            <div className={classNames(C.textInput, className)}>
                <div className={C.textInput__wrap}>
                    <div className={C.SkillBoxItem}>
                        <input
                            className={classNames(C.textInput__input)}
                            onChange={handleChange}
                            onKeyDown={handleKeyPress}
                            value={userInput}
                            placeholder={t('profileEditForm.skillBoxPlaceholder')}
                            maxLength={50}
                        />
                        <button
                            type="button"
                            className={classNames(C.SkillBoxItem__Button, C.SkillBoxItem__Button_Add)}
                            onClick={handleSubmit}
                        >
                            <AddIcon className={C.SkillBoxItem__Icon} />
                        </button>
                    </div>

                    {items?.map((item) => (
                        <div key={item.id} className={C.SkillBoxItem}>
                            <input
                                className={classNames(C.textInput__input, C.textInput__input_skill)}
                                value={item?.skill}
                                disabled
                            />

                            <button
                                type={'button'}
                                className={classNames(C.SkillBoxItem__Button, C.SkillBoxItem__Button_Delete)}
                                onClick={() => removeSkill(item?.id)}
                            >
                                <DeleteIcon className={C.SkillBoxItem__Icon} />
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};