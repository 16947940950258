import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IRatesState {
    list: any[];
}

const initialState: IRatesState = {
    list: [],
};

const rates = createSlice({
    name: 'rates',
    initialState,
    reducers: {
        setRates(state, action: PayloadAction<any[]>) {
            state.list = action.payload;
        },
    },
});

export const {
    setRates,
} = rates.actions;
export default rates.reducer;