import { Routes } from "core/box/types";
import { Module as Catalog } from "modules/Catalog";
import { Module as Project } from "modules/Project";
import { Module as Search } from "modules/Search";
import { Module as Verify } from "modules/Verify";
import { Module as Profile } from "modules/Profile";
import { Module as ProfileEdit } from "modules/ProfileEdit";
import { Module as Terms } from "modules/Terms";
import { Module as ResetPassword } from "modules/ResetPassword";
import { Module as ProfileProjects } from "modules/ProfileProjects";
import { Module as ProjectDontId } from "modules/ProjectDontId";
import { Module as ERROR404 } from "modules/ERROR404";

export enum RoutesLinkEnum {
    Catalog = '/',
    CatalogItem = '/catalog/:category',
    Project = '/project/:category/:id',
    ProjectDontId = '/project/:category',
    Search = '/search',
    PrivacyPolicy = '/privacy-policy',
    Social = '/social',
    Club = '/club',
    Terms = '/terms/:label',
    Home = '/',
    Verify = '/verify',
    Profile = '/profile/:id',
    ProfileEdit = '/profile/edit',
    ProfileProjects = '/profile/projects',
    ProfileSupportProject = '/profile/support-project',
    ProfileSupportCompany = '/profile/support-company',
    ResetPassword = '/reset-password/:token/:email',
    ERROR404 = '*',
}

export const router: Routes = [
    {
        path: RoutesLinkEnum.ERROR404,
        Component: ERROR404,
    },
    {
        path: RoutesLinkEnum.Catalog,
        Component: Catalog,
    },
    {
        path: RoutesLinkEnum.CatalogItem,
        Component: Catalog,
    },
    {
        path: RoutesLinkEnum.Project,
        Component: Project,
    },
    {
        path: RoutesLinkEnum.Search,
        Component: Search,
    },
    {
        path: RoutesLinkEnum.Home,
        Component: Catalog,
    },
    {
        path: RoutesLinkEnum.Verify,
        Component: Verify,
    },
    {
        path: RoutesLinkEnum.Profile,
        Component: Profile,
    },
    {
        path: RoutesLinkEnum.ProfileEdit,
        Component: ProfileEdit,
    },
    {
        path: RoutesLinkEnum.Terms,
        Component: Terms,
    },
    {
        path: RoutesLinkEnum.ResetPassword,
        Component: ResetPassword,
    },
    {
        path: RoutesLinkEnum.ProfileProjects,
        Component: ProfileProjects,
    },
    {
        path: RoutesLinkEnum.ProfileSupportProject,
        Component: ProfileProjects,
    },
    {
        path: RoutesLinkEnum.ProfileSupportCompany,
        Component: ProfileProjects,
    },
    {
        path: RoutesLinkEnum.ProjectDontId,
        Component: ProjectDontId,
    },
];