import React, { FC, useCallback } from 'react';
import C from "./ProjectList.module.scss";
import { useNavigate } from "react-router";
import { rateConvert } from "core/box/utils/rateConvert";
import { GetProjectOwnerr } from "../../GetProjectOwnerr";
import { avatarUrl } from "core/box/utils/avatarUrl";
import { Stars } from "../../Stars";
import { useTranslation } from "react-i18next";

interface ProjectListProps {
    data: any[];
    page: number;
}

const ProjectListComponent: FC<ProjectListProps> = ({ data, page }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const parseUrl = useCallback((jsonString: any) => (
        JSON.parse(jsonString).filter((item: any) => item?.title === "Сообщество")[0]?.url
    ), []);

    const handleClickItem = useCallback((category: string, id: string) => {
        navigate(`/project/${category}/${id}`);
    }, [ navigate ]);

    const handleClickLink = useCallback((e: any) => {
        e.stopPropagation();
    }, []);

    return (
        <div className="table">
            <div className={`table__grid ${C.Grid}`}>
                <div className={`table__th`}>{t("catalog.position")}</div>
                <div className={`table__th`}>{t("catalog.nameProduct")}</div>
                <div className={`table__th table__center`}>{t("catalog.rate")}</div>
                <div className={`table__th table__center`}>{t("catalog.owner")}</div>
                <div className={`table__th table__center`}>{t("catalog.community")}</div>
                <div className={`table__th table__center`}>{t("catalog.description")}</div>
            </div>
            {data?.map((item, index) => (
                <button
                    key={item.project_id}
                    className={`${C.Grid} table__body table__grid`}
                    onClick={() => handleClickItem(item?.category, item?.project_id)}
                >
                    <div className={`table__td`}>
                        <Stars projectId={item?.project_id} />
                        {((page - 1) * 10 + 1) + index}
                    </div>
                    <div className={`table__td`}>
                        {!!item?.logo?.length && <img className={C.Avatar} src={avatarUrl(item?.logo)} alt="" />}
                        {!item?.logo?.length && <span className={C.Avatar__Default} />}

                        <span className={C.Name}>{item?.title}</span>
                    </div>
                    <div className={`table__td table__center`}>
                        {rateConvert(item?.rate)}
                    </div>
                    <div className={`table__td table__center`}>
                        <GetProjectOwnerr data={item?.owner} />
                    </div>
                    <div className={`table__td table__center`}>
                        {!!parseUrl(item.links)?.length ? (
                            <a
                                href={parseUrl(item.links)}
                                target={"_blank"}
                                onClick={handleClickLink}
                                className={C.Link} rel="noreferrer"
                            >
                                {parseUrl(item.links)}
                            </a>
                        ) : '-'}
                    </div>
                    <div className={`table__td table__center`}>
                        <span className={C.Desc}>
                            {item.description}
                        </span>
                        <span className={C.More}>{t("catalog.more")}</span>
                    </div>
                </button>
            ))}
        </div>
    );
};

export const ProjectList = React.memo(ProjectListComponent);