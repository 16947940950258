import * as React from 'react';
import { DetailedHTMLProps, FC, SelectHTMLAttributes } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useController, UseControllerProps } from "react-hook-form";
import C from './SelectMui.module.scss';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};

function getStyles(name: string, valueSelect: string, theme: Theme) {
    return {
        fontWeight:
            valueSelect.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

interface OptionItem {
    value: string;
    label: string;
}

interface SelectMuiProps extends DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, UseControllerProps> {
    options: OptionItem[];
    placeholder: string;
    name: string;
    label?: string;
}

export const SelectMui: FC<SelectMuiProps> = ({ options, placeholder, label, name }) => {
    const theme = useTheme();

    const { field } = useController({ name });

    return (
        <label className={C.Select}>
            {label && <div className={C.Select__Label}>{label}</div>}

            <Select
                className={C.Input}
                displayEmpty
                value={field.value}
                onChange={field.onChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <span className={C.Placeholder}>{placeholder}</span>;
                    }

                    return selected;
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
            >
                <MenuItem disabled value="">
                    <em>{placeholder}</em>
                </MenuItem>
                {options?.map((item, index) => (
                    <MenuItem
                        key={item?.value + item.label}
                        value={item?.value}
                        style={getStyles(item.value, field.value, theme)}
                    >
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </label>
    );
};