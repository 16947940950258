import React from 'react';
import C from './RootPage.module.scss';
import { AsideDashboard } from "../../../../core/components/organisms/AsideDashboard";
import { ProfileEditForm } from "../ProfileEditForm";

export const RootPage = () => {
    return (
        <div className="container">
            <div className={C.RootPage}>
                <div className={C.Wrap}>
                    <div className={C.Content}>
                        <ProfileEditForm />
                    </div>
                    <AsideDashboard />
                </div>
            </div>
        </div>
    );
};