import React, { useCallback, useMemo } from 'react';
import C from "../GeneralInfo/GeneralInfo.module.scss";
import { BadgeEvent } from "../../../../core/components/atoms/BadgeEvent";
import { useAppDispatch, useAppSelector } from "../../../../core/box/hooks";
import {
    useDeleteFavorites,
    useDeleteLikes,
    useSetFavorites,
    useSetLikes
} from "../../../../core/services/userEvents.service";
import { useGetMyFavorites, useGetMyLikes } from "../../../../core/services/userInfo.service";
import { openAuth } from "../../../../core/store/auth";
import { useProject } from "../../services/project.service";
import { useParams } from "react-router-dom";
import { createNotification } from "../../../../core/store/notification";

export const LikesFavorites = (props: any) => {
    const dispatch = useAppDispatch();
    const isAuth = useAppSelector(state => state.auth.authMe);
    const paramsUrl = useParams();

    // Состояние моих лайков/избранных
    const myFavorites = useAppSelector(state => state.userInfo.myFavorites);
    const myLikes = useAppSelector(state => state.userInfo.myLikes);
    const myFeatures = useAppSelector(state => state.userInfo.myFeatures);

    const permitLikes = !!myFeatures?.find((item) => item?.tag === "likes");
    const permitFavorites = !!myFeatures?.find((item) => item?.tag === "favorites");

    // Запрос для установки/удаления избранных
    const { isLoadingFavorites, mutateAsyncFavorites } = useSetFavorites();
    const { isLoadingDelFavorites, mutateAsyncDelFavorites } = useDeleteFavorites();

    // Запрос для добавления/удаления избранных
    const { isLoadingLikes, mutateAsyncLikes } = useSetLikes();
    const { isLoadingDelLikes, mutateAsyncDelLikes } = useDeleteLikes();

    // Запрос моих избранных/лайков и проекта
    const { refetchFavorites } = useGetMyFavorites();
    const { refetchLikes } = useGetMyLikes();
    const { refetchProject } = useProject(
        !!paramsUrl?.id ? Number(paramsUrl.id) : 0,
        !!paramsUrl?.category ? paramsUrl?.category : ''
    );

    // Проверяю есть ли в этом проекты мои избранные
    const isMyFavorites = useMemo(() => {
        return !!myFavorites?.find(item => +item?.target_id === +props?.project_id);
    }, [ myFavorites, props?.project_id ]);

    // Клик по избранным
    const handleClickFavorite = useCallback(async () => {
        // Если авторизован выполняю запрос иначе открываю модалку авторизации
        if (isAuth) {
            if (permitFavorites) {
                // Если проект уже в избранном удаляю иначе добавляю и после этого обновляю данные о избранных и проекте
                isMyFavorites
                    ? await mutateAsyncDelFavorites(props?.project_id).then(() => {
                        refetchFavorites();
                        refetchProject();
                    })
                    : await mutateAsyncFavorites(props?.project_id).then(() => {
                        refetchFavorites();
                        refetchProject();
                    });
            } else {
                dispatch(createNotification({
                    type: 'danger',
                    title: 'Ошибка!',
                    message: 'Для совершения действия у Вас должна быть активна подписка с данным правом'
                }));
            }
        } else {
            dispatch(openAuth());
        }
    }, [ dispatch, isAuth, isMyFavorites, mutateAsyncDelFavorites, mutateAsyncFavorites, permitFavorites, props?.project_id, refetchFavorites, refetchProject ]);

    // Проверяю есть ли в этом проекты мои лайки
    const isMyLikes = useMemo(() => {
        return !!myLikes?.find(item => +item?.target_id === props?.project_id);
    }, [ myLikes, props?.project_id ]);

    const handleClickLike = useCallback(async () => {
        // Если авторизован выполняю запрос иначе открываю модалку авторизации
        if (isAuth) {
            if (permitLikes) {
                // Если проект уже лайкнут удаляю иначе добавляю и после этого обновляю данные о лайках и проекте
                isMyLikes
                    ? await mutateAsyncDelLikes(props?.project_id).then(() => {
                        refetchLikes();
                        refetchProject();
                    })
                    : await mutateAsyncLikes(props?.project_id).then(() => {
                        refetchLikes();
                        refetchProject();
                    });
            } else {
                dispatch(createNotification({
                    type: 'danger',
                    title: 'Ошибка!',
                    message: 'Для совершения действия у Вас должна быть активна подписка с данным правом'
                }));
            }
        } else {
            dispatch(openAuth());
        }
    }, [ dispatch, isAuth, isMyLikes, mutateAsyncDelLikes, mutateAsyncLikes, permitLikes, props?.project_id, refetchLikes, refetchProject ]);

    return (
        <div className={C.Head__Right}>
            <BadgeEvent
                className={C.Head__Icon} icon="like" label={props?.likes_count || 0}
                active={isMyLikes}
                onClick={handleClickLike}
                disabled={isLoadingLikes || isLoadingDelLikes}
            />
            <BadgeEvent
                className={C.Head__Icon} icon="star" label={props?.favorites_count || 0}
                active={isMyFavorites}
                onClick={handleClickFavorite}
                disabled={isLoadingFavorites || isLoadingDelFavorites}
            />
        </div>
    );
};