import React, { useCallback, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { TextInput } from "../../atoms/TextInput";
import C from './Auth.module.scss';
import { useAuthMe, useLogIn, ValuesLogIn } from "../../../services/auth.service";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAppDispatch } from 'core/box/hooks';
import { openResetPassword } from 'core/store/auth';
import { Button } from 'core/components/atoms/Button';
import { useTranslation } from "react-i18next";

type FormValuesLogIn = {
    email: string;
    password: string;
};

export const LogInForm = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { refetchAuthMe } = useAuthMe();
    const [ viewPass, setViewPass ] = useState<boolean>(false);
    const { isLoading, isError, mutateAsync } = useLogIn();

    const handleClickEndIcon = useCallback(() => {
        setViewPass(value => !value);
    }, []);

    const { ...methods } = useForm<FormValuesLogIn>({
        mode: 'onBlur',
        defaultValues: {
            email: '',
            password: ''
        }
    });

    const onSubmit: SubmitHandler<FormValuesLogIn> = useCallback(async (data) => {
        const dataValues: ValuesLogIn = {
            login: data?.email,
            password: data?.password,
            device_name: 'web'
        };

        await mutateAsync(dataValues).then((res => {
            if (res) {
                refetchAuthMe();
            }
        }));
    }, [ mutateAsync, refetchAuthMe ]);

    const handleResetPassword = useCallback(() => {
        dispatch(openResetPassword());
    }, [ dispatch ]);

    return (
        <FormProvider {...methods}>
            <form className={C.Auth__Wrap}>
                <TextInput
                    name="email"
                    label={t("logInForm.emailInputLabel")}
                    placeholder={t("logInForm.emailInputPlaceholder")}
                    rules={{
                        required: t("logInForm.emailInputRequiredError"),
                        pattern: {
                            value: /\b[\w\\.+-]+@[\w\\.-]+\.\w{2,4}\b/,
                            message: t("logInForm.emailInputPatternError"),
                        },
                    }}
                />

                <div className={C.Auth__Password}>
                    <TextInput
                        name="password"
                        label={t("logInForm.passwordInputLabel")}
                        type={!viewPass ? 'password' : 'text'}
                        placeholder={t("logInForm.passwordInputPlaceholder")}
                        endIcon={viewPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        onClickEndIcon={handleClickEndIcon}
                        rules={{
                            required: t("logInForm.passwordInputRequiredError"),
                            minLength: {
                                value: 6,
                                message: t("logInForm.passwordInputMinLengthError"),
                            },
                        }}
                    />

                    <button
                        type={"button"}
                        className={C.Auth__ResetPassword}
                        onClick={handleResetPassword}
                    >
                        {t("logInForm.resetPasswordButton")}
                    </button>
                </div>

                {isError && (
                    <div className={C.Auth__ErrorText}>{t("logInForm.errorText")}</div>
                )}

                <Button
                    type="submit"
                    onClick={methods?.handleSubmit(onSubmit)}
                    className={C.Auth__Button}
                    disabled={!methods.formState.isValid || isLoading}
                >
                    {t("logInForm.loginButton")}
                </Button>
            </form>
        </FormProvider>
    );
};
