import network from "../../../api/network";
import { useQuery } from "react-query";

export const SearchService = {
    async getProject(title: string, page: number) {
        return network.get(`projects`, {
            params: {
                search: title,
                per_page: 5,
                page
            }
        });
    },
};

export const useSearch = (title: string, page: number) => {
    const { isLoading, isSuccess, isError, data } = useQuery(
        'SEARCH' + title + page,
        () => {
            return SearchService.getProject(title, page);
        },
        {
            select: ({ data }): any => data,
            onError: () => {
                console.log('error');
            },
            enabled: !!title?.length && !!page,
            staleTime: Infinity
        }
    );

    return { isLoading, isSuccess, isError, data };
};