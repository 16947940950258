import React, { useEffect } from 'react';
import { RootPage } from "../RootPage";
import { useProject } from "../../services/project.service";
import { useParams } from "react-router-dom";
import { ApiLoading } from "core/components/atoms/ApiLoading";
import { useTranslation } from "react-i18next";
import C from '../RootPage/RootPage.module.scss';

export const Module = () => {
    const { t } = useTranslation();
    const paramsUrl = useParams();

    const { data, isLoading, isError, isSuccess } = useProject(
        !!paramsUrl?.id ? Number(paramsUrl.id) : 0,
        !!paramsUrl?.category ? paramsUrl?.category : ''
    );

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <>
            <ApiLoading load={!isLoading}>
                {isError && <div className={C.noResult}>💁 {t("errors.noResultTable")}</div>}
                {isSuccess && <RootPage category={paramsUrl?.category} {...data} />}
            </ApiLoading>
        </>
    );
};