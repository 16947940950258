import React from 'react';
import { RootPage } from "../RootPage";
import { IsAuthLayout } from "../../../../core/components/organisms/IsAuthLayout";

export const Module = () => {
    return (
        <IsAuthLayout>
            <RootPage />
        </IsAuthLayout>
    );
};