import React, { useCallback } from 'react';
import C from "./Categories.module.scss";
import { NavLink, useParams } from "react-router-dom";
import { setProjectPage, setProjectTotal } from "../../store/moduleCatalog";
import { useAppDispatch, useAppSelector } from "../../../../core/box/hooks";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const catalogLink = '/catalog/';

export const Categories = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const paramsUrl = useParams().category;
    const theme = useAppSelector(state => state.userInterface.theme);

    const listCategories = useAppSelector(state => state.category.list);

    const resetPage = useCallback(() => {
        dispatch(setProjectPage({ page: 1 }));
        dispatch(setProjectTotal({ total: 1 }));
    }, [ dispatch ]);

    return (
        <div className={C.Tabs}>
            {!!listCategories?.length && listCategories.map((item) => (
                <NavLink
                    key={item.id}
                    className={({ isActive }) => classNames(C.Tab, {
                        [C.Tab_Active]: isActive || (!paramsUrl && item.name === 'tokens'),
                        [C.Tab_Dark]: theme === 'dark',
                        [C.Tab_Light]: theme === 'light',
                        [C.Tab_Premium]: theme === 'light-premium' || theme === 'dark-premium',
                    })}
                    to={catalogLink + item.name}
                    onClick={resetPage}
                >
                    {t(`categories.${item.name}`, item.title)}
                </NavLink>
            ))}
        </div>
    );
};