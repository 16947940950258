import { createSlice } from '@reduxjs/toolkit';

interface courseState {
    pro: string | number,
    del: string | number,
}

const initialState: courseState = {
    pro: '-',
    del: '-',
};

const course = createSlice({
    name: 'course',
    initialState,
    reducers: {
        setPro(state, action) {
            state.pro = action.payload;
        },
        setDel(state, action) {
            state.del = action.payload;
        },
    },
});

export const {
    setPro,
    setDel
} = course.actions;
export default course.reducer;