import React, { useEffect } from 'react';
import { RootPage } from "../RootPage";
import { IsAuthLayout } from "../../../../core/components/organisms/IsAuthLayout";

export const Module = () => {
    useEffect(() => {
        localStorage.removeItem('first_log_in');
    }, []);

    return (
        <IsAuthLayout>
            <RootPage />
        </IsAuthLayout>
    );
};