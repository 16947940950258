import network from "../../../api/network";
import { useMutation, useQuery } from "react-query";
import { useAppSelector } from "../../../core/box/hooks";
import { IOwner } from "../../Catalog/components/GetProjectOwnerr";
import { CatalogService } from "../../Catalog/services/catalog.service";

export type FormValuesSupportProject = {
    title: string,
    content: string,
};

export const profileProjectsService = {
    async getMyCompany(id: string, page: number) {
        return network.get(`/user/${id}/company`, {
            params: {
                page,
                per_page: 3,
                with_company_information: 1
            }
        });
    },
    async getMyProjects(id: string, page: number) {
        return network.get(`/user/${id}/project`, {
            params: {
                page,
                per_page: 3,
                sortBy: 'rate,desc',
            }
        });
    },
    async getMySupports(page: number) {
        return network.get(`mySupports`, {
            params: {
                page,
                per_page: 3
            }
        });
    },
    async supportProject(data: FormValuesSupportProject) {
        return network.post(`supportUserStore`, data);
    },
    async getOwner(query: IOwner) {
        return network.get(query?.target_type === "company" ? `companies/${query?.target_id}` : `user_profile/${query?.target_id}`);
    },
};

export const useGetMyCompany = (page: number) => {
    const authMe = useAppSelector(state => state.auth.authMe);

    const { isLoading, isSuccess, isError, data } = useQuery(
        'PROFILE_PROJECTS/MY_COMPANY',
        () => profileProjectsService.getMyCompany(authMe?.id, page),
        {
            select: ({ data }) => data,
            enabled: !!page
        }
    );

    return { isLoading, isSuccess, isError, data };
};

export const useGetMyProject = (page: number) => {
    const authMe = useAppSelector(state => state.auth.authMe);

    const { isLoading, isSuccess, isError, data } = useQuery(
        'PROFILE_PROJECTS/MY_PROJECT' + page,
        () => profileProjectsService.getMyProjects(authMe?.id, page),
        {
            select: ({ data }) => data,
            enabled: !!page
        }
    );

    return { isLoading, isSuccess, isError, data };
};

export const useGetMySupports = (page: number) => {
    const { isLoading, isSuccess, isError, data } = useQuery(
        'PROFILE_PROJECTS/MY_SUPPORTS' + page,
        () => profileProjectsService.getMySupports(page),
        {
            select: ({ data }) => data,
            enabled: !!page
        }
    );

    return { isLoading, isSuccess, isError, data };
};

export const useSupportProject = () => {
    const { isLoading, isSuccess, isError, data, mutateAsync } = useMutation(
        'PROFILE_PROJECTS/SUPPORT_PROJECT',
        (data: FormValuesSupportProject) => profileProjectsService.supportProject(data),
    );

    return { isLoading, isSuccess, isError, data, mutateAsync };
};

export const useOwner = (query: IOwner) => {
    const { isLoading: isLoadingOwner, isSuccess: isSuccessOwner, data: dataOwner } = useQuery(
        'CATALOG/OWNER' + query?.target_type + query?.target_id,
        () => {
            return CatalogService.getOwner(query);
        },
        {
            select: ({ data }): any => data?.data,
            onError: () => {
                console.log('error');
            },
            enabled: !!query?.target_type && !!query?.target_id,
        }
    );

    return { isLoadingOwner, isSuccessOwner, dataOwner };
};