import React, { useCallback, useEffect, useMemo } from 'react';
import C from './Catalog.module.scss';
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../core/box/hooks";
import { useProjects } from "../../services/catalog.service";
import { UsePagination } from "../../../../core/components/molecules/UsePagination";
import { setProjectPage, setProjectTotal } from "../../store/moduleCatalog";
import { Categories } from "../Categories";
import { ValidatorsList } from "../../components/Lists/ValidatorsList";
import { ProjectList } from "../../components/Lists/ProjectList";
import { CoinsList } from "../../components/Lists/CoinsList";
import { RecommendList } from "../../components/Lists/RecommendList";
import { SkeletonCatalog } from "../../../../core/components/atoms/SkeletonCatalog";
import { TitleTable } from "../../components/TitleTable";
import { FavoriteList } from "../../components/Lists/FavoriteList";
import { useTranslation } from "react-i18next";


export const Catalog = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const paramsUrl = useParams().category;
    const myFavorites = useAppSelector(state => state.userInfo.myFavorites);

    const projectParams = useAppSelector(state => state.moduleCatalog.projectsParams);

    const { data, isLoading, isError } = useProjects(paramsUrl ? paramsUrl : 'tokens', projectParams.page);

    const dataList = useMemo(() => {
        if ((!paramsUrl || paramsUrl === 'tokens' || paramsUrl === 'validators') && !!data?.data?.length && !isLoading) {
            return data?.data?.map((item: any) => ({
                ...item,
                data: item?.data_extended
            }));
        }

        return data?.data;
    }, [ data?.data, isLoading, paramsUrl ]);

    useEffect(() => {
        if (data?.meta?.total) {
            dispatch(setProjectTotal({ total: data.meta.total }));
        }
    }, [ data?.meta?.total, dispatch ]);

    /**
     * Пагинация
     */
    const onChangePagination = useCallback((event: React.ChangeEvent<unknown>, page: number) => {
        dispatch(setProjectPage({ page }));
    }, [ dispatch ]);

    return (
        <div className={C.Catalog}>

            {paramsUrl === 'recommend' && <TitleTable title={t("catalog.titleTop")} />}
            {paramsUrl === 'favorite' && <TitleTable title={t("catalog.titleFavorite")} />}
            {(paramsUrl !== 'recommend' && paramsUrl !== 'favorite') && <Categories />}

            {isLoading && <SkeletonCatalog />}

            {!isLoading && !dataList?.length && paramsUrl !== 'favorite' && (
                <div className={C.noResult}>💁 {t("errors.noResultTable")}</div>
            )}

            {isError && (
                <div className={C.noResult}>⛔️ {t("errors.noResultTable")}</div>
            )}

            {(!paramsUrl || paramsUrl === 'tokens') && !!dataList?.length && !isLoading && (
                <CoinsList data={dataList} page={projectParams.page} />
            )}

            {paramsUrl === 'validators' && !!dataList?.length && !isLoading && (
                <ValidatorsList data={dataList} page={projectParams.page} />
            )}

            {(paramsUrl !== 'validators' && paramsUrl !== 'tokens' && paramsUrl !== 'recommend') && paramsUrl && !!dataList?.length && (
                <ProjectList data={dataList} page={projectParams.page} />
            )}

            {paramsUrl === 'recommend' && !!dataList?.length && !isLoading && (
                <RecommendList data={dataList} page={projectParams.page} />
            )}

            {paramsUrl === 'favorite' && !!myFavorites?.length && (
                <FavoriteList data={myFavorites} />
            )}

            {paramsUrl !== 'favorite' && (
                <UsePagination
                    className={C.Pagination}
                    page={projectParams.page}
                    onChange={onChangePagination}
                    count={Math.ceil(projectParams?.total / 10)}
                />
            )}
        </div>
    );
};