import React, { useCallback, useState } from 'react';
import C from '../../../../core/components/organisms/Auth/Auth.module.scss';
import { TextInput } from '../../../../core/components/atoms/TextInput';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import CloseIcon from '../../../../assets/images/close.svg';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useResetPasswordPage, ValuesResetPasswordApi } from '../../services/resetPassword.service';
import CheckIcon from '../../../../assets/images/check-notif.svg';
import classNames from 'classnames';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

type FormValuesResetPassword = {
    password: string;
    password_confirmation: string;
};

export const ResetPasswordForm = () => {
    const { t } = useTranslation();  // Initialize useTranslation
    const navigate = useNavigate();
    const params = useParams();
    const [ viewPass, setViewPass ] = useState<boolean>(false);

    const handleClickEndIcon = useCallback(() => {
        setViewPass((value) => !value);
    }, []);

    const { isLoading, isSuccess, isError, mutateAsync } = useResetPasswordPage();

    const { ...methods } = useForm<FormValuesResetPassword>({
        mode: 'onBlur',
        defaultValues: {
            password: '',
            password_confirmation: '',
        },
    });

    const onSubmit: SubmitHandler<FormValuesResetPassword> = useCallback(async (data) => {
        if (params?.token && params?.email) {
            const dataValues: ValuesResetPasswordApi = {
                password: data?.password,
                password_confirmation: data?.password_confirmation,
                token: params?.token,
                email: params?.email,
            };

            await mutateAsync(dataValues);
        }
    }, [ mutateAsync, params?.email, params?.token ]);

    if (isSuccess) {
        return (
            <div className={C.Auth}>
                <div className={C.Auth__Body}>
                    <div className={C.Auth__Wrap}>
                        <div className={C.ResetSuccess}>
                            <CheckIcon className={C.ResetSuccess__Icon} />
                            <div className={C.ResetSuccess__Title}>{t('resetPassword.successTitle')}</div>
                            <div className={C.ResetSuccess__Text}>{t('resetPassword.successText')}</div>

                            <button type="button" onClick={() => navigate('/')} className={C.Auth__Button}>
                                {t('resetPassword.backToHome')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={C.Auth}>
            <div className={C.Auth__Body}>
                <CloseIcon onClick={() => navigate('/')} className={C.Auth__Close} />
                <FormProvider {...methods}>
                    <form className={C.Auth__Wrap}>
                        <div>
                            <h2 className={C.Auth__Title}>{t('resetPassword.updatePassword')}</h2>
                            <p className={C.Auth__Text}>{t('resetPassword.enterNewPassword')}</p>
                        </div>

                        <TextInput
                            name="password"
                            label={t('resetPassword.newPassword')}
                            type={!viewPass ? 'password' : 'text'}
                            placeholder={t('resetPassword.enterYourPassword')}
                            endIcon={viewPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            onClickEndIcon={handleClickEndIcon}
                            rules={{
                                required: t('resetPassword.validation.required'),
                                minLength: {
                                    value: 8,
                                    message: t('resetPassword.validation.minLength', { count: 8 }),
                                },
                            }}
                        />

                        <TextInput
                            name="password_confirmation"
                            label={t('resetPassword.confirmPassword')}
                            type="password"
                            placeholder={t('resetPassword.confirmYourPassword')}
                            rules={{
                                required: t('resetPassword.validation.confirmPassword'),
                                validate: (val: string) => {
                                    if (methods.watch('password') !== val) {
                                        return t('resetPassword.validation.passwordMismatch');
                                    }
                                },
                            }}
                        />

                        {isError && <div className={C.Auth__Error}>{t('resetPassword.error')}</div>}

                        <button
                            type="submit"
                            onClick={methods?.handleSubmit(onSubmit)}
                            className={classNames(C.Auth__Button, {
                                [C.Auth__Button_Disabled]: !methods.formState.isValid || isLoading,
                            })}
                            disabled={!methods.formState.isValid || isLoading}
                        >
                            {t('resetPassword.updatePassword')}
                        </button>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};