import React, { useCallback, useMemo } from 'react';
import C from './ProfileEditForm.module.scss';
import CloseIcon from '../../../../assets/images/close.svg';
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { TextInput } from "../../../../core/components/atoms/TextInput";
import { Textarea } from "../../../../core/components/atoms/Textarea";
import { SelectMui } from "../../../../core/components/molecules/SelectMui";
import { SkillBox } from "../../../../core/components/molecules/SkillBox";
import classNames from "classnames";
import { useAppSelector } from "../../../../core/box/hooks";
import { formatDateFromInput } from "../../../../core/box/utils/formatDateFromInput";
import { useProfileEdit, useProfileEditNick } from "../../services/profileEdit.service";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

type FormValuesProfile = {
    nick: string;
    first_name: string;
    last_name: string;
    about_me: string;
    sex: string;
    birthday: string;
    city: string;
    education: string;
    skill_box: string;
};

export const ProfileEditForm = () => {
    const { t } = useTranslation();
    const authMe = useAppSelector(state => state.auth.authMe);

    const { isLoading, mutateAsync } = useProfileEdit();
    const { mutateAsyncNick } = useProfileEditNick();

    const {
        nick,
        about_me,
        last_name,
        first_name,
        skill_box,
        sex,
        city,
        education,
        birthday
    } = useMemo(() => {
        if (authMe?.user_information) {
            return {
                nick: authMe?.nick,
                about_me: authMe?.user_information?.about_me?.value,
                first_name: authMe?.user_information?.first_name?.value,
                last_name: authMe?.user_information?.last_name?.value,
                skill_box: !!authMe?.user_information?.skill_box?.value?.length ? authMe?.user_information?.skill_box?.value : '',
                sex: authMe?.user_information?.sex?.value,
                birthday: authMe?.user_information?.birthday?.value,
                city: authMe?.user_information?.city?.value,
                education: authMe?.user_information?.education?.value,
            };
        }

        return {};
    }, [ authMe?.nick, authMe?.user_information ]);

    const { ...methods } = useForm<FormValuesProfile>({
        mode: 'onChange',
        defaultValues: {
            nick: nick || '',
            first_name: first_name || '',
            last_name: last_name || '',
            about_me: about_me || '',
            sex: sex || '',
            birthday: formatDateFromInput(birthday) || '',
            city: city || '',
            education: education || '',
            skill_box: skill_box || '{}',
        }
    });

    const onSubmit: SubmitHandler<FormValuesProfile> = useCallback(async (data) => {
        const records = [
            { user_id: authMe?.id, value: data.about_me, system_name: 'about_me' },
            { user_id: authMe?.id, value: data.last_name, system_name: 'last_name' },
            { user_id: authMe?.id, value: data.first_name, system_name: 'first_name' },
            { user_id: authMe?.id, value: data.skill_box, system_name: 'skill_box' },
            { user_id: authMe?.id, value: data.sex, system_name: 'sex' },
            { user_id: authMe?.id, value: data.city, system_name: 'city' },
            { user_id: authMe?.id, value: data.education, system_name: 'education' },
            { user_id: authMe?.id, value: data.birthday, system_name: 'birthday' }
        ];

        await mutateAsync(records?.filter((item) => !!item.value?.length && item?.value !== "NaN-aN-aN"));

        if (!!data?.nick?.length) {
            await mutateAsyncNick(data?.nick);
        }
    }, [ authMe?.id, mutateAsync, mutateAsyncNick ]);

    const changeSkillBox = (value: any) => methods.setValue('skill_box', JSON.stringify(value));

    return (
        <div className={C.ProfileEditForm}>
            <div className={C.Head}>
                <div>
                    <h1 className={C.Title}>{t('profileEditForm.title')}</h1>
                    <p className={C.Desc}>{t('profileEditForm.desc')}</p>
                </div>

                <NavLink className={C.Close} to={`/profile/${authMe?.id}`}>
                    <CloseIcon className={C.Close__Icon} />
                </NavLink>
            </div>
            <FormProvider {...methods}>
                <form className={C.Form}>
                    <TextInput
                        name="first_name"
                        label={t('profileEditForm.firstNameLabel')}
                        placeholder={t('profileEditForm.firstNamePlaceholder')}
                        rules={{
                            maxLength: {
                                value: 20,
                                message: t('profileEditForm.maxLength20')
                            },
                        }}
                    />

                    <TextInput
                        name="last_name"
                        label={t('profileEditForm.lastNameLabel')}
                        placeholder={t('profileEditForm.lastNamePlaceholder')}
                        rules={{
                            maxLength: {
                                value: 30,
                                message: t('profileEditForm.maxLength30')
                            },
                        }}
                    />

                    <TextInput
                        name="nick"
                        label={t('profileEditForm.nickLabel')}
                        placeholder={t('profileEditForm.nickPlaceholder')}
                        rules={{
                            maxLength: {
                                value: 50,
                                message: t('profileEditForm.maxLength50')
                            },
                        }}
                    />

                    <Textarea
                        name={'about_me'}
                        label={t('profileEditForm.aboutMeLabel')}
                        placeholder={t('profileEditForm.aboutMePlaceholder')}
                        rules={{
                            maxLength: {
                                value: 1000,
                                message: t('profileEditForm.validation.maxLength1000')
                            },
                        }}
                    />

                    <SelectMui
                        name={'sex'}
                        options={[
                            {
                                label: t('profileEditForm.male'),
                                value: t('profileEditForm.male')
                            },
                            {
                                label: t('profileEditForm.female'),
                                value: t('profileEditForm.female')
                            }
                        ]}
                        label={t('profileEditForm.sexLabel')}
                        placeholder={t('profileEditForm.sexPlaceholder')}
                    />

                    <TextInput
                        name="birthday"
                        label={t('profileEditForm.birthdayLabel')}
                        placeholder={t('profileEditForm.birthdayPlaceholder')}
                        type={'date'}
                    />

                    <TextInput
                        name="city"
                        label={t('profileEditForm.cityLabel')}
                        placeholder={t('profileEditForm.cityPlaceholder')}
                    />

                    <TextInput
                        name="education"
                        label={t('profileEditForm.educationLabel')}
                        placeholder={t('profileEditForm.educationPlaceholder')}
                    />

                    <div>
                        <h3>{t('profileEditForm.skillBoxTitle')}</h3>
                        <p className={C.Desc}>{t('profileEditForm.skillBoxDesc')}</p>
                    </div>

                    <SkillBox name={'skill_box'} skills={skill_box} onChangeSkillBox={changeSkillBox} />

                    <button
                        type="button"
                        onClick={methods?.handleSubmit(onSubmit)}
                        className={classNames('btn', C.Button)}
                        disabled={!methods.formState.isValid || isLoading}
                    >
                        {t('profileEditForm.saveButton')}
                    </button>
                </form>
            </FormProvider>
        </div>
    );
};