import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from "react";
import { setTheme } from "core/store/userInterface";
import { AppDispatch, RootState } from 'core/store';
import { TypeTheme } from "core/box/types";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useThemeDetector = () => {
    const dispatch = useAppDispatch();
    const theme = useAppSelector(state => state.userInterface.theme);
    const myFeatures = useAppSelector(state => state.userInfo.myFeatures);
    const isPermitPremiumTheme = !!myFeatures?.find(item => item.tag === "premium_theme");

    const getTheme = useMemo((): TypeTheme => {
        const LSTheme = localStorage.getItem('theme');

        switch (LSTheme) {
            case ('light'):
                return 'light';
            case ('light-premium'):
                return 'light-premium';
            case ('dark-premium'):
                return 'dark-premium';
            default:
                return "dark";
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('theme', theme);
    }, [ theme ]);

    useEffect(() => {
        dispatch(setTheme(getTheme));
    }, []);

    useEffect(() => {
        const body = document.getElementById('body');

        if (body) {
            body.className = '';
            body.className = `theme-${theme}`;
        }
    }, [ theme ]);
};