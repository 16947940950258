import React from 'react';
import classNames from "classnames";
import C from './RootPage.module.scss';
import { ResetPasswordForm } from "../ResetPasswordForm";

export const RootPage = () => {

    return (
        <div className={classNames('container', C.RootPage)}>
            <ResetPasswordForm />
        </div>
    );
};