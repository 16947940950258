import React, { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';
import { useController, UseControllerProps, useFormContext } from "react-hook-form";
import C from './Textarea.module.scss';
import classNames from "classnames";

interface TextareaProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>, UseControllerProps {
    label?: string;
    name: string;
    defaultValue?: string;
    endIcon?: React.ReactNode | React.ReactNode[];
    onClickEndIcon?: () => void;
    className?: string;
}

export const Textarea: FC<TextareaProps> = (props) => {
    const formContext = useFormContext();
    const { formState } = formContext;

    const {
        name,
        label,
        rules,
        defaultValue,
        endIcon,
        placeholder,
        onClickEndIcon,
        className
    } = props;

    const { field } = useController({ name, rules, defaultValue });
    const hasError = Boolean(formState?.errors[name]);

    return (
        <label className={classNames(C.Textarea, className)}>
            {label && <span className={C.Textarea__Label}>{label}</span>}
            <div className={C.Textarea__Wrap}>
                <textarea
                    className={classNames(C.Textarea__Input, {
                        [C.Textarea__Input_Error]: hasError
                    })}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    placeholder={placeholder}
                />

                {endIcon && <span className={C.Textarea__EndIcon} onClick={onClickEndIcon}>{endIcon}</span>}
            </div>

            {hasError && <span className={C.Textarea__Error}>{`${formState?.errors[name]?.message}`}</span>}
        </label>
    );
};