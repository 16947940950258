import { useQuery } from "react-query";
import network from "../../../api/network";

export const ProjectService = {
    async getProject(id: number, category: string) {
        return network.get(`projects/${id}`, {
            params: {
                category_like: category
            }
        });
    },
};

export const useProject = (id: number, category: string) => {
    const { isLoading, isSuccess, isError, data, refetch: refetchProject } = useQuery(
        'PROJECT' + id + category,
        () => {
            return ProjectService.getProject(id, category);
        },
        {
            select: ({ data }): any => {
                return {
                    ...data,
                    data: data?.data,
                    links: !!data?.data?.links?.length ? JSON.parse(data?.data.links) : []
                };
            },
            onError: () => {
                console.log('error');
            },
            enabled: !!id && !!category,
        }
    );

    return { isLoading, isSuccess, isError, data, refetchProject };
};