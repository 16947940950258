import React, { FC } from 'react';
import C from './Empty.module.scss';
import ErrorIcon from '../../../../assets/images/error.svg';
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface EmptyProps {
    title: string;
    to?: string;
}

export const Empty: FC<EmptyProps> = ({ title, to }) => {
    const { t } = useTranslation()

    return (
        <div className={C.Empty}>
            <ErrorIcon className={C.Icon} />
            <div className={C.Title}>{title}</div>
            <div className={C.SubTitle}>{t("profileProjects.empty.title")}</div>
            {to && (
                <NavLink to={to} className={'btn'}>
                    {t("profileProjects.empty.btnText")}
                </NavLink>
            )}
        </div>
    );
};