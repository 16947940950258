import React, { FC, useCallback } from 'react';
import C from "./RecommendList.module.scss";
import { useNavigate } from "react-router";
import { rateConvert } from "core/box/utils/rateConvert";
import { avatarUrl } from "core/box/utils/avatarUrl";
import { Stars } from "../../Stars";
import { useTranslation } from "react-i18next";

interface RecommendListProps {
    data: any[];
    page: number;
}

const RecommendListComponent: FC<RecommendListProps> = ({ data, page }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const avatarLink = useCallback((category: string, item: any) => {
        if (category === 'tokens') {
            return item?.data_extended?.coin?.coin?.avatar;
        }

        if (category === 'validators') {
            return item?.data_extended?.list?.identity;
        }

        return item?.logo;
    }, []);

    const handleClickItem = useCallback((category: string, id: string) => {
        navigate(`/project/${category}/${id}`);
    }, [ navigate ]);

    return (
        <div className="table">
            <div className={`table__grid ${C.Grid}`}>
                <div className={`table__th`}>{t("catalog.position")}</div>
                <div className={`table__th`}>{t("catalog.nameProduct")}</div>
                <div className={`table__th table__center`}>{t("catalog.rate")}</div>
            </div>
            {data?.map((item, index) => (
                <button
                    key={item.project_id}
                    className={`${C.Grid} table__body table__grid`}
                    onClick={() => handleClickItem(item?.category, item?.project_id)}
                >
                    <div className={`table__td`}>
                        <Stars projectId={item?.project_id} />
                        {((page - 1) * 10 + 1) + index}
                    </div>
                    <div className={`table__td`}>
                        <img className={C.Avatar} src={avatarUrl(avatarLink(item.category, item))} alt="" />
                        <span className={C.Name}>{item.title}</span>
                    </div>
                    <div className={`table__td table__center`}>
                        {rateConvert(item?.rate)}
                    </div>
                </button>
            ))}
        </div>
    );
};

export const RecommendList = React.memo(RecommendListComponent);