import React from 'react';
import C from '../RootPage/RootPage.module.scss';
import classNames from 'classnames';
import { useGetMyCompany } from '../../services/profileProjects.service';
import { useAppSelector } from 'core/box/hooks';
import { Skeleton } from '@mui/material';
import { useMediaQuery } from '@react-hook/media-query';
import { MediaQuery } from 'core/box/types';
import { NavLink } from 'react-router-dom';
import { Empty } from '../../components/Empty';
import { RoutesLinkEnum } from 'core/box/router';
import { useTranslation } from 'react-i18next';

export const Teams = () => {
    const { t } = useTranslation();
    const page = useAppSelector((state) => state.moduleProfileProjects.teamPage);
    const authMe = useAppSelector((state) => state?.auth?.authMe);

    const { data, isLoading } = useGetMyCompany(page);

    const isMobile = useMediaQuery(`only screen and (max-width: ${MediaQuery.XL})`);

    return (
        <div className={C.Projects}>
            {!!data?.data?.length && (
                <>
                    <div className={C.Projects__Head}>{t('profileProjects.teams.yourTeamsAndCompanies')}</div>

                    <div className={'table'}>
                        <div className={classNames('table__grid', C.Grid)}>
                            <div className={`table__th`}>{t('profileProjects.teams.name')}</div>
                            <div className={`table__th table__center`}>{t('profileProjects.teams.projects')}</div>
                            <div className={`table__th table__center`}>{t('profileProjects.teams.employees')}</div>
                            <div className={`table__th table__right`}>{t('profileProjects.teams.yourPosition')}</div>
                        </div>

                        {data?.data?.map((item: any, index: number) => (
                            <button key={item.id} className={`${C.Grid} table__body table__grid`} disabled>
                                <div className={`table__td table__td--min`}>{item.name}</div>
                                <div className={`table__td table__td--min table__center`}>{item?.project_count}</div>
                                <div className={`table__td table__td--min table__center`}>{item?.members?.length}</div>
                                <div className={`table__td table__td--min table__right`}>
                                    {item?.members?.find((item: any) => item?.user_id === authMe?.id)?.role_name || '-'}
                                </div>
                            </button>
                        ))}
                    </div>
                </>
            )}

            {isLoading && (
                <div>
                    <Skeleton height={!isMobile ? 36 : 32} />
                    <Skeleton height={!isMobile ? 36 : 32} />
                    <Skeleton height={!isMobile ? 36 : 32} />
                    <Skeleton height={!isMobile ? 36 : 32} />
                </div>
            )}

            {!!data?.data?.length && (
                <div className={C.Footer}>
                    <NavLink className={classNames('btn', C.Btn)} to={RoutesLinkEnum.ProfileSupportCompany}>
                        {t('profileProjects.teams.addNewTeam')}
                    </NavLink>
                </div>
            )}

            {!data?.data?.length && !isLoading && (
                <Empty title={t('profileProjects.teams.emptyListTitle')} to={RoutesLinkEnum.ProfileSupportCompany} />
            )}
        </div>
    );
};
