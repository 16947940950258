import network from "../../../api/network";
import { useMutation } from "react-query";

export type ValuesResetPasswordApi = {
    email: string,
    token: string,
    password: string,
    password_confirmation: string
};

export const ResetPasswordServicePage = {
    async resetPassword(value: ValuesResetPasswordApi) {
        return network.post(`/auth/password/reset`, value);
    },
};

export const useResetPasswordPage = () => {
    const { isLoading, isSuccess, isError, data, error, mutateAsync } = useMutation(
        'RESET_PASSWORD',
        (value: ValuesResetPasswordApi) => ResetPasswordServicePage.resetPassword(value),
        {
            onError: (error: any) => {
                // console.log('error', error);
                // @ts-ignore
                return error?.response?.data;
            },
        }
    );

    return { isLoading, mutateAsync, isSuccess, isError, data, error };
};