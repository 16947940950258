import { configureStore } from '@reduxjs/toolkit';
import userInterface from './userInterface';
import category from './category';
import rates from './rates';
import auth from './auth';
import course from './course';
import userInfo from './userInfo';
import notification from './notification';
import moduleCatalog from '../../modules/Catalog/store/moduleCatalog';
import moduleProfile from '../../modules/Profile/store/moduleProfile';
import moduleProfileProjects from '../../modules/ProfileProjects/store/profileProjects';

const store = configureStore({
    reducer: {
        auth,
        userInterface,
        category,
        rates,
        course,
        userInfo,
        notification,
        moduleCatalog,
        moduleProfile,
        moduleProfileProjects
    },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
