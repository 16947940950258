import React, { FC } from 'react';
import C from "./BadgeEvent.module.scss";
import { Icon } from "../Icon";

interface BadgeEventProps {
    icon: string;
    label: string | number;
    className?: string;
    active: boolean;
    onClick?: (e?: any) => void;
    disabled?: boolean;
}

export const BadgeEvent: FC<BadgeEventProps> = ({
    icon,
    label,
    active,
    className,
    onClick,
    disabled
}) => {
    return (
        <button
            className={`${C.BadgeEvent} ${!!className?.length ? className : ''}`}
            onClick={onClick}
            disabled={disabled}
        >
            <Icon className={`${C.BadgeEvent__Icon} ${active ? C.BadgeEvent__Icon_Active : ''}`} name={icon} />
            <span className={C.BadgeEvent__Label}>{label}</span>
        </button>
    );
};