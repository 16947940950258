import React from 'react';
import { BackUrl } from "../../components/BackUrl";
import { Search } from "../Search";

export const RootPage = () => {
    return (
        <div className="container container--top-space">
            <BackUrl />
            <Search />
        </div>
    );
};