import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { TypeTheme } from "core/box/types";


export type TypeVariantPanel = 'setting' | 'wallet' | null

interface userInterfaceState {
    theme: TypeTheme,
    isDashboardShow: boolean,
    isApiLoaded: boolean,
    isSidebar: boolean,
    isPanel: boolean,
    variantPanel: TypeVariantPanel
}

const initialState: userInterfaceState = {
    theme: 'dark',
    isDashboardShow: false,
    isApiLoaded: false,
    isSidebar: false,
    isPanel: false,
    variantPanel: null
};

const userInterface = createSlice({
    name: 'userInterface',
    initialState,
    reducers: {
        toggleDashboard(state) {
            state.isDashboardShow = !state.isDashboardShow;
        },
        showDashboard(state) {
            state.isDashboardShow = true;
        },
        toggleTheme(state) {
            state.theme = state.theme === "dark" ? "light" : 'dark';
        },
        setTheme(state, action: PayloadAction<TypeTheme>) {
            state.theme = action.payload;
        },
        setApiLoaded(state, action: PayloadAction<{ load: boolean }>) {
            state.isApiLoaded = action.payload.load;
        },
        setOpenSidebar(state) {
            state.isSidebar = true;
        },
        setCloseSidebar(state) {
            state.isSidebar = false;
        },
        openPanel(state) {
            state.isPanel = true;
        },
        closePanel(state) {
            state.isPanel = false;
        },
        setVariantPanel(state, action: PayloadAction<TypeVariantPanel>) {
            state.variantPanel = action.payload;
        }
    },
});

export const {
    toggleDashboard,
    toggleTheme,
    setApiLoaded,
    showDashboard,
    setTheme,
    setOpenSidebar,
    setCloseSidebar,
    openPanel,
    closePanel,
    setVariantPanel
} = userInterface.actions;
export default userInterface.reducer;