import React, { useCallback, useMemo } from 'react';
import C from './UserCard.module.scss';
import { useAppDispatch, useAppSelector } from "../../../box/hooks";
import DefaultUser from "assets/images/user-card/default-user.svg";
import VerifyIcon from "assets/images/user-card/verify.svg";
import classNames from "classnames";
import { Button } from "../../atoms/Button";
import { useNavigate } from "react-router";
import { RoutesLinkEnum } from "../../../box/router";
import { openPanel, setCloseSidebar, setVariantPanel } from "../../../store/userInterface";
import { openAuth } from "../../../store/auth";
import { useTranslation } from "react-i18next";

export const UserCard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const authMe = useAppSelector(state => state.auth.authMe);

    const { last_name, first_name, profile_photo, email_verified_at, nick, email } = useMemo(() => ({
        first_name: authMe?.user_information?.first_name?.value || false,
        last_name: authMe?.user_information?.last_name?.value || false,
        profile_photo: !!authMe?.user_information?.profile_photo?.value ? `${process.env.REACT_APP_URL_BACKEND}${authMe?.user_information?.profile_photo?.value}` : undefined,
        email_verified_at: !!authMe?.email_verified_at?.length || false,
        nick: authMe?.nick || '',
        email: authMe?.email || '',
    }), [ authMe ]);

    const goToButton = useCallback((to: string) => {
        navigate(to);
        dispatch(setCloseSidebar());
    }, [ dispatch, navigate ]);

    const handleSettings = useCallback(() => {
        dispatch(openPanel());
        dispatch(setVariantPanel('setting'));
    }, [ dispatch ]);

    const handleOpenAuth = useCallback(() => {
        dispatch(openAuth());
    }, [ dispatch ]);

    return (
        <div className={C.UserCard}>
            <div className={C.UserCard__wrap}>
                <div className={C.UserCard__content}>
                    {!!profile_photo?.length ? (
                        <img className={C.UserCard__avatar} src={profile_photo} alt="" />
                    ) : (
                        <DefaultUser className={C.UserCard__avatar} />
                    )}

                    <div className={C.UserCard__info}>
                        <div className={C.UserCard__name}>
                            {authMe ? (
                                <>
                                    <VerifyIcon className={classNames(C.UserCard__verify, {
                                        [C.UserCard__verify_done]: email_verified_at
                                    })} />

                                    {`${first_name} ${last_name}`}
                                </>
                            ) : (
                                t('userCard.title')
                            )}
                        </div>

                        <div className={C.UserCard__desc}>
                            {authMe ? (
                                <>
                                    <div>{t('userCard.userName')}: <span>@{nick}</span></div>
                                    <div>{t('userCard.email')}: <span>{email}</span></div>
                                </>
                            ) : (
                                <div>{t('userCard.desc')}</div>
                            )}
                        </div>
                    </div>
                </div>

                <div className={C.UserCard__buttons}>
                    {authMe ? (
                        <Button
                            className={C.UserCard__button}
                            mode={"green"}
                            onClick={() => goToButton(RoutesLinkEnum.ProfileEdit)}
                        >
                            {t('userCard.editProfile')}
                        </Button>
                    ) : (
                        <Button
                            className={C.UserCard__button}
                            mode={"green"}
                            onClick={handleOpenAuth}
                        >
                            {t('userCard.logIn')}
                        </Button>
                    )}


                    <Button
                        className={C.UserCard__button}
                        onClick={handleSettings}
                    >
                        {t('userCard.setting')}
                    </Button>
                </div>
            </div>
        </div>
    );
};