export const rateConvert = (rate: number) => {
    if (rate >= 100) {
        return "AAA";
    }
    if (rate >= 90 && rate <= 99) {
        return "AA";
    }
    if (rate >= 80 && rate <= 89) {
        return "A";
    }
    if (rate >= 70 && rate <= 79) {
        return "BBB";
    }
    if (rate >= 60 && rate <= 69) {
        return "BB";
    }
    if (rate >= 50 && rate <= 59) {
        return "B";
    }
    if (rate >= 40 && rate <= 49) {
        return "CCC";
    }
    if (rate >= 30 && rate <= 39) {
        return "CC";
    }
    if (rate >= 20 && rate <= 29) {
        return "C";
    }
    if (rate >= 10 && rate <= 19) {
        return "NR";
    }
    return "NR";
};