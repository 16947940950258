import React from 'react';
import C from './VerifyForm.module.scss';
import { useAppSelector } from "../../../../core/box/hooks";
import { VerifyFormEmail } from "../../components/VerifyFormEmail";
import { IsVerify } from "../../components/IsVerify";

export const VerifyForm = () => {
    const isVerify = useAppSelector(state => state.auth.authMe?.email_verified_at);

    return (
        <div className={C.VerifyForm}>
            <div className={C.Wrap}>

                {!isVerify && (
                    <VerifyFormEmail />
                )}

                {isVerify && (
                    <IsVerify />
                )}
            </div>
        </div>
    );
};