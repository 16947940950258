import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IProfileState {
    profile: any;
    companies: any[];
    isMe: boolean;
    isOpenAvatarForm: boolean;
}

const initialState: IProfileState = {
    profile: null,
    companies: [],
    isMe: false,
    isOpenAvatarForm: false
};

const profile = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfile: (state, action: PayloadAction<any>) => {
            state.profile = action.payload;
        },
        setCompanies: (state, action: PayloadAction<any>) => {
            state.companies = action.payload;
        },
        setIsMe: (state, action: PayloadAction<boolean>) => {
            state.isMe = action.payload;
        },
        setIsOpenAvatarForm: (state, action: PayloadAction<boolean>) => {
            state.isOpenAvatarForm = action.payload;
        },
        resetProfile: (state) => {
            state.profile = null;
            state.isMe = false;
        },
    }
});

export const { setProfile, setIsOpenAvatarForm, setIsMe, setCompanies } = profile.actions;
export default profile.reducer;