import React, { FC } from 'react';
import usePagination, { UsePaginationProps } from "@mui/material/usePagination";
import C from './UsePagination.module.scss';
import { Icon } from "../../atoms/Icon";

interface IUsePagination extends UsePaginationProps {
    className?: string;
}

export const UsePagination: FC<IUsePagination> = ({ count, onChange, page: pageNumber, className }) => {
    const { items } = usePagination({
        count,
        onChange
    });

    return (
        <ul className={`${C.UsePagination} ${className?.length ? className : ''}`}>
            {items.map(({ page, type, selected, disabled, ...item }, index) => {
                let children = null;

                if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                    children = (
                        <button
                            className={`${C.Button} ${C.Button__Dot}`}
                            type="button"
                            disabled
                            {...item}
                        >
                            ...
                        </button>
                    );
                } else if (type === 'page') {
                    children = (
                        <button
                            className={`${C.Button} ${pageNumber === page ? C.Button__Selected : ''}`}
                            type="button"
                            {...item}
                        >
                            {page}
                        </button>
                    );
                } else {
                    children = (
                        <button
                            className={C.Button}
                            type="button"
                            disabled={(pageNumber === 1 && type === "previous") || (pageNumber === count && type !== "previous")}
                            {...item}
                        >
                            <Icon className={C.Arrow} name={type === "previous" ? "arrow-left" : "arrow-right"} />
                        </button>
                    );
                }

                return <li className={C.Item} key={index}>{children}</li>;
            })}
        </ul>
    );
};