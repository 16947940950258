import React, { FC, useCallback, useState } from 'react';
import classNames from "classnames";
import StarIcon from "../../../../assets/images/star.svg";
import { openAuth } from "../../../../core/store/auth";
import { useDeleteFavorites, useSetFavorites } from "../../../../core/services/userEvents.service";
import { useGetMyFavorites } from "../../../../core/services/userInfo.service";
import { useAppDispatch, useAppSelector } from "../../../../core/box/hooks";

interface StarsProps {
    projectId: string | number;
}

export const Stars: FC<StarsProps> = ({ projectId }) => {
    const dispatch = useAppDispatch();
    const isAuth = useAppSelector(state => state.auth.authMe);
    const myFavorites = useAppSelector(state => state.userInfo.myFavorites);
    const [ loadFavorite, setLoadFavorite ] = useState('');

    const { isLoadingFavorites, mutateAsyncFavorites } = useSetFavorites();
    const { isLoadingDelFavorites, mutateAsyncDelFavorites } = useDeleteFavorites();
    const { refetchFavorites } = useGetMyFavorites();

    const isMyFavorites = useCallback((id: number | string) => {
        return !!myFavorites?.find(item => +item?.target_id === +id);
    }, [ myFavorites ]);

    const handleClickFavorites = useCallback(async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
        e.preventDefault();
        e.stopPropagation();

        setLoadFavorite(id);

        if (isAuth) {
            isMyFavorites(id) ? await mutateAsyncDelFavorites(id).then(() => refetchFavorites()) : await mutateAsyncFavorites(id).then(() => refetchFavorites());
        } else {
            dispatch(openAuth());
        }
    }, [ dispatch, isAuth, isMyFavorites, mutateAsyncDelFavorites, mutateAsyncFavorites, refetchFavorites ]);


    return (
        <button className={classNames(`table__star`, {
            [`table__star--active`]: isMyFavorites(projectId),
            [`table__star--loading`]: (isLoadingFavorites || isLoadingDelFavorites) && loadFavorite.toString() === projectId?.toString()
        })}
                onClick={(e) => handleClickFavorites(e, projectId.toString())}
                disabled={isLoadingFavorites || isLoadingDelFavorites}
        >
            <StarIcon className={'table__star-icon'} />
        </button>
    );
};