import React from 'react';
import C from "../../containers/VerifyForm/VerifyForm.module.scss";
import CheckIcon from "@mui/icons-material/Check";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../../core/box/hooks";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

export const IsVerify = () => {
    const { t } = useTranslation();
    const authMeId = useAppSelector(state => state.auth.authMe.id);

    return (
        <>
            <div className={C.Head}>
                <div className={C.Email}>
                    <CheckIcon className={C.Email__Icon} />
                </div>
            </div>

            <h1 className={C.Title}>{t('verify.emailVerificationSuccess')}</h1>

            <p className={C.Text}>
                {t('verify.fillProfileDataToStartUsingService')}
            </p>

            <NavLink className={classNames('btn', C.Button)}
                     to={`/profile/${authMeId}`}>{t('verify.goToProfile')}</NavLink>
        </>
    );
};
