import React, { useCallback, useMemo, useState } from 'react';
import C from './Tabs.module.scss';
import classNames from "classnames";
import { useAppSelector } from "../../../../core/box/hooks";
import { formatDate } from "../../../../core/box/utils/formatDate";
import { useTranslation } from "react-i18next";

const tabs = [ 'tabProfile', 'tabProjects', 'tabPublic', 'tabLikes', 'tabResume' ];

export const Tabs = () => {
    const { t } = useTranslation();
    const [ activeTab, setActiveTab ] = useState(0);
    const authMe = useAppSelector(state => state.moduleProfile.profile);
    const companies = useAppSelector(state => state.moduleProfile.companies);

    const handleClickTab = useCallback((tabIndex: number) => {
        setActiveTab(tabIndex);
    }, []);

    const { last_name, first_name, sex, skill_box, birthday, city, education } = useMemo(() => {
        if (authMe?.user_information) {
            return {
                first_name: authMe?.user_information?.first_name?.value,
                last_name: authMe?.user_information?.last_name?.value,
                sex: authMe?.user_information?.sex?.value,
                birthday: authMe?.user_information?.birthday?.value,
                city: authMe?.user_information?.city?.value,
                education: authMe?.user_information?.education?.value,
                skill_box: !!authMe?.user_information?.skill_box?.value?.length ? JSON.parse(authMe?.user_information?.skill_box?.value) : [],
            };
        }

        return {};
    }, [ authMe?.user_information ]);

    return (
        <>
            <div className={C.Tabs}>
                <div className={C.Head}>
                    {tabs.map((item, index) => (
                        <button
                            key={index}
                            className={classNames(C.Tab, {
                                [C.Tab_Active]: index === activeTab
                            })}
                            onClick={() => handleClickTab(index)}
                        >
                            {t(`profile.${item}`)}
                        </button>
                    ))}
                </div>

                {activeTab === 0 && (
                    <div className={classNames(C.Content, C.Content__Profile)}>
                        <div className={C.Col}>
                            <h3>{t('profile.mainTitle')}</h3>
                            <p>{t('profile.firstName')}: {first_name || t('profile.noAbout')}</p>
                            <p>{t('profile.lastName')}: {last_name || t('profile.noAbout')}</p>
                            <p>{t('profile.sex')}: {sex || t('profile.noAbout')}</p>
                            <p>{t('profile.birthday')}: {formatDate(birthday, false) || t('profile.noAbout')}</p>
                            <p>{t('profile.city')}: {city || t('profile.noAbout')}</p>
                            <p>{t('profile.education')}: {education || t('profile.noAbout')}</p>
                        </div>
                        <div className={C.Col}>
                            <h3>{t('profile.skillBoxTitle')}</h3>
                            {!!skill_box?.length ? skill_box?.map((item: any, index: number) => (
                                <p key={index}>{item.skill}</p>
                            )) : t('profile.noAbout')}
                        </div>
                        <div className={C.Col}>
                            <h3>{t('profile.projectTitle')}</h3>
                            {!!companies?.length ? companies?.map((item: any, index: number) => (
                                <p key={item?.id}>{item.name}</p>
                            )) : t('profile.noAbout')}
                        </div>
                    </div>
                )}

                {activeTab === 1 && (
                    <>Проекты</>
                )}

                {activeTab === 2 && (
                    <>Публикации</>
                )}

                {activeTab === 3 && (
                    <>Нравится</>
                )}

                {activeTab === 4 && (
                    <>Резюме</>
                )}
            </div>
        </>
    );
};