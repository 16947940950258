import network from "api/network";
import { useAppDispatch, useAppSelector } from "core/box/hooks";
import { setMyFavorites, setMyFeatures, setMyLikes } from "core/store/userInfo";
import { useQuery } from "react-query";

export const profileInfoService = {
    async getMyFavorites() {
        return network.get(`myFavorites`, {
            params: {
                target: "project",
                per_page: 1000,
                sortBy: 'rate,desc',
            }
        });
    },
    async getMyLikes() {
        return network.get(`myLikes`, {
            params: {
                per_page: 1000
            }
        });
    },
    async getMyPlan(planId: number) {
        return network.get(`plans/${planId}`);
    },
};

export const useGetMyFavorites = () => {
    const dispatch = useAppDispatch();
    const isAuth = useAppSelector(state => state?.auth?.authMe);

    const { isLoading, isSuccess, isError, data, refetch: refetchFavorites } = useQuery(
        'CORE_PROFILE_INFO_FAVORITES',
        () => profileInfoService.getMyFavorites(),
        {
            onSuccess: (data: any) => {
                if (data?.data?.data) {
                    dispatch(setMyFavorites(data?.data?.data));
                }
            },
            enabled: !!isAuth
        }
    );

    return { isLoading, isSuccess, isError, data, refetchFavorites };
};

export const useGetMyLikes = () => {
    const dispatch = useAppDispatch();
    const isAuth = useAppSelector(state => state?.auth?.authMe);

    const { isLoading, isSuccess, isError, data, refetch: refetchLikes } = useQuery(
        'CORE_PROFILE_INFO_LIKES',
        () => profileInfoService.getMyLikes(),
        {
            onSuccess: (data: any) => {
                if (data?.data?.data) {
                    dispatch(setMyLikes(data?.data?.data));
                }
            },
            enabled: !!isAuth
        }
    );

    return { isLoading, isSuccess, isError, data, refetchLikes };
};

export const useGetPlan = () => {
    const dispatch = useAppDispatch();
    const isAuth = useAppSelector(state => state?.auth?.authMe);

    const {
        isLoading: isLoadingPlan,
        isSuccess: isSuccessPlan,
        isError: isErrorPlan,
        data: dataPlan,
        refetch: refetchPlan
    } = useQuery(
        'CORE_USER_INFO_PLAN',
        () => profileInfoService.getMyPlan(isAuth?.subscriptions[0]?.plan_id),
        {
            onSuccess: (data: any) => {
                if (data?.data?.data) {
                    dispatch(setMyFeatures(data?.data?.data?.features));
                }
            },
            enabled: !!isAuth?.subscriptions[0]?.plan_id
        }
    );

    return { isLoadingPlan, isSuccessPlan, isErrorPlan, dataPlan, refetchPlan };
};