import network from "../../../api/network";
import { useMutation, useQuery } from "react-query";
import { useAppDispatch, useAppSelector } from "../../../core/box/hooks";
import { setCompanies, setIsOpenAvatarForm, setProfile } from "../store/moduleProfile";
import { IProfileItem } from "../../ProfileEdit/services/profileEdit.service";
import { createNotification } from "../../../core/store/notification";

export const UserProfileService = {
    async getUserProfile(id: string) {
        return network.get(`/user_profile/${id}`);
    },
    async getUserCompany(id: string) {
        return network.get(`/user/${id}/company`);
    },
    async updateAvatar(data: IProfileItem[]) {
        return network.post('/userInformation/upsert', {
            "records": data
        });
    },
    async editAvatar(file: File) {
        return network.post('/upload-file', { file }, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    }
};

export const useUserProfile = (id: string) => {
    const dispatch = useAppDispatch();

    const { isLoading, isSuccess, isError, data } = useQuery(
        'PROFILE__USER_PROFILE' + id,
        () => UserProfileService.getUserProfile(id),
        {
            onSuccess: ({ data }) => {
                dispatch(setProfile(data.data));
            },
            onError: () => {
                dispatch(setProfile(null));
            },
            enabled: !!id?.length,
        }
    );

    return { isLoading, isSuccess, isError, data };
};

export const useUserCompany = (id: string) => {
    const dispatch = useAppDispatch();

    const { isLoading: isLoadingCompany, isSuccess, isError, data: dataCompany } = useQuery(
        'PROFILE__USER_COMPANY' + id,
        () => UserProfileService.getUserCompany(id),
        {
            onSuccess: ({ data }) => {
                dispatch(setCompanies(data.data));
            },
            onError: () => {
                dispatch(setCompanies([]));
            },
            enabled: !!id?.length,
        }
    );

    return { isLoadingCompany, isSuccess, isError, dataCompany };
};

export const useEditAvatar = () => {
    const dispatch = useAppDispatch();
    const { isLoading: isLoadingEdit, isSuccess, isError, data, error, mutateAsync } = useMutation(
        'PROFILE__EDIT_AVATAR',
        (file: File) => UserProfileService.editAvatar(file),
        {
            onError: (error: any) => {
                // console.log('error', error);
                // @ts-ignore
                dispatch(createNotification({
                    type: 'danger',
                    title: 'Ошибка!',
                    message: 'Максимальный вес изображения 2МБ'
                }));
                return error?.response?.data;
            },
        }
    );

    return { isLoadingEdit, mutateAsync, isSuccess, isError, data, error };
};

export const useUpdateAvatar = (path: string) => {
    const dispatch = useAppDispatch();
    const userId = useAppSelector(state => state?.auth?.authMe?.id);

    const { isLoading: isLoadingUpdate, isSuccess, isError, data, error } = useQuery(
        'PROFILE__UPDATE_AVATAR',
        () => UserProfileService.updateAvatar([ {
            system_name: 'profile_photo',
            user_id: userId,
            value: path
        } ]),
        {
            onSuccess: () => {
                dispatch(createNotification({
                    type: 'success',
                    title: 'Аватар успешно обновлен!'
                }));
                dispatch(setIsOpenAvatarForm(false));
            },
            enabled: !!path && !!userId
        },
    );

    return { isLoadingUpdate, isSuccess, isError, data, error };
};