import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IUserInfoState {
    myFavorites: any[];
    myLikes: any[];
    myCompany: any[];
    myProjects: any[];
    myFeatures: any[];
}

const initialState: IUserInfoState = {
    myFavorites: [],
    myLikes: [],
    myCompany: [],
    myProjects: [],
    myFeatures: []
};

const rates = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        setMyFavorites(state, action: PayloadAction<any[]>) {
            state.myFavorites = action.payload;
        },
        setMyLikes(state, action: PayloadAction<any[]>) {
            state.myLikes = action.payload;
        },
        setMyCompany(state, action: PayloadAction<any[]>) {
            state.myCompany = action.payload;
        },
        setMyProjects(state, action: PayloadAction<any[]>) {
            state.myProjects = action.payload;
        },
        setMyFeatures(state, action: PayloadAction<any[]>) {
            state.myFeatures = action.payload;
        },
    },
});

export const {
    setMyFavorites,
    setMyCompany,
    setMyProjects,
    setMyLikes,
    setMyFeatures
} = rates.actions;
export default rates.reducer;