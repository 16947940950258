import React, { useCallback } from 'react';
import C from './SupportForm.module.scss';
import CloseIcon from '../../../../assets/images/close.svg';
import { useNavigate } from "react-router";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { TextInput } from "../../../../core/components/atoms/TextInput";
import classNames from "classnames";
import { FormValuesSupportProject, useSupportProject } from "../../services/profileProjects.service";
import CloudIcon from '../../../../assets/images/cloud.svg';
import { NavLink } from "react-router-dom";
import { RoutesLinkEnum } from "../../../../core/box/router";

// Import translations
import { useTranslation } from 'react-i18next';

type FormValues = {
    name: string,
    lastName: string,
    connect: string,
    nameCompany: string,
    projects: string,
    role: string
};

export const SupportFormCompany = () => {
    const { t } = useTranslation(); // Initialize translation function
    const navigate = useNavigate();

    const { ...methods } = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: {
            name: '',
            lastName: '',
            connect: '',
            nameCompany: '',
            projects: '',
            role: '',
        }
    });

    const { mutateAsync, isLoading, isSuccess } = useSupportProject();

    const onSubmit: SubmitHandler<FormValues> = useCallback(async (data) => {
        const values: FormValuesSupportProject = {
            title: t('profileProjects.supportForm.title', { nameCompany: data?.nameCompany }), // Using translation key
                                                                                               // for title
            content: `
                ${t('profileProjects.supportForm.nameLabel')}: ${data?.name}
                ${t('profileProjects.supportForm.lastNameLabel')}: ${data?.lastName}
                ${t('profileProjects.supportForm.connectLabel')}: ${data?.connect}
                ${t('profileProjects.supportForm.companyLabel')}: ${data?.nameCompany}
                ${t('profileProjects.supportForm.projectsLabel')}: ${data?.projects}
                ${t('profileProjects.supportForm.roleLabel')}: ${data?.role}
            `
        };

        await mutateAsync(values);
    }, [ mutateAsync, t ]);

    return (
        <div className={C.SupportForm}>
            {!isSuccess && (
                <>
                    <div className={C.Head}>
                        <h1 className={C.Title}>{t('profileProjects.supportForm.title')}</h1>
                        <button type={'button'} className={C.Close} onClick={() => navigate(-1)}>
                            <CloseIcon className={C.Close__Icon} />
                        </button>
                    </div>
                    <FormProvider {...methods}>
                        <form className={C.Form}>
                            <TextInput
                                name="name"
                                label={t('profileProjects.supportForm.nameLabel')}
                                placeholder={t('profileProjects.supportForm.namePlaceholder')}
                                rules={{
                                    required: true,
                                    maxLength: {
                                        value: 20,
                                        message: t('profileProjects.supportForm.maxLength20'),
                                    },
                                }}
                            />

                            <TextInput
                                name="lastName"
                                label={t('profileProjects.supportForm.lastNameLabel')}
                                placeholder={t('profileProjects.supportForm.lastNamePlaceholder')}
                                rules={{
                                    required: true,
                                    maxLength: {
                                        value: 30,
                                        message: t('profileProjects.supportForm.maxLength30'),
                                    },
                                }}
                            />

                            <TextInput
                                name="connect"
                                label={t('profileProjects.supportForm.connectLabel')}
                                placeholder={t('profileProjects.supportForm.connectPlaceholder')}
                                rules={{
                                    required: true,
                                    maxLength: {
                                        value: 100,
                                        message: t('profileProjects.supportForm.maxLength100'),
                                    },
                                }}
                            />

                            <TextInput
                                name="nameCompany"
                                label={t('profileProjects.supportForm.companyLabel')}
                                placeholder={t('profileProjects.supportForm.companyPlaceholder')}
                                rules={{
                                    required: true,
                                    maxLength: {
                                        value: 100,
                                        message: t('profileProjects.supportForm.maxLength100'),
                                    },
                                }}
                            />

                            <TextInput
                                name="projects"
                                label={t('profileProjects.supportForm.projectsLabel')}
                                placeholder={t('profileProjects.supportForm.projectsPlaceholder')}
                                rules={{
                                    required: true,
                                    maxLength: {
                                        value: 100,
                                        message: t('profileProjects.supportForm.maxLength1000'),
                                    },
                                }}
                            />

                            <TextInput
                                name="role"
                                label={t('profileProjects.supportForm.roleLabel')}
                                placeholder={t('profileProjects.supportForm.rolePlaceholder')}
                                rules={{
                                    required: true,
                                    maxLength: {
                                        value: 200,
                                        message: t('profileProjects.supportForm.maxLength200'),
                                    },
                                }}
                            />

                            <button
                                type="button"
                                onClick={methods?.handleSubmit(onSubmit)}
                                className={classNames('btn', C.Button)}
                                disabled={!methods.formState.isValid || isLoading}
                            >
                                {t('profileProjects.supportForm.submitButton')}
                            </button>
                        </form>
                    </FormProvider>
                </>
            )}

            {isSuccess && (
                <div className={C.Success}>
                    <div className={C.Success__Wrap}>
                        <CloudIcon className={C.Success__Icon} />
                        <div className={C.Success__Title}>{t('profileProjects.supportForm.successTitle')}</div>
                        <div className={C.Success__Desc}>
                            {t('profileProjects.supportForm.successDesc')}
                        </div>

                        <NavLink
                            to={RoutesLinkEnum.ProfileProjects}
                            className={classNames('btn', C.Success__Button)}
                        >
                            {t('profileProjects.supportForm.nextButton')}
                        </NavLink>
                    </div>
                </div>
            )}
        </div>
    );
};
