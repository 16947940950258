import React, { useCallback, useEffect, useMemo } from 'react';
import { NavLink } from "react-router-dom";
import { Modal } from "@mui/material";
import { useMediaQuery } from "@react-hook/media-query";
import classNames from "classnames";

import C from './AboutMe.module.scss';
import { useAppDispatch, useAppSelector } from "core/box/hooks";
import { RoutesLinkEnum } from "core/box/router";
import { BackdropProps } from "core/box/utils/BackdropProps";
import { MediaQuery } from "core/box/types";
import { useAuthMe } from "core/services/auth.service";

import VerifySvg from 'assets/images/verify/verify.svg';
import DefaultUserIcon from 'assets/images/default-user.svg';
import EditIcon from 'assets/images/edit.svg';

import { EditAvatarForm } from "../EditAvatarForm";
import { setIsOpenAvatarForm } from "../../store/moduleProfile";
import { useTranslation } from "react-i18next";

export const AboutMe = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isOpenAvatarForm = useAppSelector(state => state.moduleProfile.isOpenAvatarForm);
    const authMe = useAppSelector(state => state.moduleProfile.profile);
    const isAuthMe = useAppSelector(state => state.auth.authMe);
    const isMe = useAppSelector(state => state.moduleProfile.isMe);
    const isMobile = useMediaQuery(`only screen and (max-width: ${MediaQuery.SM})`);

    const { about_me, last_name, first_name, profile_photo, email } = useMemo(() => {
        if (authMe?.user_information) {
            return {
                about_me: authMe?.user_information?.about_me?.value,
                first_name: authMe?.user_information?.first_name?.value,
                last_name: authMe?.user_information?.last_name?.value,
                email: authMe?.email,
                profile_photo: authMe?.user_information?.profile_photo?.value ? `${process.env.REACT_APP_URL_BACKEND}${authMe?.user_information?.profile_photo?.value}` : null,
            };
        }

        return {};
    }, [ authMe?.user_information ]);

    const handleOpenAvatarEdit = useCallback(() => {
        dispatch(setIsOpenAvatarForm(true));
    }, [ dispatch ]);

    const handleCloseAvatarEdit = useCallback(() => {
        dispatch(setIsOpenAvatarForm(false));
    }, [ dispatch ]);

    const { refetchAuthMe } = useAuthMe();

    useEffect(() => {
        refetchAuthMe();
    }, [ refetchAuthMe, isOpenAvatarForm ]);

    return (
        <>
            <div className={C.AboutMe}>

                <div className={C.Avatar}>
                    {!!profile_photo?.length ? (
                        <img className={C.Avatar__Img} src={profile_photo} alt="" />
                    ) : (
                        <DefaultUserIcon className={C.DefaultUser} />
                    )}
                    {isMe && (
                        <button className={C.Avatar__Edit} onClick={handleOpenAvatarEdit}>
                            <EditIcon className={C.Avatar__EditIcon} />
                        </button>
                    )}
                </div>

                {isMe && isMobile && (
                    <div className={classNames(C.Verify, {
                        [C.Verify_Confirmed]: isAuthMe?.email_verified_at,
                        [C.Verify_NotConfirmed]: !isAuthMe?.email_verified_at,
                    })}>
                        {isAuthMe?.email_verified_at ? (
                            <>
                                <VerifySvg className={C.Verify__Icon} /> {email}
                            </>
                        ) : (
                            <NavLink className={C.Verify__Error} to={RoutesLinkEnum.Verify}>
                                <VerifySvg className={C.Verify__Icon} /> {email}
                            </NavLink>
                        )}
                    </div>
                )}

                {isMobile && (
                    <div className={C.MobileHead}>
                        <div className={C.Center__Nick}>@{authMe.nick}</div>
                        <NavLink to={RoutesLinkEnum.ProfileEdit} className={C.Edit}>{t("profile.edit")}</NavLink>
                    </div>
                )}

                <div className={C.Content}>
                    <div className={C.Head}>
                        <div className={C.NameWrap}>
                            <h2 className={C.Name}>
                                {authMe?.nick.toLowerCase() === 'deleted' ? t("profile.userDeleted") : (
                                    <>
                                        {!!first_name && !!last_name ? `${first_name} ${last_name}` : t("profile.user")}
                                    </>
                                )}
                            </h2>
                            {(isMe && !isMobile) &&
                            <NavLink to={RoutesLinkEnum.ProfileEdit} className={C.Edit}>{t("profile.edit")}</NavLink>}
                        </div>

                        {isMe && !isMobile && (
                            <div className={classNames(C.Verify, {
                                [C.Verify_Confirmed]: isAuthMe?.email_verified_at,
                                [C.Verify_NotConfirmed]: !isAuthMe?.email_verified_at,
                            })}>
                                {isAuthMe?.email_verified_at ? (
                                    <>
                                        <VerifySvg className={C.Verify__Icon} /> {email}
                                    </>
                                ) : (
                                    <NavLink className={C.Verify__Error} to={RoutesLinkEnum.Verify}>
                                        <VerifySvg className={C.Verify__Icon} /> {email}
                                    </NavLink>
                                )}
                            </div>
                        )}
                    </div>
                    <div className={C.Center}>
                        {(authMe?.nick && !isMobile) && <div className={C.Center__Nick}>@{authMe.nick}</div>}
                        <div className={C.Center__Item}>{t("profile.posts")}: <span>0</span></div>
                        <div className={C.Center__Item}>{t("profile.subscribers")}: <span>0</span></div>
                        <div className={C.Center__Item}>{t("profile.subscriptions")}: <span>0</span></div>
                    </div>
                    <div className={C.Footer}>
                        {t("profile.aboutMe")}: {!!about_me?.length ? about_me : t("profile.noAbout")}
                    </div>
                </div>
            </div>

            {isMe && (
                <Modal open={isOpenAvatarForm} BackdropProps={BackdropProps} onClose={handleCloseAvatarEdit}>
                    <EditAvatarForm onClose={handleCloseAvatarEdit} />
                </Modal>
            )}
        </>
    );
};