import React, { useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { closeResetPassword } from "../../../store/auth";
import C from './Auth.module.scss';
import { TextInput } from "../../atoms/TextInput";
import BackIcon from '../../../../assets/images/back.svg';
import { useResetPassword } from "../../../services/auth.service";
import CheckIcon from '../../../../assets/images/check-notif.svg';
import classNames from "classnames";
import { useAppDispatch } from 'core/box/hooks';
import { useTranslation } from "react-i18next";

type FormValuesResetPassword = {
    email: string;
};

export const ResetPasswordForm = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isLoading, data, error, isSuccess, isError, mutateAsync } = useResetPassword();

    const { ...methods } = useForm<FormValuesResetPassword>({
        mode: 'onBlur',
        defaultValues: {
            email: '',
        }
    });

    useEffect(() => {
        let timeOut: any;

        if (isSuccess) {
            timeOut = setTimeout(() => {
                dispatch(closeResetPassword());
            }, 5000);
        }

        return () => timeOut && clearTimeout(timeOut);
    }, [ dispatch, isSuccess ]);

    const onSubmit: SubmitHandler<FormValuesResetPassword> = useCallback(async (data) => {
        await mutateAsync(data.email);
    }, [ mutateAsync ]);

    const handleClose = useCallback(() => {
        dispatch(closeResetPassword());
    }, [ dispatch ]);

    if (isSuccess) {
        return (
            <div className={C.Auth__Wrap}>
                <div className={C.ResetSuccess}>
                    <CheckIcon className={C.ResetSuccess__Icon} />
                    <div className={C.ResetSuccess__Title}>{t("resetPasswordForm.resetPasswordSuccessTitle")}</div>
                    <div className={C.ResetSuccess__Text}>{t("resetPasswordForm.resetPasswordSuccessText")}</div>
                </div>
            </div>
        );
    }

    return (
        <FormProvider {...methods}>
            <form className={C.Auth__Wrap}>
                <div>
                    <button
                        className={C.Auth__Back}
                        type="button"
                        onClick={handleClose}
                    >
                        <BackIcon className={C.Auth__BackIcon} /> {t("resetPasswordForm.backButton")}
                    </button>
                    <h2 className={C.Auth__Title}>{t("resetPasswordForm.forgotPasswordTitle")}</h2>
                    <p className={C.Auth__Text}>{t("resetPasswordForm.forgotPasswordText")}</p>
                </div>

                <TextInput
                    name="email"
                    label={t("resetPasswordForm.emailInputLabel")}
                    placeholder={t("resetPasswordForm.emailInputPlaceholder")}
                    rules={{
                        required: t("resetPasswordForm.emailInputRequiredError"),
                        pattern: {
                            value: /\b[\w\\.+-]+@[\w\\.-]+\.\w{2,4}\b/,
                            message: t("resetPasswordForm.emailInputPatternError"),
                        },
                    }}
                />

                {isError && (
                    <div className={C.Auth__ErrorText}>{t("resetPasswordForm.errorText")}</div>
                )}

                <button
                    type="submit"
                    onClick={methods?.handleSubmit(onSubmit)}
                    className={classNames(C.Auth__Button, 'btn')}
                    disabled={!methods.formState.isValid || isLoading}
                >
                    {t("resetPasswordForm.submitButton")}
                </button>
            </form>
        </FormProvider>
    );
};
