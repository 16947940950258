import React, { useCallback, useEffect, useRef, useState } from 'react';
import C from './Search.module.scss';
import TopSvgDark from '../../../../assets/images/search/top-search--dark.svg';
import TopSvgLight from '../../../../assets/images/search/top-search--light.svg';
import BotSvgDark from '../../../../assets/images/search/bot-search--light.svg';
import { Icon } from "../../../../core/components/atoms/Icon";
import debounce from "@mui/utils/debounce";
import { useSearch } from "../../services/search.service";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../../core/box/hooks";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

export const Search = () => {
    const { t } = useTranslation();
    const theme = useAppSelector((state) => state?.userInterface?.theme);
    const [ value, setValue ] = useState('');
    const [ searchValue, setSearchValue ] = useState('');
    const [ searchList, setSearchList ] = useState<any[]>([]);
    const [ page, setPage ] = useState(1);

    const { isLoading, isSuccess, isError, data } = useSearch(searchValue, page);

    useEffect(() => {
        if (isSuccess) {
            if (page === 1) {
                setSearchList(data?.data);
            } else {
                setSearchList((searchList: any[]) => [ ...searchList, ...data?.data ]);
            }
        }
    }, [ data?.data, isSuccess, page ]);

    /**
     * Cancel the default form behavior
     */
    const handleSubmit = useCallback((e: React.FormEvent) => {
        e.preventDefault();
    }, []);

    /**
     * Real-time search
     */
    const handleDebounceFn = useCallback((inputValue: string) => {
        setSearchValue(inputValue);
        setPage(1);
        setSearchList([]);
    }, []);

    const debounceFn = useRef(debounce(handleDebounceFn, 1000)).current;

    useEffect(() => {
        return () => {
            debounceFn.clear();
        };
    }, [ debounceFn ]);

    /**
     * Input change
     */
    const handleChangeInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const str = e.target.value.replace(/^\s+|\s+$/g, ' ');
        setValue(str);
        debounceFn(str);
    }, [ debounceFn ]);

    /**
     * Show more
     */
    const handleShowMore = useCallback(() => {
        setPage((page) => page + 1);
    }, []);

    return (
        <div className={C.Search}>
            {theme === "dark" ? (
                <TopSvgDark className={C.Search__Top} width={540} height={64} />
            ) : (
                <TopSvgLight className={C.Search__Top} width={540} height={64} />
            )}

            <form onSubmit={handleSubmit} className={C.Form}>
                <label className={C.Label}>
                    <Icon className={C.Icon} name="search" />
                    <input
                        className={C.Input}
                        type="text"
                        autoFocus
                        placeholder={t('search.placeholder')}
                        value={value}
                        onChange={handleChangeInput}
                    />

                    {isLoading && <div className={C.Loader} />}

                </label>

                {(isSuccess || !!searchList?.length) && (
                    <div className={C.Result}>
                        <h3 className={C.Result__Title}>{t('search.resultsTitle')}</h3>

                        {!searchList?.length && (
                            <div className={C.NoResult}>{t('search.noResults')} <b>{searchValue}</b></div>
                        )}

                        {!!searchList?.length && searchList?.map((item: any) => (
                            <NavLink
                                to={`/project/${item?.category}/${item?.project_id}`}
                                key={item?.project_id}
                                className={C.Result__Item}
                            >
                                {t(`categories.${item?.category}`, item?.category)} "{item?.title}"
                            </NavLink>
                        ))}

                        {(data?.meta?.last_page !== page && !!searchList.length) && (
                            <button className={classNames(C.ShowMore, 'btn')} onClick={handleShowMore}>
                                {t('search.showMore')}
                            </button>
                        )}
                    </div>
                )}
            </form>

            <BotSvgDark className={C.Search__Bot} width={480} height={140} />
        </div>
    );
};
