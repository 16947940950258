import network from "../../api/network";
import { useMutation, useQuery } from "react-query";
import { setAuthMe } from "../store/auth";
import { useAppDispatch } from "core/box/hooks";

export interface ValuesLogIn {
    login: string,
    password: string,
    device_name: 'web'
}

export interface ValuesSingIn {
    nick: string,
    email: string,
    password: string,
    password_confirmation: string,
    apliner?: string,
}

export interface ValuesResetPassword {
    email: string;
}

export const AuthService = {
    async logIn(data: ValuesLogIn) {
        return network.post('/auth/user_login', data);
    },
    async singIn(data: ValuesSingIn) {
        return network.post('/auth/user_register_by_email', data);
    },
    async authMe() {
        return network.get(`/auth/me`, {
            params: {
                with_user_information: 1
            }
        });
    },
    async resetPassword(email: string) {
        return network.post('/auth/password/email', { email });
    },
};

export const useLogIn = () => {
    const { isLoading, isSuccess, isError, data, error, mutateAsync } = useMutation(
        'CORE/LOG_IN',
        (data: ValuesLogIn) => AuthService.logIn(data),
        {
            onSuccess: ({ data }) => {
                if (!!data.token.length) {
                    localStorage.setItem('token', data.token);
                    window.location.reload();
                }
            },
            onError: (error: any) => {
                // console.log('error', error);
                // @ts-ignore
                return error?.response?.data;
            },
        }
    );

    return { isLoading, mutateAsync, isSuccess, isError, data, error };
};

export const useSingIn = () => {
    const {
        isLoading: isLoadingSignIn,
        isSuccess: isSuccessSignIn,
        isError: isErrorSighIn,
        mutateAsync: mutateAsyncSignIn
    } = useMutation(
        'CORE/SIGN_IN',
        (data: ValuesSingIn) => AuthService.singIn(data),
        {
            onSuccess: () => {
                localStorage.setItem('first_log_in', 'true');
            },
            onError: (error) => {
                console.log(error);
            },
        }
    );

    return { isLoadingSignIn, mutateAsyncSignIn, isSuccessSignIn, isErrorSighIn };
};

export const useAuthMe = () => {
    const dispatch = useAppDispatch();
    const token = localStorage.getItem('token');

    const { isLoading: isLoadingAuth, isSuccess, isError, data, refetch: refetchAuthMe } = useQuery(
        'CORE/AUTH_ME',
        () => AuthService.authMe(),
        {
            onSuccess: ({ data }) => {
                dispatch(setAuthMe({ ...data.data }));
            },
            onError: () => {
                dispatch(setAuthMe(null));
            },
            staleTime: Infinity,
            enabled: !!token
        }
    );

    return { isLoadingAuth, isSuccess, isError, data, refetchAuthMe };
};

export const useResetPassword = () => {
    const { isLoading, isSuccess, isError, data, error, mutateAsync } = useMutation(
        'CORE/RESET_PASSWORD',
        (email: string) => AuthService.resetPassword(email),
        {
            onError: (error: any) => {
                // console.log('error', error);
                // @ts-ignore
                return error?.response?.data;
            },
        }
    );

    return { isLoading, mutateAsync, isSuccess, isError, data, error };
};