import React from 'react';
import { useTranslation } from "react-i18next";

export const PrivacyPolicy = () => {
    const { i18n } = useTranslation();

    return (
        <>
            {i18n.language === 'ru' && (
                <>

                    <h1>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h1>

                    <p>Настоящая Политика конфиденциальности («Политика конфиденциальности») распространяется на ваш
                        доступ и
                        использование этого веб-сайта ddapp.io («Веб-сайт»), а также связанного контента, программного
                        обеспечения и мобильных приложений (совместно именуемых «Сервис»).</p>

                    <p>В настоящей Политике конфиденциальности объясняется, когда, почему и как мы собираем и используем
                        личную
                        информацию о людях, которые посещают наш Веб-сайт или пользуются нашими
                        услугами</p>

                    <p>Мы можем время от времени изменять настоящую Политику конфиденциальности. Используя наш Веб-сайт
                        или наши
                        Услуги, вы соглашаетесь соблюдать настоящую Политику конфиденциальности.</p>

                    <h3>Какую информацию мы собираем?</h3>

                    <p>Decimal Dapps собирает следующие данные:</p>

                    <ol>
                        <li>Адреса электронной почты тех, кто общается с нами по электронной почте;</li>
                        <li>Данные о посещении страниц внутри платформы</li>
                        <li>Данные, предоставленная пользователем через формы</li>
                        <li>Данные о переходам по внешним ссылкам</li>
                        <li>Информацию, связанную с использованием Веб-сайта и/или мобильного приложения, включая
                            IP-адрес,
                            идентификатор устройства, географическое положение, а также дату и время запроса;
                        </li>
                        <li>Любую другую информацию, которую вы можете предоставить добровольно. Мы также можем получить
                            ваш
                            адрес
                            электронной почты и идентификатор вашего кошелька, если вы решите принять участие в одной из
                            наших
                            кампаний.
                        </li>
                    </ol>

                    <h3>Как мы используем информацию?</h3>

                    <p>Decimal Dapps использует собранную информацию, Когда это необходимо для целей, отвечающих
                        законным
                        интересам Decimal Dapps, таких как<br />
                        (а) предоставление запрошенной вами информации или контента; <br />
                        (b) чтобы связаться с вами по поводу наших программ, продуктов, функций или услуг; <br />
                        (c) для улучшения наших
                        услуг и для внутренних деловых целей, таких как идентификация и аутентификация или обслуживание
                        клиентов, отслеживание портфолио и синхронизация предпочтений пользователей между
                        устройствами; <br />
                        (d) для
                        обеспечения безопасности нашего Веб-сайта, пытаясь предотвратить несанкционированные или
                        злонамеренные
                        действия; <br />
                        (e) для обеспечения соблюдения наших Условий использования и других политик; <br />
                        (f) помогать
                        другим организациям (например, владельцам авторских прав) в защите их прав;<br />
                        (g) адаптировать контент,
                        рекламу и предложения для вас или для других целей, раскрытых во время сбора.</p>

                    <h3>Когда вы даете нам согласие, например:</h3>

                    <p>(а) когда вы просите нас отправить вам маркетинговую информацию через носитель, где нам нужно
                        ваше
                        согласие, включая оповещения через мобильные push-уведомления;<br />
                        (б) когда вы даете нам согласие на размещение файлов cookie и использование аналогичных
                        технологий;<br />
                        (c) в других случаях, когда мы запрашиваем ваше согласие, для любых других целей, которые могут
                        возникнуть.</p>

                    <p>Мы также можем предоставить доступ к вашей личной информации, когда это требуется по закону, для
                        сотрудничества с полицейскими расследованиями или другими судебными разбирательствами, для
                        защиты от
                        неправомерного или несанкционированного использования нашего Веб-сайта, для ограничения нашей
                        юридической ответственности и защиты наших прав, или для защищать права, имущество или
                        безопасность
                        посетителей Сайта или общественности. В этих случаях информация предоставляется только для этой
                        цели.</p>

                    <h3>В каких случаях мы передаем вашу информацию?</h3>
                    <p>Когда необходимо поделиться информацией в целях расследования, предотвращения или принятия мер в
                        отношении незаконных действий, подозрений на мошенничество, ситуаций, связанных с потенциальными
                        угрозами физической безопасности любого лица, нарушений Условий обслуживания, </p>

                    <p>Мы предоставляем личную информацию доверенным предприятиям или лицам с единственной целью
                        обработки
                        личной информации от нашего имени и предоставления нам услуг. </p>

                    <p>Мы предоставляем такую информацию третьим лицам, которые заключили с нами соглашения о
                        неразглашении.</p>

                    <p>Мы предоставляем такую информацию компании, контролируемой или находящейся под общим контролем
                        Decimal
                        Dapps, для любых целей, разрешенных настоящей Политикой конфиденциальности.</p>

                    <p>Мы можем агрегировать, анонимизировать и публиковать данные только в статистических и
                        исследовательских
                        целях. </p>

                    <p>Например, мы можем собирать и делиться информацией о популярности определенных продуктов,
                        отслеживаемых
                        пользователями. </p>

                    <p>В любом таком случае информацию нельзя будет отследить до какого-либо лица.</p>

                    <h3>Хранение вашей личной информации</h3>

                    <p>Информация, связанная с вашей учетной записью, как правило, будет храниться до тех пор, пока в
                        ней больше
                        не будет необходимости для предоставления Услуг или до тех пор, пока вы не попросите нас удалить
                        ее или
                        ваша учетная запись не будет удалена, в зависимости от того, что наступит раньше. </p>

                    <p>Кроме того, мы можем сохранять информацию из удаленных учетных записей в соответствии с законом,
                        предотвращать мошенничество, разрешать споры, устранять неполадки, помогать в расследованиях,
                        обеспечивать соблюдение Условий использования и предпринимать другие действия, разрешенные
                        законом. </p>

                    <p>Сохраняемая нами информация будет обрабатываться в соответствии с настоящей Политикой
                        конфиденциальности.</p>

                    <h3>Вопросы</h3>

                    <p>Любые вопросы об этой Политике конфиденциальности или об использовании нами вашей личной
                        информации
                        следует направлять в техническую поддержку сервиса.</p>
                </>
            )}

            {i18n.language === "en" && (
                <>
                    <h1>PRIVACY POLICY</h1>

                    <p>This Privacy Policy ("Privacy Policy") applies to your access and use of this website ddapp.io
                        ("Website"), as well as related content, software, and mobile applications (collectively
                        referred to as "Services").</p>

                    <p>This Privacy Policy explains when, why, and how we collect and use personal information from
                        people who visit our Website or use our services.</p>

                    <p>We may change this Privacy Policy from time to time. By using our Website or Services, you agree
                        to comply with this Privacy Policy.</p>

                    <h3>What information do we collect?</h3>

                    <p>Decimal Dapps collects the following data:</p>

                    <ol>
                        <li>Email addresses of those who communicate with us via email;</li>
                        <li>Data about visits to pages within the platform;</li>
                        <li>Data provided by the user through forms;</li>
                        <li>Data about clicks on external links;</li>
                        <li>Information related to the use of the Website and/or mobile application, including IP
                            address, device identifier, geographical location, as well as date and time of the request;
                        </li>
                        <li>Any other information you may voluntarily provide. We may also obtain your email address and
                            wallet identifier if you decide to participate in one of our campaigns.
                        </li>
                    </ol>

                    <h3>How do we use the information?</h3>

                    <p>Decimal Dapps uses the collected information when necessary for purposes that align with Decimal
                        Dapps' legitimate interests, such as:</p>
                    <p>(a) providing the information or content you requested;</p>
                    <p>(b) contacting you regarding our programs, products, features, or services;</p>
                    <p>(c) improving our services and for internal business purposes, such as identification and
                        authentication or customer service, portfolio tracking, and syncing user preferences between
                        devices;</p>
                    <p>(d) ensuring the security of our Website by attempting to prevent unauthorized or malicious
                        actions;</p>
                    <p>(e) ensuring compliance with our Terms of Service and other policies;</p>
                    <p>(f) assisting other organizations (e.g., copyright owners) in protecting their rights;</p>
                    <p>(g) adapting content, advertising, and offers for you or other purposes disclosed during
                        collection.</p>

                    <h3>When you give us consent, for example:</h3>

                    <p>(a) when you ask us to send you marketing information via a medium where we need your consent,
                        including notifications via mobile push notifications;</p>
                    <p>(b) when you consent to us placing cookies and using similar technologies;</p>
                    <p>(c) in other cases when we request your consent, for any other purposes that may arise.</p>

                    <p>We may also provide access to your personal information when required by law, to cooperate with
                        police investigations or other legal proceedings, to protect against unlawful or unauthorized
                        use of our Website, to limit our legal liability and protect our rights, or to protect the
                        rights, property, or safety of Website visitors or the public. In these cases, the information
                        is provided only for that purpose.</p>

                    <h3>When do we share your information?</h3>
                    <p>We share information when necessary for the purposes of investigating, preventing, or taking
                        action regarding illegal activities, suspected fraud, situations related to potential threats to
                        the physical safety of any person, violations of the Terms of Service,</p>

                    <p>We provide personal information to trusted companies or individuals for the sole purpose of
                        processing personal information on our behalf and providing us with services.</p>

                    <p>We provide such information to third parties who have entered into non-disclosure agreements with
                        us.</p>

                    <p>We provide such information to a company controlled or under common control with Decimal Dapps,
                        for any purposes permitted by this Privacy Policy.</p>

                    <p>We may aggregate, anonymize, and publish data solely for statistical and research purposes.</p>

                    <p>For example, we may collect and share information about the popularity of certain products
                        tracked by users.</p>

                    <p>In any such case, the information cannot be traced back to any individual.</p>

                    <h3>Storage of your personal information</h3>

                    <p>Information related to your account is generally stored until it is no longer needed to provide
                        the Services or until you ask us to delete it or your account is deleted, whichever comes
                        first.</p>

                    <p>In addition, we may retain information from deleted accounts in accordance with the law, prevent
                        fraud, resolve disputes, troubleshoot problems, assist in investigations, ensure compliance with
                        the Terms of Service, and take other actions permitted by law.</p>

                    <p>Information stored by us will be processed in accordance with this Privacy Policy.</p>

                    <h3>Questions</h3>

                    <p>Any questions about this Privacy Policy or our use of your personal information should be
                        directed to the technical support of the service.</p>

                </>
            )}
        </>
    );
};