import React from 'react';
import C from './Footer.module.scss';
import tg from 'assets/images/footer/tg.png';
import vk from 'assets/images/footer/vk.png';
import tw from 'assets/images/footer/tw.png';
import dz from 'assets/images/footer/dz.png';
import yt from 'assets/images/footer/yt.png';
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Footer = () => {
    const { t } = useTranslation();

    return (
        <div className={C.Footer}>
            <div className="container">
                <div className={C.Body}>
                    <p className={C.Desc}>{t('footer.footerDesc')}</p>

                    <div className={C.Wrap}>
                        <div className={C.Socials}>
                            <a className={C.Socials__Item} href="https://t.me/ddapps_community " target="_blank"
                               rel="noreferrer"><img src={tg} alt="" /></a>
                            <a className={C.Socials__Item} href="https://vk.com/ddapps_community" target="_blank"
                               rel="noreferrer"><img src={vk} alt="" /></a>
                            <a className={C.Socials__Item} href="https://twitter.com/DecimalDapps" target="_blank"
                               rel="noreferrer"><img src={tw} alt="" /></a>
                            <a className={C.Socials__Item} href="https://dzen.ru/decimaldapps" target="_blank"
                               rel="noreferrer"><img src={dz} alt="" /></a>
                            <a className={C.Socials__Item}
                               href="https://www.youtube.com/channel/UCqyLZhmttOAp7AQsrxKiOnw"
                               target="_blank" rel="noreferrer"><img src={yt} alt="" /></a>
                        </div>

                        <div className={C.Links}>
                            <NavLink className={C.Links__Item}
                                     to={'/terms/terms-of-use'}>{t('footer.termsOfUseLink')}</NavLink>
                            <NavLink className={C.Links__Item}
                                     to={'/terms/privacy-policy'}>{t('footer.privacyPolicyLink')}</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
