import React, { useEffect } from 'react';
import { AboutMe } from "../AboutMe";
import C from './RootPage.module.scss';
import { AsideDashboard } from "../../../../core/components/organisms/AsideDashboard";
import { Tabs } from "../Tabs";
import { useMediaQuery } from "@react-hook/media-query";
import { MediaQuery } from "core/box/types";
import { useAppDispatch, useAppSelector } from "../../../../core/box/hooks";
import { useParams } from "react-router-dom";
import { useUserCompany, useUserProfile } from "../../services/profile.service";
import { setIsMe, setProfile } from "../../store/moduleProfile";
import { ApiLoading } from "../../../../core/components/atoms/ApiLoading";

export const RootPage = () => {
    const isMobile = useMediaQuery(`only screen and (max-width: ${MediaQuery.XL})`);
    const dispatch = useAppDispatch();
    const params = useParams();
    const authMeId = useAppSelector(state => state.auth?.authMe?.id?.toString());
    const authMe = useAppSelector(state => state.auth?.authMe);

    const {
        isLoading,
        isSuccess,
        isError,
        data
    } = useUserProfile((params?.id !== authMeId && params?.id) ? params?.id : authMeId);

    const { isLoadingCompany } = useUserCompany((params?.id !== authMeId && params?.id) ? params?.id : authMeId);

    useEffect(() => {
        if (params?.id === authMeId) {
            dispatch(setProfile(authMe));
            dispatch(setIsMe(true));
        } else {
            dispatch(setIsMe(false));
        }
    }, [ authMe, authMeId, dispatch, params?.id ]);

    return (
        <ApiLoading load={!isLoading && !isLoadingCompany}>
            <div className="container">
                <div className={C.RootPage}>
                    <div className={C.Wrap}>
                        <div className={C.Content}>
                            <AboutMe />
                            <Tabs />
                        </div>
                        {!isMobile && <AsideDashboard />}
                    </div>
                </div>
            </div>
        </ApiLoading>
    );
};