import React, { FC, useCallback } from 'react';
import C from './Panel.module.scss';
import classNames from "classnames";
import { Dialog } from "@mui/material";
import { useAppDispatch, useAppSelector } from "core/box/hooks";
import { closePanel } from "core/store/userInterface";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';
import CloseCircle from 'assets/images/panel/close-circle.svg';
import { PanelSetting } from "core/components/molecules/PanelSetting";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

interface PanelProps {
    className?: string;
}

export const Panel: FC<PanelProps> = ({ className }) => {
    const isPanel = useAppSelector(state => state.userInterface.isPanel);
    const variantPanel = useAppSelector(state => state.userInterface.variantPanel);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleClosePanel = useCallback(() => {
        dispatch(closePanel());
    }, [ dispatch ]);

    return (
        <Dialog
            open={isPanel && variantPanel != null}
            TransitionComponent={Transition}
            onClose={handleClosePanel}
            keepMounted
            className={classNames(C.panel, className)}
        >
            <div className={C.panel__content}>
                <div className={C.panel__head}>
                    <div className={C.panel__title}>{t(`panel.${variantPanel}.title`)}</div>
                    <button className={C.panel__close} type={"button"} onClick={handleClosePanel}>
                        <CloseCircle className={C.panel__closeIcon} />
                    </button>
                </div>

                {variantPanel === "setting" && (
                    <PanelSetting onClosePanel={handleClosePanel} />
                )}
            </div>
        </Dialog>
    );
};