import BigNumber from "bignumber.js";

export const formatNumber = (value: number, shiftedBy: number, decimal: number) => {
    return Number.parseFloat(Number(new BigNumber(value).shiftedBy(-shiftedBy).toString()).toFixed(decimal)).toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 4
    }) || 0;
};

export const formatNumberFee = (value: number) => {
    return value.toString().substring(2, 4).replace(/^0+/, '') || 0;
};

export const formatNumberReserve = (value: number, toFixed = 0) => {
    return Number(Math.ceil(value)).toFixed(toFixed).replace(/\B(?=(?:\d{3})*$)/g, ' ');
};

export const formatFixed = (value: number, toFixed = 0) => {
    return value.toFixed(toFixed);
};