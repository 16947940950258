import React, { useCallback } from 'react';
import C from '../RootPage/RootPage.module.scss';
import classNames from "classnames";
import { useNavigate } from "react-router";
import { useGetMyProject } from "../../services/profileProjects.service";
import { useAppDispatch, useAppSelector } from "../../../../core/box/hooks";
import { Skeleton } from "@mui/material";
import { useMediaQuery } from "@react-hook/media-query";
import { MediaQuery } from "core/box/types";
import { UsePagination } from "../../../../core/components/molecules/UsePagination";
import { setProjectPage } from "../../store/profileProjects";
import { NavLink } from "react-router-dom";
import { Empty } from "../../components/Empty";
import { RoutesLinkEnum } from "../../../../core/box/router";
import { avatarUrl } from "../../../../core/box/utils/avatarUrl";
import { rateConvert } from "../../../../core/box/utils/rateConvert";
import { useTranslation } from "react-i18next";

export const Projects = () => {
    const { t } = useTranslation();
    const page = useAppSelector(state => state.moduleProfileProjects.projectsPage);

    const { data, isLoading } = useGetMyProject(page);

    const navigate = useNavigate();
    const isMobile = useMediaQuery(`only screen and (max-width: ${MediaQuery.XL})`);
    const dispatch = useAppDispatch();

    const listCategories = useAppSelector(state => state.category.list);

    const getCategory = useCallback((category: string) => {
        return listCategories?.find(item => item.name === category)?.title || '-';
    }, [ listCategories ]);

    const handleClickItem = useCallback((category: string, id: string) => {
        navigate(`/project/${category}/${id}`);
    }, [ navigate ]);

    const avatarLink = useCallback((category: string, item: any) => {
        if (category === 'tokens') {
            return item?.data_extended?.coin?.coin?.avatar;
        }

        if (category === 'validators') {
            return item?.data_extended?.list?.identity;
        }

        return item?.logo;
    }, []);

    const onChangePagination = useCallback((event: React.ChangeEvent<unknown>, page: number) => {
        dispatch(setProjectPage(page));
    }, [ dispatch ]);

    return (
        <div className={C.Projects}>
            {!!data?.data?.length && (
                <>
                    <div className={C.Projects__Head}>{t("profileProjects.projects.title")}</div>

                    <div className={'table'}>
                        <div className={classNames('table__grid', C.GridProject)}>
                            <div className={`table__th`}>{t("profileProjects.projects.name")}</div>
                            <div className={`table__th table__center`}>{t("profileProjects.projects.category")}</div>
                            <div className={`table__th table__center`}>{t("profileProjects.projects.rate")}</div>
                            <div className={`table__th table__right`}>{t("profileProjects.projects.trustPoints")}</div>
                        </div>

                        {data?.data?.map((item: any, index: number) => (
                            <button
                                key={item.project_id}
                                className={`${C.GridProject} table__body table__grid`}
                                onClick={() => handleClickItem(item?.category, item?.project_id)}
                            >
                                <div className={`table__td table__td--min`}>
                                    <img className={C.Avatar} src={avatarUrl(avatarLink(item.category, item))} alt="" />
                                    <span className={C.Name}>{item.title}</span>
                                </div>
                                <div
                                    className={`table__td table__td--min table__center`}>{getCategory(item?.category)}</div>
                                <div
                                    className={`table__td table__td--min table__center`}>{rateConvert(item?.rate)}</div>
                                <div
                                    className={`table__td table__td--min table__right`}>{item?.rate}</div>
                            </button>
                        ))}
                    </div>
                </>
            )}

            {isLoading && (
                <div>
                    <Skeleton height={!isMobile ? 36 : 32} />
                    <Skeleton height={!isMobile ? 36 : 32} />
                    <Skeleton height={!isMobile ? 36 : 32} />
                    <Skeleton height={!isMobile ? 36 : 32} />
                </div>
            )}

            {!!data?.data?.length && (
                <div className={C.Footer}>
                    <NavLink className={classNames('btn', C.Btn)} to={RoutesLinkEnum.ProfileSupportProject}>
                        {t("profileProjects.projects.addBtnText")}
                    </NavLink>

                    <UsePagination
                        className={C.Pagination}
                        page={page}
                        onChange={onChangePagination}
                        count={Math.ceil(data?.meta?.total / 3)}
                    />
                </div>
            )}

            {!data?.data?.length && !isLoading && (
                <Empty title={t("profileProjects.projects.noResult")} to={RoutesLinkEnum.ProfileSupportProject} />
            )}

        </div>
    );
};