import network from "../../api/network";
import { useQueries, useQuery } from "react-query";
import { setCategory, setCategoryCount } from "../store/category";
import { useState } from "react";
import { setApiLoaded } from "../store/userInterface";
import { ApiCategoryItem } from "core/box/types";
import { useAppDispatch, useAppSelector } from "core/box/hooks";

export const CategoryService = {
    async getCategories() {
        return network.get('/categories');
    },
    async getCategory(query: string) {
        return network.get('projects', {
            params: {
                category_like: query,
                per_page: 1
            }
        });
    }
};

export const useCategory = (categories: ApiCategoryItem[]) => {
    const dispatch = useAppDispatch();
    const [ count, setCount ] = useState(1);

    return useQueries(
        categories.map((item) => ({
            queryKey: [ 'category', item.name ],
            queryFn: () => CategoryService.getCategory(item.name),
            onSuccess: (data: any) => {
                setCount(count => count + 1);

                dispatch(setCategoryCount({ name: item.name, total: data.data.meta.total }));

                if (count === categories?.length) {
                    dispatch(setApiLoaded({ load: true }));
                }
            },
            enabled: item?.name === 'tokens' || item?.name === 'validators',
            staleTime: Infinity
        }))
    );
};

export const useCategories = () => {
    const categories = useAppSelector(state => state.category.list);

    const dispatch = useAppDispatch();
    const { isLoading: isLoadingCategory, isSuccess, isError, data } = useQuery(
        'CORE/CATEGORIES',
        () => CategoryService.getCategories(),
        {
            onSuccess: ({ data }) => {
                dispatch(setCategory(data.data));
            },
            onError: () => {
                console.log('error');
            },
            enabled: !categories?.length
        }
    );

    return { isLoadingCategory, isSuccess, isError, data };
};