import React from 'react';
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import store from "core/store";
import { AppScreen } from "core/components/organisms/AppScreen";
import { ReactNotifications } from 'react-notifications-component';
import { NotificationCenter } from "core/components/organisms/NotificationCenter";


export const App = () => {
    const queryClient = new QueryClient();

    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <ReactNotifications />
                <NotificationCenter />
                <AppScreen />
            </QueryClientProvider>
        </Provider>
    );
};
