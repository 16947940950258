import React, { FC } from 'react';
import { useOwner } from "../../services/catalog.service";

export interface IOwner {
    target_id: number,
    target_type: string
}

export interface GetProjectOwnerrProps {
    data: IOwner;
}

export const GetProjectOwnerr: FC<GetProjectOwnerrProps> = ({ data }) => {
    const { dataOwner } = useOwner(data);

    return (
        <>
            {dataOwner?.name || '-'}
        </>
    );
};