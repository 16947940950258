import React, { useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { router } from "core/box/router";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAppSelector, useThemeDetector } from "core/box/hooks";
import { Layout } from 'core/components/organisms/Layout';

export const AppScreen = () => {
    const theme = useAppSelector(state => state.userInterface.theme);
    useThemeDetector();

    const themeReplaceMui = useMemo((): "dark" | "light" => {
        if (theme === "dark-premium") return "dark";
        if (theme === "light-premium") return "light";
        if (theme === "light") return "light";
        return "dark";
    }, [ theme ]);

    const themeMui = createTheme({
        palette: {
            mode: themeReplaceMui,
        },
    });

    return (
        <ThemeProvider theme={themeMui}>
            <Router>
                <Routes>
                    {router.map(({ path, Component }) => {
                        return <Route
                            key={path}
                            path={path}
                            element={<Layout component={<Component />} />}
                        />;
                    })}
                </Routes>
            </Router>
        </ThemeProvider>
    );
};