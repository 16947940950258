import React from 'react';
import C from './RootPage.module.scss';
import { BackUrl } from "../../components/BackUrl";
import { GeneralInfoToken } from "../../components/GeneralInfo/GeneralInfoToken";
import { DopInfo } from "../../components/DopInfo";
import { GeneralInfoValidators } from "../../components/GeneralInfo/GeneralInfoValidators";
import { GeneralInfoDefault } from "../../components/GeneralInfo/GeneralInfoDefault";

const RootPageComponent = ({ ...props }: any) => {
    const { category, data } = props;

    return (
        <div className="container container--top-space">
            <BackUrl className={C.Back} categoryName={props?.category} />

            <div className={C.Grid}>
                {category === 'tokens' &&
                <GeneralInfoToken className={C.Card} {...data} />}
                {category === 'validators' && <GeneralInfoValidators className={C.Card} {...data} />}
                {(category !== 'tokens' && category !== 'validators') && (
                    <GeneralInfoDefault className={C.Card} {...data} />
                )}
                <DopInfo className={C.Card} links={props?.links} rate={props?.data?.rate} />
            </div>
        </div>
    );
};

export const RootPage = React.memo(RootPageComponent);