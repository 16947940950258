import React, { FC } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import C from './TitleTable.module.scss';
import { useNavigate } from "react-router";
import { RoutesLinkEnum } from "../../../../core/box/router";

interface TitleTableProps {
    title: string;
}

export const TitleTable: FC<TitleTableProps> = ({ title }) => {
    const navigate = useNavigate();

    return (
        <div className={C.TitleTable}>
            <HighlightOffIcon className={C.Icon} onClick={() => navigate(RoutesLinkEnum.Catalog)} />
            {title}
        </div>
    );
};