import React, { useCallback, useEffect, useState } from 'react';
import C from './Sidebar.module.scss';
import { NavLink } from "react-router-dom";
import { RoutesLinkEnum } from "../../../box/router";
import classNames from "classnames";
import MonitoringSVG from 'assets/images/sidebar/monitoring.svg';
import ProfileSVG from 'assets/images/sidebar/profile.svg';
import ProjectsSVG from 'assets/images/sidebar/projects.svg';
import VerificationSVG from 'assets/images/sidebar/verification.svg';
import ArrowSVG from 'assets/images/sidebar/arrow.svg';
import SupportSvg from 'assets/images/sidebar/support.svg';
import ConstructorSvg from 'assets/images/sidebar/constructor.svg';
import { useMediaQuery } from "@react-hook/media-query";
import { Collapse } from "@mui/material";
import { useAppDispatch, useAppSelector } from 'core/box/hooks';
import { setCloseSidebar } from 'core/store/userInterface';
import { MediaQuery } from 'core/box/types';
import { useTranslation } from "react-i18next";
import { UserCard } from "../../molecules/UserCard";


type TypeMenu = 'lk' | 'vip' | 'setting' | 'services' | '';

export const Sidebar = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const authMe = useAppSelector(state => state?.auth?.authMe);
    const [ openMenu, setOpenMenu ] = useState<TypeMenu>('');
    const isSidebar = useAppSelector(state => state.userInterface.isSidebar);
    const isMobile = useMediaQuery(`only screen and (max-width: ${MediaQuery.XL})`);

    const handleToggleMenu = useCallback((menu: TypeMenu) => {
        if (openMenu === menu) {
            setOpenMenu('');
        } else {
            setOpenMenu(menu);
        }
    }, [ openMenu ]);

    useEffect(() => {
        if (!isMobile) {
            setOpenMenu('');
        }
        return () => setOpenMenu('');
    }, [ isMobile ]);

    const handleCloseSidebar = useCallback(() => {
        dispatch(setCloseSidebar());
    }, [ dispatch ]);

    return (
        <div className={classNames(C.Sidebar, {
            [C.Sidebar_open]: isMobile && isSidebar
        })}>
            {isMobile && (
                <UserCard />
            )}

            <nav className={C.Nav}>
                {isMobile ? (
                    <button className={C.Title} onClick={() => handleToggleMenu('services')}>
                        <span>{t('sidebar.services')}</span> <ArrowSVG className={classNames(C.Title__Icon, {
                        [C.Title__Icon_open]: openMenu === 'services'
                    })} />
                    </button>
                ) : (
                    <div className={C.Title}>{t('sidebar.services')}</div>
                )}

                <Collapse in={true}>
                    <NavLink onClick={handleCloseSidebar}
                             className={({ isActive }) => classNames(C.Link, { [`${C.Link_Active}`]: isActive })}
                             to={RoutesLinkEnum.Catalog}>
                        <MonitoringSVG className={C.Link__Icon} />
                        <span className={C.Link__Text}>{t('sidebar.projectsMonitoring')}</span>
                    </NavLink>

                    <a onClick={handleCloseSidebar}
                       className={C.Link}
                       href={"https://tokenizator.ddapps.io/"}
                       target="_blank"
                       rel="noreferrer"
                    >
                        <ConstructorSvg className={C.Link__Icon} />
                        <span className={C.Link__Text}>{t('sidebar.constructor')}</span>
                    </a>
                </Collapse>

                {isMobile ? (
                    <button className={C.Title} onClick={() => handleToggleMenu('lk')}>
                        <span>{t('sidebar.personalAccount')}</span> <ArrowSVG className={classNames(C.Title__Icon, {
                        [C.Title__Icon_open]: openMenu === 'lk'
                    })} />
                    </button>
                ) : (
                    <div className={C.Title}>{t('sidebar.personalAccount')}</div>
                )}

                <Collapse in={true}>
                    <NavLink
                        onClick={handleCloseSidebar}
                        className={({ isActive }) => classNames(C.Link, {
                            [`${C.Link_Active}`]: isActive,
                        })}
                        to={authMe ? `/profile/${authMe?.id}` : `/profile/1`}
                    >
                        <ProfileSVG className={C.Link__Icon} />
                        <span className={C.Link__Text}>{t('sidebar.yourPage')}</span>
                    </NavLink>

                    <NavLink
                        onClick={handleCloseSidebar}
                        className={({ isActive }) => classNames(C.Link, {
                            [`${C.Link_Active}`]: isActive,
                        })}
                        to={RoutesLinkEnum.ProfileProjects}
                    >
                        <ProjectsSVG className={C.Link__Icon} />
                        <span className={C.Link__Text}>{t('sidebar.applications')}</span>
                    </NavLink>

                    <a
                        href={'https://t.me/Projects_supportbot'}
                        target={"_blank"}
                        onClick={handleCloseSidebar}
                        className={C.Link}
                        rel="noreferrer"
                    >
                        <SupportSvg className={C.Link__Icon} />
                        <span className={C.Link__Text}>{t('sidebar.techSupport')}</span>
                    </a>
                </Collapse>

                {isMobile ? (
                    <button className={C.Title} onClick={() => handleToggleMenu('setting')}>
                        <span>{t('sidebar.accountSecurity')}</span> <ArrowSVG className={classNames(C.Title__Icon, {
                        [C.Title__Icon_open]: openMenu === 'setting'
                    })} />
                    </button>
                ) : (
                    <div className={C.Title}>{t('sidebar.accountSecurity')}</div>
                )}

                <Collapse in={true}>
                    <NavLink
                        onClick={handleCloseSidebar}
                        className={({ isActive }) => classNames(C.Link, {
                            [`${C.Link_Active}`]: isActive,
                        })}
                        to={RoutesLinkEnum.Verify}
                    >
                        <VerificationSVG className={C.Link__Icon} />
                        <span className={C.Link__Text}>{t('sidebar.verification')}</span>
                    </NavLink>
                </Collapse>
            </nav>
        </div>
    );
};