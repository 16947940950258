import React from 'react';
import { DashboardItem } from 'core/components/organisms/DashboardItem';
import { useProjectsRecommend } from 'core/services/project.service';

export const Recommend = () => {
    const { dataRecommend, isLoadingRecommend } = useProjectsRecommend();

    return (
        <DashboardItem
            type={"recommend"}
            list={dataRecommend}
            isLoading={isLoadingRecommend}
        />
    );
};