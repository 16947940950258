import React, { useCallback, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import C from "./Auth.module.scss";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import classNames from "classnames";
import { useLogIn, useSingIn, ValuesSingIn } from 'core/services/auth.service';
import { TextInput } from 'core/components/atoms/TextInput';
import { Checkbox } from 'core/components/atoms/Checkbox';
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

type FormValuesLogIn = {
    status: Boolean;
    nick: string;
    email: string;
    password: string;
    password_confirmation: string;
    apliner: string;
    terms: boolean;
    notification: boolean;
};

export const SignInForm = () => {
    const { t } = useTranslation();
    const { isLoadingSignIn, isErrorSighIn, mutateAsyncSignIn } = useSingIn();
    const { mutateAsync } = useLogIn();
    const [ viewPass, setViewPass ] = useState<boolean>(false);

    const handleClickEndIcon = useCallback(() => {
        setViewPass(value => !value);
    }, []);

    const { ...methods } = useForm<FormValuesLogIn>({
        mode: 'onBlur',
        defaultValues: {
            nick: '',
            email: '',
            password: '',
            password_confirmation: '',
            apliner: '',
            terms: true,
            notification: false
        }
    });

    const onSubmit: SubmitHandler<FormValuesLogIn> = useCallback(async (data) => {
        const dataValues: ValuesSingIn = {
            nick: data?.nick,
            email: data?.email,
            password: data?.password,
            password_confirmation: data?.password_confirmation,
        };

        if (!!data?.apliner?.length) {
            dataValues.apliner = data?.apliner;
        }

        await mutateAsyncSignIn(dataValues).then(res => {
            if (res.status === 200) {
                mutateAsync({
                    login: data?.email,
                    password: data?.password,
                    device_name: 'web'
                });
            }
        });

    }, [ mutateAsync, mutateAsyncSignIn ]);

    return (
        <FormProvider {...methods}>
            <form className={C.Auth__Wrap}>
                <TextInput
                    name="email"
                    label={t("signInForm.emailInputLabel")}
                    placeholder={t("signInForm.emailInputPlaceholder")}
                    rules={{
                        required: t("signInForm.emailInputRequiredError"),
                        pattern: {
                            value: /\b[\w\\.+-]+@[\w\\.-]+\.\w{2,4}\b/,
                            message: t("signInForm.emailInputPatternError"),
                        },
                    }}
                />

                <TextInput
                    name="password"
                    label={t("signInForm.passwordInputLabel")}
                    type={!viewPass ? 'password' : 'text'}
                    placeholder={t("signInForm.passwordInputPlaceholder")}
                    endIcon={viewPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    onClickEndIcon={handleClickEndIcon}
                    rules={{
                        required: t("signInForm.passwordInputRequiredError"),
                        minLength: {
                            value: 8,
                            message: t("signInForm.passwordInputMinLengthError")
                        },
                    }}
                />

                <TextInput
                    name="password_confirmation"
                    label={t("signInForm.passwordConfirmationInputLabel")}
                    type="password"
                    placeholder={t("signInForm.passwordConfirmationInputPlaceholder")}
                    rules={{
                        required: t("signInForm.passwordConfirmationInputRequiredError"),
                        validate: (val: string) => {
                            if (methods.watch('password') !== val) {
                                return t("signInForm.passwordConfirmationInputMatchError");
                            }
                        },
                    }}
                />

                <TextInput
                    name="nick"
                    label={t("signInForm.nickInputLabel")}
                    placeholder={t("signInForm.nickInputPlaceholder")}
                    rules={{
                        required: t("signInForm.nickInputRequiredError"),
                    }}
                />

                <TextInput
                    name="apliner"
                    label={t("signInForm.aplinerInputLabel")}
                    placeholder={t("signInForm.aplinerInputPlaceholder")}
                    rules={{
                        pattern: {
                            value: /\b[\w\\.+-]+@[\w\\.-]+\.\w{2,4}\b/,
                            message: t("signInForm.aplinerInputPatternError"),
                        },
                    }}
                />

                <Checkbox
                    name="terms"
                    label={<>
                        {t("signInForm.termsCheckboxLabel")}
                        {' '}
                        <NavLink to={'/terms/terms-of-use'}>{t("signInForm.termsCheckboxLabelLink")}</NavLink>
                    </>}
                    rules={{
                        required: t("signInForm.termsCheckboxRequiredError")
                    }}
                />

                <Checkbox
                    name="notification"
                    label={t("signInForm.notificationCheckboxLabel")}
                />

                {isErrorSighIn &&
                <div className={C.Auth__Error}>{t("signInForm.signInErrorText")}</div>}

                <button
                    type="submit"
                    onClick={methods?.handleSubmit(onSubmit)}
                    className={classNames(C.Auth__Button, 'btn')}
                    disabled={!methods.formState.isValid || isLoadingSignIn}
                >
                    {t("signInForm.signInButton")}
                </button>

            </form>
        </FormProvider>
    );
};
