import React, { FC, useMemo } from 'react';
import { useAppSelector } from "../../../../core/box/hooks";
import { NavLink } from "react-router-dom";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import C from './BackUrl.module.scss';
import { useTranslation } from "react-i18next";

interface BackUrlProps {
    categoryName: string;
    className?: string;
}

export const BackUrl: FC<BackUrlProps> = ({ categoryName, className }) => {
    const { t } = useTranslation();
    const categories = useAppSelector(state => state.category.list);
    const category = useMemo(() => (categories.filter(item => item?.name === categoryName)[0]), [ categories, categoryName ]);

    return (
        <NavLink to={`/catalog/${category?.name}`} className={`${C.BackUrl} ${!!className?.length ? className : ''}`}>
            <ArrowCircleLeftIcon className={C.BackUrl__Icon} /> {t('project.back')} “{category?.title}”
        </NavLink>
    );
};