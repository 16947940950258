import React, { FC, useCallback, useEffect } from 'react';
import C from './Layout.module.scss';
import { Modal } from "@mui/material";
import classNames from "classnames";
import { useMediaQuery } from "@react-hook/media-query";
import { useAppDispatch, useAppSelector } from 'core/box/hooks';
import { useCategories } from 'core/services/category.service';
import { useRates } from 'core/services/rate.service';
import { useAuthMe } from 'core/services/auth.service';
import { useDelCourse, useProCourse } from 'core/services/course.service';
import { useNavigate } from "react-router";
import { ApiLoading } from 'core/components/atoms/ApiLoading';
import { Header } from 'core/components/organisms/Header';
import { Footer } from 'core/components/organisms/Footer';
import { Sidebar } from 'core/components/organisms/Sidebar';
import { Auth } from "core/components/organisms/Auth";
import { BackdropProps } from 'core/box/utils/BackdropProps';
import { RoutesLinkEnum } from 'core/box/router';
import { MediaQuery } from 'core/box/types';
import { closeAuth } from 'core/store/auth';
import { Panel } from "../Panel";
import { useGetPlan } from "../../../services/userInfo.service";

interface LayoutProps {
    component: React.ReactNode | React.ReactNode[];
}


export const Layout: FC<LayoutProps> = ({ component }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isOpenAuth = useAppSelector(state => state.auth.isOpenAuth);
    const authMe = useAppSelector(state => state.auth.authMe);
    const isMobile = useMediaQuery(`only screen and (max-width: ${MediaQuery.XL})`);

    const { isLoadingCategory } = useCategories();
    const { isLoadingRates } = useRates();
    const { isLoadingAuth } = useAuthMe();
    useProCourse();
    useDelCourse();
    useGetPlan();

    const handleCloseAuth = useCallback(() => {
        dispatch(closeAuth());
    }, [ dispatch ]);

    useEffect(() => {
        if (localStorage.getItem('first_log_in') && authMe) {
            navigate(RoutesLinkEnum.Verify);
        }
    }, [ authMe, navigate ]);

    return (
        <div className={classNames(`${C.Layout}`, {
            [`${C.Layout_Aside}`]: !isMobile
        })}>
            <ApiLoading load={!isLoadingCategory && !isLoadingRates && !isLoadingAuth}>
                <Header />

                <Sidebar />

                <main className={C.Layout__Main}>
                    {component}
                </main>
                <Footer />
                {!authMe && (
                    <Modal
                        BackdropProps={BackdropProps}
                        open={isOpenAuth}
                        onClose={handleCloseAuth}
                    >
                        <Auth onClose={handleCloseAuth} />
                    </Modal>
                )}

                <Panel />
            </ApiLoading>
        </div>
    );
};