import React, { useCallback, useMemo, useState } from 'react';
import C from './Header.module.scss';
import { NavLink } from "react-router-dom";
import SearchIcon from 'assets/images/header/search.svg';
import SettingIcon from 'assets/images/header/setting.svg';
import MenuIcon from 'assets/images/header/menu.svg';
import StatIcon from 'assets/images/header/stat.svg';
import CloseIcon from 'assets/images/close-2.svg';
import NetworkSvg from 'assets/images/header/network.svg';

import classNames from "classnames";
import { useMediaQuery } from "@react-hook/media-query";
import LogoText from "assets/images/logoText.svg";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from 'core/box/hooks';
import { useCategory } from 'core/services/category.service';
import { openPanel, setCloseSidebar, setOpenSidebar, setVariantPanel } from 'core/store/userInterface';
import { openAuth } from 'core/store/auth';
import { Logo } from 'core/components/molecules/Logo';
import { RoutesLinkEnum } from 'core/box/router';
import { MediaQuery } from 'core/box/types';
import { Button } from 'core/components/atoms/Button';

export const Header = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const authMe = useAppSelector(state => state.auth.authMe);
    const categories = useAppSelector(state => state.category.list);
    const isSideBar = useAppSelector(state => state.userInterface.isSidebar);
    const course = useAppSelector(state => state.course);
    const theme = useAppSelector(state => state.userInterface.theme);
    const isMobile = useMediaQuery(`only screen and (max-width: ${MediaQuery.XL})`);
    const [ isStats, setIsStats ] = useState(false);

    useCategory(categories);

    const tokens = useMemo(() => categories?.filter(item => item.name === "tokens")[0], [ categories ]);
    const validators = useMemo(() => categories?.filter(item => item.name === "validators")[0], [ categories ]);

    const handleOpenAuth = useCallback(() => {
        dispatch(openAuth());
    }, [ dispatch ]);

    const handleToggleSideBar = useCallback(() => {
        if (isSideBar) {
            dispatch(setCloseSidebar());
        } else {
            dispatch(setOpenSidebar());
        }
    }, [ dispatch, isSideBar ]);

    const handleToggleStat = useCallback(() => {
        setIsStats((isStats) => !isStats);
    }, []);

    const handleOpenSetting = useCallback(() => {
        dispatch(openPanel());
        dispatch(setVariantPanel('setting'));
    }, [ dispatch ]);

    const handleCloseSidebar = useCallback(() => {
        dispatch(setCloseSidebar());
    }, [ dispatch ]);

    return (
        <>
            <div className={classNames(C.Header, {
                [`${C.Header_Aside}`]: !isMobile
            })}>
                <div className={C.Header__container}>
                    <div className={C.Header__wrap}>

                        <NavLink className={C.Header__logo} to="/">
                            <Logo />
                            <LogoText className={C.Header__logoText} />
                        </NavLink>

                        {!isMobile && (
                            <div className={C.Header__items}>
                                <div className={C.Header__item}>
                                    {t("header.network")}: Main Net Decimal <NetworkSvg
                                    className={C.Header__itemNetwork} />
                                </div>
                                <div className={C.Header__item}>
                                    1 PRO = {course?.pro} DEL
                                </div>
                                <div className={C.Header__item}>
                                    1 DEL = {course?.del} DUSD
                                </div>
                                <div className={C.Header__item}>
                                    {t(`categories.tokens`)}: {tokens?.total}
                                </div>
                                <div className={C.Header__item}>
                                    {t(`categories.validators`)}: {validators?.total}
                                </div>
                            </div>
                        )}

                        <div className={C.Header__buttons}>
                            <div className={C.Header__iconButtons}>
                                <NavLink to={RoutesLinkEnum.Search} className={C.Header__iconButton}>
                                    <SearchIcon className={classNames(C.Header__iconButtonIcon, {
                                        [C.Header__iconButtonIcon_Light]: theme === "light-premium"
                                    })}
                                                onClick={handleCloseSidebar}
                                    />
                                </NavLink>
                                {!isMobile && (
                                    <button className={C.Header__iconButton} onClick={handleOpenSetting}>
                                        <SettingIcon className={classNames(C.Header__iconButtonIcon, {
                                            [C.Header__iconButtonIcon_Light]: theme === "light-premium"
                                        })} />
                                    </button>
                                )}

                                {isMobile && (
                                    <button className={C.Header__iconButton} onClick={handleToggleSideBar}>
                                        {!isSideBar
                                            ? <MenuIcon className={classNames(C.Header__iconButtonIcon, {
                                                [C.Header__iconButtonIcon_Light]: theme === "light-premium"
                                            })} />
                                            : <CloseIcon
                                                className={classNames(C.Header__iconButtonIcon, C.Header__iconButtonIcon_Close, {
                                                    [C.Header__iconButtonIcon_Light]: theme === "light-premium"
                                                })} />}

                                    </button>
                                )}
                            </div>

                            {(!isMobile && !authMe) && (
                                <Button className={classNames({
                                    [C.Header__btnAuth]: theme === "light-premium"
                                })} onClick={handleOpenAuth}>
                                    {t("header.logIn")}
                                </Button>
                            )}
                        </div>
                    </div>

                    {isMobile && (
                        <div className={C.Header__mobile}>
                            <div className={C.Header__mobileNetwork}>{t("header.network")}: Main Net Decimal <NetworkSvg
                                className={C.Header__mobileNetworkIcon} /></div>
                            <div className={classNames(C.Header__stats, {
                                [C.Header__stats_active]: isStats
                            })}>
                                <button className={C.Header__statsBtn} onClick={handleToggleStat}>
                                    <StatIcon className={C.Header__statsBtnIcon} />
                                    <span>{t("header.statistics")}</span>
                                </button>

                                <div className={C.Header__items}>
                                    <div className={C.Header__item}>
                                        1 PRO = {course?.pro} DEL
                                    </div>
                                    <div className={C.Header__item}>
                                        1 DEL = {course?.del} USDT
                                    </div>
                                    <div className={C.Header__item}>
                                        {t(`categories.tokens`)}: {tokens?.total}
                                    </div>
                                    <div className={C.Header__item}>
                                        {t(`categories.validators`)}: {validators?.total}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};