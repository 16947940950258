import React, { FC } from 'react';

interface IconProps {
    className?: string,
    name: string,
    title?: string,
}

export const Icon: FC<IconProps> = ({ className, name, title, ...props }) => {
    return (
        <span
            className={`font-icons font-icons--${name} ${className?.length ? className : ''}`}
            title={title?.length ? title : 'icon'}
            {...props}
        >
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
            <span className="path4" />
            <span className="path5" />
            <span className="path6" />
            <span className="path7" />
            <span className="path8" />
            <span className="path9" />
            <span className="path10" />
        </span>
    );
};