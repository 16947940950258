import network from "../../../api/network";
import { useMutation, useQuery } from "react-query";

export interface ValuesVerifyCode {
    email: string,
    code: string,
}

export const VerifyService = {
    async emailVerifyCodeToEmail(email: string) {
        return network.post(`/auth/email/resend`, {
            email
        });
    },
    async verifyEmail(data: ValuesVerifyCode) {
        return network.post(`/auth/email/verify/${data?.email}`, {
            code: data?.code
        });
    },
};

export const useVerifyCodeToEmail = (email: string, isVerify: boolean) => {
    const { isLoading, isSuccess, isError, data } = useQuery(
        'VERIFY/TO_EMAIL' + email,
        () => {
            return VerifyService.emailVerifyCodeToEmail(email);
        },
        {
            select: ({ data }): any => data,
            onError: () => {
                console.log('error');
            },
            staleTime: Infinity,
            enabled: !!email?.length && isVerify
        }
    );

    return { isLoading, isSuccess, isError, data };
};

export const useVerifyEmail = () => {
    const { isLoading, isSuccess, isError, mutateAsync, data } = useMutation(
        'VERIFY/VERIFY_EMAIL',
        (data: ValuesVerifyCode) => VerifyService.verifyEmail(data),
        {
            onSuccess: (data) => {
                console.log('success', data);
            },
            onError: (error) => {
                console.log(error);
            },
        }
    );

    return { isLoading, mutateAsync, data, isSuccess, isError };
};