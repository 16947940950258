import React, { useCallback } from 'react';
import C from "./BackUrl.module.scss";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export const BackUrl = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleGoBack = useCallback(() => {
        navigate(-1);
    }, [ navigate ]);

    return (
        <button className={`${C.BackUrl}`} onClick={handleGoBack}>
            <ArrowCircleLeftIcon className={C.BackUrl__Icon} /> {t("search.back")}
        </button>
    );
};