import React from 'react';
import C from './SkeletonCatalog.module.scss';
import { Skeleton } from "@mui/material";
import { useMediaQuery } from "@react-hook/media-query";
import { MediaQuery } from 'core/box/types';

export const SkeletonCatalog = () => {
    const isMobile = useMediaQuery(`only screen and (max-width: ${MediaQuery.XL})`);

    return (
        <div className={C.SkeletonCatalog}>
            <Skeleton height={!isMobile ? 36 : 32} />
            <Skeleton height={!isMobile ? 68 : 50} />
            <Skeleton height={!isMobile ? 68 : 50} />
            <Skeleton height={!isMobile ? 68 : 50} />
            <Skeleton height={!isMobile ? 68 : 50} />
            <Skeleton height={!isMobile ? 68 : 50} />
            <Skeleton height={!isMobile ? 68 : 50} />
            <Skeleton height={!isMobile ? 68 : 50} />
            <Skeleton height={!isMobile ? 68 : 50} />
            <Skeleton height={!isMobile ? 68 : 50} />
            <Skeleton height={!isMobile ? 68 : 50} />
        </div>
    );
};