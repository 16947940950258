import React from 'react';
import C from './RootPage.module.scss';
import { AsideDashboard } from "../../../../core/components/organisms/AsideDashboard";
import { Projects } from "../Projects";
import { Teams } from "../Teams";
import { Support } from "../Support";
import { useLocation } from "react-router-dom";
import { RoutesLinkEnum } from "../../../../core/box/router";
import { SupportFormProject } from "../SupportForm/SupportFormProject";
import { SupportFormCompany } from "../SupportForm/SupportFormCompany";
import { useMediaQuery } from "@react-hook/media-query";
import { MediaQuery } from "core/box/types";

export const RootPage = () => {
    const pathName = useLocation();
    const isMobile = useMediaQuery(`only screen and (max-width: ${MediaQuery.XL})`);

    return (
        <div className="container">
            <div className={C.RootPage}>
                <div className={C.Wrap}>
                    <div className={C.Content}>
                        {pathName.pathname !== RoutesLinkEnum.ProfileSupportProject && pathName.pathname !== RoutesLinkEnum.ProfileSupportCompany && (
                            <>
                                <Support />
                                <Projects />
                                <Teams />
                            </>
                        )}

                        {pathName.pathname === RoutesLinkEnum.ProfileSupportProject && (
                            <SupportFormProject />
                        )}

                        {pathName.pathname === RoutesLinkEnum.ProfileSupportCompany && (
                            <SupportFormCompany />
                        )}
                    </div>
                    {!isMobile && <AsideDashboard />}
                </div>
            </div>
        </div>
    );
};