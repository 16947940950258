import React, { useCallback, useMemo } from 'react';
import C from "../GeneralInfo/GeneralInfo.module.scss";
import { Icon } from "../../../../core/components/atoms/Icon";
import { rateConvert } from "../../../../core/box/utils/rateConvert";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../core/box/hooks";
import { Button } from "../../../../core/components/atoms/Button";
import { openAuth } from "../../../../core/store/auth";

const DopInfoComponent = ({ links, className, rate, ...props }: any) => {
    const { t } = useTranslation();
    const isAuth = useAppSelector(state => state?.auth?.authMe);
    const dispatch = useAppDispatch();
    const rateName = useMemo(() => rateConvert(rate), [ rate ]);

    const contacts = useMemo(() => (
        links
            ?.filter((item: any) => item?.type === 0)
            ?.filter((item: any) => !!item?.url?.length)
    ), [ links ]);
    const dock = useMemo(() => (
        links
            ?.filter((item: any) => item?.type === 2)
            ?.filter((item: any) => !!item?.url?.length)
    ), [ links ]);
    const communication = useMemo(() => (
        links
            ?.filter((item: any) => item?.type === 1)
            ?.filter((item: any) => !!item?.url?.length)
    ), [ links ]);

    const handleOpenAuth = useCallback(() => {
        dispatch(openAuth());
    }, [ dispatch ]);

    return (
        <div className={`${!!className?.length ? className : ''}`}>
            <div className={C.Head}>
                <div className={C.Head__Left}>
                    <Icon className={`${C.Head__Logo} ${C.Head__Logo_Icon}`} name="info" />

                    <div className={C.Head__Content}>
                        <h3 className={C.Head__Title}>{t('project.dopInfo.additionalInfo')}</h3>
                        <p className={C.Head__Desc}>{t('project.dopInfo.additionalInfoDesc')}</p>
                    </div>
                </div>
            </div>

            {isAuth ? (
                <div className={C.Grid}>
                    <div className={C.Col}>
                        <h3 className={C.Title}>{t('project.dopInfo.contactDetails')}</h3>
                        {!!contacts?.length ? contacts?.map((item: any) => (
                            <p key={item?.title}>
                                {item?.title}: <a
                                className={C.Link}
                                target="_blank"
                                href={item?.url}
                                rel="noreferrer">{!!item?.url?.length ? item?.url : '-'}</a>
                            </p>
                        )) : t('project.dopInfo.noData')}
                    </div>

                    <div className={C.Col}>
                        <h3 className={C.Title}>{t('project.dopInfo.documents')}</h3>
                        {!!dock?.length ? dock?.map((item: any) => (
                            <p key={item?.title}>
                                <a className={C.Link} target="_blank" href={item?.url}
                                   rel="noreferrer">{item?.title}</a>
                            </p>
                        )) : t('project.dopInfo.noData')}
                    </div>

                    <div className={classNames(C.Col, C.Col__Full)}>
                        <h3 className={C.Title}>{t('project.dopInfo.communicationChannels')}</h3>
                        {!!communication?.length ? communication?.map((item: any) => (
                            <p key={item?.title}>
                                {item?.title}: <a
                                className={C.Link}
                                target="_blank"
                                href={item?.url}
                                rel="noreferrer">{item?.url}</a>
                            </p>
                        )) : t('project.dopInfo.noData')}
                    </div>
                </div>
            ) : (
                <div className={C.NoRate}>
                    <div className={C.NoRate__Title}>{t('project.dopInfo.projectNotAuth.title')}</div>
                    <div className={C.NoRate__Desc}>
                        {t('project.dopInfo.projectNotAuth.description')}
                    </div>
                    <Button
                        className={C.NoRate__Button}
                        mode={"green"}
                        onClick={handleOpenAuth}
                    >
                        {t('project.dopInfo.projectNotAuth.logIn')}
                    </Button>
                </div>
            )}


        </div>
    );
};

export const DopInfo = React.memo(DopInfoComponent);