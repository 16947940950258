import React, { useMemo } from 'react';
import C from '../GeneralInfo.module.scss';
import { formatFixed, formatNumber, formatNumberReserve } from "core/box/utils/formatNumber";
import { textEllipsisCenter } from "core/box/utils/textEllipsisCenter";
import { formatDate } from "core/box/utils/formatDate";
import { avatarUrl } from "core/box/utils/avatarUrl";
import { LikesFavorites } from "../../LikesFavorites";
import { useTranslation } from "react-i18next";

const GeneralInfoTokenComponent = ({ className, ...props }: any) => {
    const { t } = useTranslation();

    const symbol = useMemo(() => props?.data_extended?.coin?.coin?.symbol.toUpperCase(), [ props?.data_extended?.coin?.coin?.symbol ]);
    const coin = useMemo(() => props?.data_extended?.coin?.coin, [ props?.data_extended?.coin?.coin ]);
    const current = useMemo(() => props?.data_extended?.coin?.current, [ props?.data_extended?.coin ]);
    const initial = useMemo(() => props?.data_extended?.coin?.initial, [ props?.data_extended?.coin?.initial ]);

    return (
        <div className={`${!!className?.length ? className : ''}`}>
            <div className={C.Head}>
                <div className={C.Head__Left}>
                    <img className={C.Head__Logo} src={avatarUrl(coin?.avatar)} alt={symbol} />

                    <div className={C.Head__Content}>
                        <h1 className={C.Head__Title}>"{symbol}"</h1>
                        {!!props?.description?.length && <div className={C.Head__Desc}>{props?.description}</div>}
                    </div>
                </div>
                <LikesFavorites {...props} />
            </div>

            <div className={C.Grid}>
                <div className={C.Col}>
                    <h3 className={C.Title}>{t("project.generalInfoToken.informationCreation")}</h3>

                    {(!!coin?.created?.length || !!coin?.creator?.length) ? (
                        <>
                            <p><b>{t("project.generalInfoToken.timestamp")}:</b> {formatDate(coin?.created)}</p>
                            <p>
                                <b>{t("project.generalInfoToken.creator")}:</b> <a
                                className={C.Link}
                                href={`https://explorer.decimalchain.ru/address/${coin?.creator}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {textEllipsisCenter(coin?.creator, 2, 3)}
                            </a>
                            </p>
                        </>
                    ) : t("project.generalInfoToken.noData")}
                </div>

                <div className={C.Col}>
                    <h3 className={C.Title}>{t("project.generalInfoToken.aboutCoin")}</h3>

                    <p>
                        <b>{t("project.generalInfoToken.usage")}:</b> {props?.additional_info || t("project.generalInfoToken.noData")}
                    </p>
                </div>

                <div className={C.Col}>
                    <h3>{t("project.generalInfoToken.tokenomics")}</h3>
                    <p>
                        <b>{t("project.generalInfoToken.firstEmission")}:</b> {formatNumberReserve(initial?.volume)}
                    </p>
                    <p>
                        <b>{t("project.generalInfoToken.totalEmission")}:</b> {formatNumberReserve(current?.volume)}
                    </p>
                    <p>
                        <b>{t("project.generalInfoToken.finalEmission")}:</b> {formatNumberReserve(coin?.limitVolume)}
                    </p>
                    <p>
                        <b>{t("project.generalInfoToken.rotationalReserve")}:</b> {coin?.crr}%
                    </p>
                    <p>
                        <b>{t("project.generalInfoToken.burned")}:</b> {formatNumber(coin?.burn, 15, 0)}
                    </p>
                    <p>
                        <b>{t("project.generalInfoToken.delegated")}:</b> {formatFixed((current?.coinDelegated / current?.volume * 100), 2)}%
                    </p>
                </div>

                <div className={C.Col}>
                    <h3>{t("project.generalInfoToken.marketData")}</h3>
                    <p>
                        <b>{t("project.generalInfoToken.priceCreation")}:</b> {formatFixed(Number(initial?.price), 4)} DEL
                    </p>
                    <p><b>{t("project.generalInfoToken.priceNow")}:</b> {formatFixed(Number(current?.price), 4)} DEL</p>
                    <p>
                        <b>{t("project.generalInfoToken.delegationPrice")}:</b> {formatFixed(Number(current?.delegationPrice), 4)} DEL
                    </p>
                    <p><b>{t("project.generalInfoToken.totalReserve")}:</b> {formatFixed(Number(coin?.reserve), 4)} DEL
                    </p>
                </div>
            </div>
        </div>
    );
};

export const GeneralInfoToken = React.memo(GeneralInfoTokenComponent);
