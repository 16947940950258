import React, { FC } from 'react';
import classNames from "classnames";
import C from './Button.module.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    mode?: string;
}

export const Button: FC<ButtonProps> = ({
    className,
    children,
    mode,
    ...props
}) => {
    return (
        <button className={classNames(className, C.button, {
            [C[`button_${mode}`]]: mode
        })} {...props}>
            {children}
        </button>
    );
};