import { createSlice } from '@reduxjs/toolkit';

interface authState {
    isOpenAuth: boolean,
    isLogInModal: boolean,
    isResetPassword: boolean,
    authMe: any | null
}

const initialState: authState = {
    isOpenAuth: false,
    isLogInModal: true,
    isResetPassword: false,
    authMe: null
};

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        openAuth(state) {
            state.isOpenAuth = true;
        },
        closeAuth(state) {
            state.isOpenAuth = false;
        },
        setLogIn(state) {
            state.isLogInModal = true;
        },
        setSignIn(state) {
            state.isLogInModal = false;
        },
        setAuthMe(state, action) {
            state.authMe = action.payload;
        },
        openResetPassword(state) {
            state.isResetPassword = true;
        },
        closeResetPassword(state) {
            state.isResetPassword = false;
        },
        logOut(state) {
            state.authMe = null;
        }
    },
});

export const {
    openAuth,
    closeAuth,
    setLogIn,
    setSignIn,
    setAuthMe,
    logOut,
    openResetPassword,
    closeResetPassword
} = auth.actions;
export default auth.reducer;