import React, { FC } from 'react';
import C from './Auth.module.scss';
import CloseIcon from 'assets/images/close.svg';
import { Tabs } from "./Tabs";
import { LogInForm } from "./LogInForm";
import { SignInForm } from "./SignInForm";
import { ResetPasswordForm } from "./ResetPasswordForm";
import { useAppSelector } from 'core/box/hooks';

interface AuthProps {
    onClose: () => void;
}

export const Auth: FC<AuthProps> = React.forwardRef(({ onClose }, ref) => {
    const isLogInModal = useAppSelector(state => state.auth.isLogInModal);
    const isResetPassword = useAppSelector(state => state.auth.isResetPassword);

    return (
        <div className={C.Auth}>
            <div className={C.Auth__Body}>
                <CloseIcon onClick={onClose} className={C.Auth__Close} />
                <div className={C.Auth__Content}>
                    <Tabs />
                    {isLogInModal && (
                        <>
                            {!isResetPassword ? <LogInForm /> : <ResetPasswordForm />}
                        </>
                    )}
                    {!isLogInModal && <SignInForm />}
                </div>
            </div>
        </div>
    );
});