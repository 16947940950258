import React, { FC, useCallback } from 'react';
import C from './PanelSetting.module.scss';
import classNames from "classnames";
import { PanelBanner } from 'core/components/atoms/PanelBanner';
import { useTranslation } from "react-i18next";
import { Select, SelectChangeEvent } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { languages, themes } from "core/box/constants";
import ArrowIcon from 'assets/images/panel/arrow.svg';
import { useAppDispatch, useAppSelector } from "core/box/hooks";
import { setTheme } from "core/store/userInterface";
import { localStorageRemoveLogout } from "../../../box/utils/localStorageRemoveLogout";
import { closeAuth, logOut } from "../../../store/auth";
import { Button } from "../../atoms/Button";

interface PanelSettingProps {
    className?: string;
    onClosePanel?: () => void;
}

interface PanelItemProps {
    name: string;
    desc: string;
    children: React.ReactNode | React.ReactNode[];
}

const PanelItem: FC<PanelItemProps> = ({ name, desc, children }) => (
    <div className={C.panelSetting__item}>
        <div className={C.panelSetting__itemLabel}>
            <div className={C.panelSetting__itemName}>{name}</div>
            <div className={C.panelSetting__itemDesc}>{desc}</div>
        </div>
        <div className={C.panelSetting__itemSelect}>
            {children}
        </div>
    </div>
);

export const PanelSetting: FC<PanelSettingProps> = ({ className, onClosePanel }) => {
    const theme = useAppSelector(state => state.userInterface.theme);
    const authMe = useAppSelector(state => state.auth.authMe);
    const myFeatures = useAppSelector(state => state.userInfo.myFeatures);
    const isPermitPremiumTheme = !!myFeatures?.find(item => item.tag === "premium_theme");
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();

    const handleChangeLang = useCallback((e: SelectChangeEvent) => {
        i18n.changeLanguage(e.target.value);
    }, [ i18n ]);

    const handleChangeTheme = useCallback((e: SelectChangeEvent) => {
        // @ts-ignore
        dispatch(setTheme(e.target.value));
    }, [ dispatch ]);

    const handleLogOut = useCallback(() => {
        localStorageRemoveLogout();
        dispatch(logOut());
        dispatch(closeAuth());
        dispatch(setTheme('dark'));

        window.location.reload();

        if (onClosePanel) {
            onClosePanel();
        }
    }, [ dispatch, onClosePanel ]);

    return (
        <div className={classNames(C.panelSetting, className)}>
            <PanelBanner />
            <PanelItem name={t("panel.setting.lang.label")} desc={t("panel.setting.lang.desc")}>
                <Select
                    className={C.panelSetting__select}
                    IconComponent={ArrowIcon}
                    displayEmpty
                    value={i18n.language}
                    onChange={handleChangeLang}
                >
                    {languages?.map((lang) => (
                        <MenuItem
                            key={lang?.code}
                            value={lang?.code}
                        >
                            {lang?.label}
                        </MenuItem>
                    ))}
                </Select>
            </PanelItem>

            <PanelItem name={t("panel.setting.theme.label")} desc={t("panel.setting.theme.desc")}>
                <Select
                    className={C.panelSetting__select}
                    IconComponent={ArrowIcon}
                    displayEmpty
                    value={theme}
                    onChange={handleChangeTheme}
                >
                    {themes?.map((theme) => (
                        <MenuItem
                            key={theme}
                            value={theme}
                            disabled={(theme === "dark-premium" || theme === "light-premium") && !isPermitPremiumTheme}
                        >
                            {t(`panel.setting.theme.${theme}`)}
                        </MenuItem>
                    ))}
                </Select>
            </PanelItem>

            {authMe && (
                <Button className={C.panelSetting__logOut} onClick={handleLogOut} mode="orange">
                    {t("header.logOut")}
                </Button>
            )}
        </div>
    );
};