import React, { FC } from 'react';
import { Icon } from '../Icon';
import C from './ApiLoading.module.scss';

interface ApiLoadingProps {
    children: React.ReactNode | React.ReactNode[];
    load: boolean;
}

export const ApiLoading: FC<ApiLoadingProps> = ({ children, load }) => {
    return (
        <>
            {!load && <div className={C.ApiLoading}><Icon className={C.ApiLoading__Logo} name="logo" /></div>}
            {load && children}
        </>
    );
};