import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IProjectParams {
    page: number,
    peerPage: number,
    total: number,
}

interface ICatalogState {
    projectsParams: IProjectParams;
}

const initialState: ICatalogState = {
    projectsParams: {
        page: 1,
        peerPage: 10,
        total: 1
    }
};

const catalog = createSlice({
    name: 'catalog',
    initialState,
    reducers: {
        setProjectPage: (state, action: PayloadAction<{ page: number }>) => {
            state.projectsParams = {
                ...state.projectsParams,
                page: action.payload.page
            };
        },
        setProjectTotal: (state, action: PayloadAction<{ total: number }>) => {
            state.projectsParams = {
                ...state.projectsParams,
                total: action.payload.total
            };
        },
    }
});

export const { setProjectPage, setProjectTotal } = catalog.actions;
export default catalog.reducer;