import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { TermsOfUse } from "../TermsOfUse";
import { PrivacyPolicy } from "../PrivacyPolicy";
import classNames from "classnames";
import C from './RootPage.module.scss';
import { Logo } from "../../../../core/components/molecules/Logo";
import LogoText from "../../../../assets/images/logoText.svg";
import CloseSvg from "../../../../assets/images/close.svg";

export const RootPage = () => {
    const path = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (path?.label !== 'terms-of-use' && path?.label !== 'privacy-policy') {
            navigate('/404');
        }
    }, [ navigate, path?.label ]);

    return (
        <div className={classNames('container', C.RootPage)}>
            <div className={C.Wrap}>

                <div className={C.Head}>
                    <div className={C.Logo}>
                        <Logo className={C.Logo__Icon} />
                        <LogoText className={C.Logo__Text} />
                    </div>
                    <button onClick={() => navigate(-1)} className={C.Close}>
                        <CloseSvg className={C.Close__Icon} />
                    </button>
                </div>

                {path?.label === 'terms-of-use' && (
                    <TermsOfUse />
                )}
                {path?.label === 'privacy-policy' && (
                    <PrivacyPolicy />
                )}
            </div>
        </div>
    );
};