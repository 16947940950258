import React, { ChangeEvent, FC, FormEvent, useCallback, useMemo, useState } from 'react';
import C from './EditAvatarForm.module.scss';
import A from '../AboutMe/AboutMe.module.scss';
import CloseIcon from "../../../../assets/images/close.svg";
import DefaultUserIcon from "../../../../assets/images/default-user.svg";
import { useAppSelector } from "../../../../core/box/hooks";
import { useEditAvatar, useUpdateAvatar } from "../../services/profile.service";
import classNames from "classnames";

interface EditAvatarFormProps {
    onClose: () => void;
}

const convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

export const EditAvatarForm: FC<EditAvatarFormProps> = React.forwardRef(({ onClose }, ref) => {
    const authMe = useAppSelector(state => state.auth.authMe);

    const { profile_photo } = useMemo(() => {
        if (authMe?.user_information) {
            return {
                profile_photo: authMe?.user_information?.profile_photo?.value ? `${process.env.REACT_APP_URL_BACKEND}${authMe?.user_information?.profile_photo?.value}` : null,
            };
        }

        return {};
    }, [ authMe?.user_information ]);

    const [ fileBase64, setFileBase64 ] = useState<any>(profile_photo || '');
    const [ file, setFile ] = useState<null | File>(null);
    const [ isChange, setIsChange ] = useState<boolean>(false);

    const { mutateAsync, isLoadingEdit, data } = useEditAvatar();

    const { isLoadingUpdate } = useUpdateAvatar(data?.data?.data?.asset);

    const handlePhotoChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const fileTarget = e.target.files[0];
            setFile(fileTarget);
            await convertBase64(fileTarget).then(res => {
                setFileBase64(res);
                setIsChange(true);
            });
        }
    }, []);

    const handleSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (file) {
            await mutateAsync(file);
        }
    }, [ file, mutateAsync ]);

    return (
        <div className={C.EditAvatarForm}>
            <div className={C.EditAvatarForm__Body}>
                <CloseIcon onClick={onClose} className={C.EditAvatarForm__Close} />
                <form className={C.Form} onSubmit={handleSubmit}>
                    <label className={C.AvatarWrap}>
                        <input name="avatar" type="file" onChange={handlePhotoChange} hidden />
                        {!!fileBase64?.length ? (
                            <img className={A.Avatar__Img} src={fileBase64} alt="" />
                        ) : (
                            <DefaultUserIcon className={A.DefaultUser} />
                        )}
                    </label>

                    <button
                        disabled={!isChange || (isLoadingUpdate || isLoadingEdit)}
                        className={classNames('btn', {
                            [C.Button_Disabled]: !isChange || (isLoadingUpdate || isLoadingEdit)
                        })}
                    >
                        Сохранить
                    </button>
                </form>
            </div>
        </div>
    );
});