import React, { useCallback } from 'react';
import C from '../RootPage/RootPage.module.scss';
import S from './Support.module.scss';
import classNames from "classnames";
import { useGetMySupports } from "../../services/profileProjects.service";
import { useAppDispatch, useAppSelector } from "../../../../core/box/hooks";
import { Skeleton } from "@mui/material";
import { useMediaQuery } from "@react-hook/media-query";
import { MediaQuery } from "core/box/types";
import { UsePagination } from "../../../../core/components/molecules/UsePagination";
import { setSupportPage } from "../../store/profileProjects";
import { Empty } from "../../components/Empty";
import { useTranslation } from "react-i18next";

export const Support = () => {
    const { t } = useTranslation();
    const page = useAppSelector(state => state.moduleProfileProjects.supportPage);

    const { data, isLoading } = useGetMySupports(page);

    const isMobile = useMediaQuery(`only screen and (max-width: ${MediaQuery.XL})`);
    const dispatch = useAppDispatch();

    const onChangePagination = useCallback((event: React.ChangeEvent<unknown>, page: number) => {
        dispatch(setSupportPage(page));
    }, [ dispatch ]);

    if (!data?.data?.length) return null;

    return (
        <>
            <div className={C.Projects}>
                {!!data?.data?.length && (
                    <>
                        <div className={C.Projects__Head}>{t("profileProjects.support.title")}</div>

                        <div className={'table table--no-margin'}>
                            <div className={classNames(S.Grid)}>
                                <div className={`table__th`}>{t("profileProjects.support.name")}</div>
                                <div className={`table__th table__right`}>{t("profileProjects.support.status")}</div>
                            </div>

                            {data?.data?.map((item: any, index: number) => (
                                <div
                                    key={item.id}
                                    className={`${S.Grid}`}
                                >
                                    <div className={`table__td table__td--min  table__td--no-border`}>
                                        {item?.title}
                                    </div>
                                    <div className={`table__td table__td--min table__td--no-border table__right`}>
                                        {item?.status}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}

                {isLoading && (
                    <div>
                        <Skeleton height={!isMobile ? 36 : 32} />
                        <Skeleton height={!isMobile ? 36 : 32} />
                        <Skeleton height={!isMobile ? 36 : 32} />
                        <Skeleton height={!isMobile ? 36 : 32} />
                    </div>
                )}

                {!!data?.data?.length && (
                    <div className={C.Footer}>
                        <UsePagination
                            className={S.Pagination}
                            page={page}
                            onChange={onChangePagination}
                            count={Math.ceil(data?.meta?.total / 3)}
                        />
                    </div>
                )}

                {!data?.data?.length && !isLoading && (
                    <Empty title={t("profileProjects.support.noResult")} />
                )}

            </div>
        </>
    );
};