import network from "api/network";
import { useAppDispatch } from "core/box/hooks";
import { createNotification } from "core/store/notification";
import { useMutation } from "react-query";

const userEventsService = {
    async setFavorites(id: string) {
        return network.post('favorites', {
            target: 'project',
            target_id: id
        });
    },
    async deleteFavorites(id: string) {
        return network.delete('favorites', {
            params: {
                target: 'project',
                target_id: id
            }
        });
    },
    async setLikes(id: string) {
        return network.post('likes', {
            target: 'project',
            target_id: id
        });
    },
    async deleteLikes(id: string) {
        return network.delete('likes', {
            params: {
                target: 'project',
                target_id: id
            }
        });
    }
};

export const useSetFavorites = () => {
    const dispatch = useAppDispatch();

    const {
        isLoading: isLoadingFavorites,
        isSuccess: isSuccessFavorites,
        isError: isErrorFavorites,
        data: dataFavorites,
        error: errorFavorites,
        mutateAsync: mutateAsyncFavorites
    } = useMutation(
        'USER_EVENTS/SET_FAVORITES',
        (id: string) => userEventsService.setFavorites(id),
        {
            onSuccess: () => {
                dispatch(createNotification({ type: 'success', title: 'Проект добавлен в избранное' }));
            },
            onError: (error: any) => {
                // console.log('error', error);
                // @ts-ignore
                if (error?.response?.status === 403) {
                    dispatch(createNotification({
                        type: 'danger',
                        title: 'Ошибка!',
                        message: 'Для совершения действия у Вас должна быть активна подписка с данным правом'
                    }));
                }
            },
        }
    );

    return {
        isLoadingFavorites,
        isSuccessFavorites,
        isErrorFavorites,
        dataFavorites,
        errorFavorites,
        mutateAsyncFavorites
    };
};

export const useDeleteFavorites = () => {
    const dispatch = useAppDispatch();

    const {
        isLoading: isLoadingDelFavorites,
        isSuccess: isSuccessDelFavorites,
        isError: isErrorDelFavorites,
        data: dataDelFavorites,
        error: errorDelFavorites,
        mutateAsync: mutateAsyncDelFavorites
    } = useMutation(
        'USER_EVENTS/DELETE_FAVORITES',
        (id: string) => userEventsService.deleteFavorites(id),
        {
            onSuccess: () => {
                dispatch(createNotification({ type: 'warning', title: 'Проект удален из избранное' }));
            },
            onError: (error: any) => {
                // console.log('error', error);
                // @ts-ignore
                if (error?.response?.status === 403) {
                    dispatch(createNotification({
                        type: 'danger',
                        title: 'Ошибка!',
                        message: 'Для совершения действия у Вас должна быть активна подписка с данным правом'
                    }));
                }
            },
        }
    );

    return {
        isLoadingDelFavorites,
        isSuccessDelFavorites,
        isErrorDelFavorites,
        dataDelFavorites,
        errorDelFavorites,
        mutateAsyncDelFavorites
    };
};

export const useSetLikes = () => {
    const dispatch = useAppDispatch();

    const {
        isLoading: isLoadingLikes,
        isSuccess: isSuccessLikes,
        isError: isErrorLikes,
        data: dataLikes,
        error: errorLikes,
        mutateAsync: mutateAsyncLikes
    } = useMutation(
        'USER_EVENTS/SET_LIKES',
        (id: string) => userEventsService.setLikes(id),
        {
            onSuccess: () => {
                dispatch(createNotification({ type: 'success', title: 'Вы оценили проект!' }));
            },
            onError: (error: any) => {
                // console.log('error', error);
                // @ts-ignore
                if (error?.response?.status === 403) {
                    dispatch(createNotification({
                        type: 'danger',
                        title: 'Ошибка!',
                        message: 'Для совершения действия у Вас должна быть активна подписка с данным правом'
                    }));
                }
            },
        }
    );

    return {
        isLoadingLikes,
        isSuccessLikes,
        isErrorLikes,
        dataLikes,
        errorLikes,
        mutateAsyncLikes
    };
};

export const useDeleteLikes = () => {
    const dispatch = useAppDispatch();

    const {
        isLoading: isLoadingDelLikes,
        isSuccess: isSuccessDelLikes,
        isError: isErrorDelLikes,
        data: dataDelLikes,
        error: errorDelLikes,
        mutateAsync: mutateAsyncDelLikes
    } = useMutation(
        'USER_EVENTS/DELETE_LIKES',
        (id: string) => userEventsService.deleteLikes(id),
        {
            onSuccess: () => {
                dispatch(createNotification({ type: 'warning', title: 'Оценка снята!' }));
            },
            onError: (error: any) => {
                // console.log('error', error);
                // @ts-ignore
                if (error?.response?.status === 403) {
                    dispatch(createNotification({
                        type: 'danger',
                        title: 'Ошибка!',
                        message: 'Для совершения действия у Вас должна быть активна подписка с данным правом'
                    }));
                }
            },
        }
    );

    return {
        isLoadingDelLikes,
        isSuccessDelLikes,
        isErrorDelLikes,
        dataDelLikes,
        errorDelLikes,
        mutateAsyncDelLikes
    };
};