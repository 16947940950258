import React from 'react';
import { Dashboard } from "../Dashboard";
import { Catalog } from "../Catalog";

export const RootPage = () => {
    return (
        <div className="container container--top-space">
            <Dashboard />
            <Catalog />
        </div>
    );
};