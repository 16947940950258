import React, { FC, useCallback } from 'react';
import C from './CoinsList.module.scss';
import { formatNumber, formatNumberReserve } from "core/box/utils/formatNumber";
import { useAppSelector } from "core/box/hooks";
import classNames from "classnames";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { rateConvert } from "core/box/utils/rateConvert";
import { avatarUrl } from "core/box/utils/avatarUrl";
import { Stars } from "../../Stars";
import { useTranslation } from "react-i18next";

interface CoinsListProps {
    data: any[];
    page: number;
}

const CoinsListComponent: FC<CoinsListProps> = ({ data, page }) => {
    const { t } = useTranslation();

    const isAuth = useAppSelector(state => state.auth.authMe);
    const navigate = useNavigate();

    const handleClickItem = useCallback((category: string, id: string) => {
        navigate(`/project/${category}/${id}`);
    }, [ navigate ]);

    return (
        <div className="table">
            <div className={classNames('table__grid', `${C.Grid}`, {
                [`${C.Grid}--guest`]: !isAuth
            })}>
                <div className={`table__th`}>{t('catalog.position')}</div>
                <div className={`table__th`}>{t('catalog.name')}</div>
                <div className={`table__th table__center ${C.Rate}`}>{t('catalog.rate')}</div>
                <div className={`table__th table__center`}>{t('catalog.kpi')}</div>
                <div className={`table__th table__center`}>{t('catalog.reserve')}</div>
                <div className={`table__th table__center`}>{t('catalog.volatility')}</div>
                <div className={`table__th table__center`}>{t('catalog.price')}</div>
                <div className={`table__th table__center`}>{t('catalog.priceDel')}</div>
                <div className={`table__th table__center`}>{t('catalog.crr')}</div>
            </div>
            {data?.map((item, index) => {
                const coin = item?.data?.coin?.coin;
                const current = item?.data?.coin?.current;

                return (
                    <NavLink
                        key={item.id}
                        className={`${C.Grid} table__body table__grid`}
                        to={`/project/${item?.category}/${item?.project_id}`}
                        onClick={() => handleClickItem(item?.category, item?.project_id)}
                    >
                        <div className={`table__td`}>
                            <Stars projectId={item?.project_id} />
                            <span>{((page - 1) * 10 + 1) + index}</span>
                        </div>
                        <div className={`table__td`}>
                            <img className={C.Avatar}
                                 src={!!item?.avatar?.length ? avatarUrl(item?.avatar) : avatarUrl(coin?.avatar)}
                                 alt="" />
                            <span className={C.Name}>{coin?.symbol}</span>
                        </div>
                        <div className={`table__td table__center ${C.Rate}`}>{rateConvert(item?.rate)}</div>
                        <div className={`table__td table__center`}>-</div>
                        <div className={`table__td table__center`}>{formatNumberReserve(coin?.reserve)}</div>
                        <div className={`table__td table__center`}>{100 - coin?.crr}%</div>
                        <div className={`table__td table__center`}>{formatNumber(current?.price, 0, 3)} DEL</div>
                        <div
                            className={`table__td table__center`}>{current?.delegationPrice ? formatNumber(current?.delegationPrice, 0, 3) : 0} DEL
                        </div>
                        <div className={`table__td table__center`}>{coin?.crr}%</div>
                    </NavLink>
                );
            })}
        </div>
    );
};

export const CoinsList = React.memo(CoinsListComponent);