import React, { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';
import C from './Checkbox.module.scss';
import { useController, UseControllerProps, useFormContext } from "react-hook-form";
import CheckIcon from '../../../../assets/images/check.svg';

interface CheckboxProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, UseControllerProps {
    name: string;
    defaultValue?: string;
    label?: React.ReactNode | React.ReactNode[];
}

export const Checkbox: FC<CheckboxProps> = (props) => {
    const formContext = useFormContext();
    const { formState } = formContext;

    const {
        name,
        rules,
        label,
        defaultValue
    } = props;

    const { field } = useController({ name, rules, defaultValue });
    const hasError = Boolean(formState?.errors[name]);

    return (
        <label className={C.Wrap}>
            <input
                className={`${C.Input}`}
                type="checkbox"
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                {...props}
            />

            <span className={`
                ${C.Checkbox} 
                ${field.value ? C.Checkbox_Checked : ''}
                ${hasError ? C.Checkbox_Error : ''}
            `}>
                <CheckIcon />
            </span>

            {label && <span className={C.Label}>{label}</span>}
        </label>
    );
};